import React from 'react';
import { Page } from 'framework7-react';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { ColumnView } from 'Containers';

const BrokenOrUnauthorized = () => (
  <Page id={'unauthorized'}>
    <ColumnView height={'100%'} backgroundColor={colors.standard}>
      <img src={'/static/images/broken-link.png'} />

      <ColumnView paddingHorizontal={20}>
        <div className="screen-title">{I18n.t('general:brokenOrUnauthorized.title')}</div>
        <div className="screen-subtitle pt-24">{I18n.t('general:brokenOrUnauthorized.description')}</div>
      </ColumnView>
    </ColumnView>
  </Page>
);

export default BrokenOrUnauthorized;
