import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { ColumnView } from 'Containers';
import { QuickFilters, Table } from 'Components';
import { ALIGNMENT, FILTERS, FONT_SIZE, PAGE_NAMES } from 'Constants';

const Commercial = ({
  adminDailyFigures = {},
  adminFilters = {},
  getAdminDailyFigures = mockFunction,
  transient = {},
  updateTransientProps = mockFunction,
  updateUserProps = mockFunction,
  userRole = ''
}) => {
  const { dailyFigures = {} } = adminDailyFigures;
  const { campaigns = [] } = dailyFigures;

  const { getAdminDailyFiguresLoading = false, filters = {} } = transient;

  const columns = [
    {
      name: I18n.t('admin:campaign'),
      field: 'name',
      headerAlign: ALIGNMENT.START,
      itemSize: FONT_SIZE.SMALL,
      width: '40%',
      sorting: true,
      sortingAction: getAdminDailyFigures
    },
    {
      name: I18n.t('admin:baInTheField'),
      field: 'trading',
      headerAlign: ALIGNMENT.CENTER,
      itemSize: FONT_SIZE.SMALL,
      itemAlign: ALIGNMENT.CENTER,
      width: '20%'
    },
    {
      name: I18n.t('admin:scoring'),
      field: 'scoring',
      headerAlign: ALIGNMENT.CENTER,
      itemSize: FONT_SIZE.SMALL,
      itemAlign: ALIGNMENT.CENTER,
      width: '20%'
    },
    {
      name: I18n.t('admin:sales'),
      field: 'sales',
      headerAlign: ALIGNMENT.CENTER,
      itemSize: FONT_SIZE.SMALL,
      itemAlign: ALIGNMENT.CENTER,
      width: '20%'
    }
  ];

  return (
    <ColumnView>
      <QuickFilters
        filtersType={FILTERS.DAILY_FIGURES}
        fetchTableItems={getAdminDailyFigures}
        quickFilters={adminFilters}
        role={userRole}
        transient={transient}
        updateTransientProps={updateTransientProps}
        updateUserProps={updateUserProps}
        view={PAGE_NAMES.CAMPAIGNS_VIEW}
      />

      <Table
        columns={columns}
        filters={filters}
        multipleSelection={false}
        processing={getAdminDailyFiguresLoading}
        requiresDataTransformation={false}
        rows={campaigns}
      />
    </ColumnView>
  );
};

Commercial.propTypes = {
  adminDailyFigures: PropTypes.object,
  adminFilters: PropTypes.object,
  getAdminDailyFigures: PropTypes.func,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateUserProps: PropTypes.func,
  userRole: PropTypes.string
};

export default Commercial;
