import { createActions, createReducer } from 'reduxsauce';

import { DAILY_FIGURES_TAB_VIEWS } from 'Constants';

import { updateProps } from '../shared';

export const { Types, Creators } = createActions(
  {
    getAdminDailyFigures: ['data'],
    getAdminDailyFiguresFail: ['error'],
    getAdminDailyFiguresSuccess: ['payload'],
    getCampaignsByClientType: ['data'],
    getCampaignsByClientTypeSuccess: ['payload'],
    getClientsByClientType: ['data'],
    getClientsByClientTypeSuccess: ['payload'],
    manualCheckIn: ['data'],
    manualCheckInSuccess: null,
    manualCheckInFail: ['error'],
    manualCheckInCommercial: ['data'],
    manualCheckInCommercialSuccess: null,
    manualCheckInCommercialFail: ['error'],
    updateSales: ['data'],
    updateSalesSuccess: null,
    updateSalesFail: ['error'],
    reset: null,
    updateProps: ['props']
  },
  { prefix: 'adminDailyFigures/' }
);

const initialState = {
  baViewsTabs: [DAILY_FIGURES_TAB_VIEWS.STATUS, DAILY_FIGURES_TAB_VIEWS.PRODUCT_SPLIT],
  campaignViewTabs: [DAILY_FIGURES_TAB_VIEWS.STATUS, DAILY_FIGURES_TAB_VIEWS.DONATION_AMOUNT],
  currentTableView: DAILY_FIGURES_TAB_VIEWS.STATUS
};

export const reset = () => initialState;
export const adminDailyFigures = state => state.adminDailyFigures;

export default createReducer(initialState, {
  [Types.RESET]: reset,
  [Types.UPDATE_PROPS]: updateProps
});
