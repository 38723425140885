import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { RowView } from 'Containers';
import { mockFunction } from 'Helpers';
import { MODAL_SIZES } from 'Constants';
import { Button, Modal } from 'Components';

const BlockedLogoutModal = ({ hasOfflineResults = mockFunction, openPopup = false, setOpenPopup = mockFunction }) => (
  <Modal
    open={openPopup}
    size={MODAL_SIZES.SMALL}
    closeByBackdropClick={false}
    onClose={setOpenPopup.bind(null, false)}>
    <Modal.Content contentPosition="center">
      <RowView>
        <div className="title-lg">{I18n.t('general:logOutPopupTitle')}</div>
      </RowView>
      <RowView>
        <p className="text-center">{I18n.t('general:logOutPopupDescription')}</p>
      </RowView>
    </Modal.Content>

    <Modal.Actions>
      <Button.Primary onClick={hasOfflineResults.bind(null, { setOpenPopup })} width={'100%'}>
        {I18n.t('general:ok')}
      </Button.Primary>
    </Modal.Actions>
  </Modal>
);

BlockedLogoutModal.propTypes = {
  hasOfflineResults: PropTypes.func,
  openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.func
};

export default BlockedLogoutModal;
