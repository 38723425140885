import { I18n } from 'Locales';

const ACCEPTED_IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'heif', 'heic'];

const APP_MODES = {
  ADMIN: 'admin',
  AGENT: 'agent',
  DONOR: 'donor'
};

const ALIGNMENT = {
  CENTER: 'center',
  END: 'end',
  START: 'start'
};

const FONT_SIZE = {
  EXTRA_SMALL: 'font-size-xs',
  LARGE: 'font-size-lg',
  MEDIUM: 'font-size-md',
  SMALL: 'font-size-sm'
};

const TEXT_ALIGN = {
  START: 'text-left',
  CENTER: 'text-center',
  END: 'text-end'
};

const NEEDS_LEURKAART = ['Brussels-Capital', 'Wallonia'];

const COUNTRIES = { BE: 'BE', DE: 'DE', FR: 'FR', IE: 'IE', UK: 'UK' };

const SHORT_CODE_COUNTRIES = { BE: 'BE', DE: 'DE', FR: 'FR', IE: 'IE', UK: 'UK' };

const CREATE_USER_OPTIONS = {
  ADMIN_LIKE_ROLES: ['Admin', 'Supervisor', 'Telemarketer', 'Telemarketer FA'],
  MC_ADMIN_LIKE_ROLES: ['MC Admin', 'MC Bookkeeper', 'MC Recruiter', 'Owner'],
  COUNTRIES: ['BE', 'DE', 'FR', 'IE', 'UK'],
  PITCHING_REGIONS: ['Brussels-Capital', 'Flanders', 'Wallonia'],
  LANGUAGES: ['en', 'fr', 'nl'],
  // Keep this order for recruitment types for the condition in getRecruiter saga to work
  RECRUITMENT_TYPES: ['Office recruitment', 'Personal recruitment'],

  ROLES: [
    'Admin',
    'Applicant',
    'MC Admin',
    'MC Bookkeeper',
    'MC Recruiter',
    'Owner',
    'Supervisor',
    'Telemarketer',
    'Telemarketer FA'
  ],
  COACH_ROLES: ['Stage 2', 'Stage 3', 'Stage 4', 'Stage 5', 'Owner'],
  PERSONAL_RECRUITMENT_RECRUITER_ROLES: [
    'MC Admin',
    'Owner',
    'Applicant',
    'Stage 1',
    'Stage 2',
    'Stage 3',
    'Stage 4',
    'Stage 5'
  ],
  OFFICE_RECRUITMENT_RECRUITER_ROLES: ['MC Admin', 'Owner'],
  NEEDS_TO_RESET: ['pins', 'recruitment_source', 'coach', 'recruiter', 'regions'],
  SIMPLE_USER_CREATE_FIELDS: ['role', 'pins', 'marketing_companies']
};

const CLIENT_TYPE = { COMMERCIAL: 'Commercial', FUNDRAISING: 'Fundraising' };

const DATE_FORMAT = {
  UI: 'DD/MM/YYYY',
  INTERNAL: 'YYYY-MM-DD' // For setting min/max limits or default values
};

const DAILY_FIGURES_TYPE = {
  CHARITY: 'charity',
  COMMERCIAL: 'commercial'
};

const DAILY_FIGURES_VIEW = {
  BAS_VIEW: 'bas',
  CAMPAIGNS_VIEW: 'campaigns',
  ADMIN_VIEW: 'admin'
};

const DAILY_FIGURES_CAMPAIGNS = {
  CHARITY_CAMPAIGNS: 'charityCampaigns',
  COMMERCIAL_CAMPAIGNS: 'commercialCampaigns'
};

const CAMPAIGNS_TYPE = {
  FUNDRAISING: 'Fundraising',
  COMMERCIAL: 'Commercial'
};

const DAILY_FIGURES_TAB_VIEWS = {
  DONATION_AMOUNT: 'donationAmount',
  PRODUCT_SPLIT: 'productSplit',
  STATUS: 'status'
};

const COUNTRIES_ADDRESS = {
  ALL: ['BE', 'DE', 'FR', 'IE', 'UK'],
  AUTOCOMPLETE: ['BE', 'FR'],
  FINDER: ['BE', 'FR', 'UK']
};

const DOCUMENT_STATES = {
  ACCEPTED: 'accepted',
  COMPLETED: 'completed',
  INCOMPLETE: 'incomplete',
  PENDING_READ_ONLY: 'pendingReadOnly',
  PENDING: 'pending',
  READ: 'read',
  REJECTED: 'rejected',
  SIGNED: 'signed'
};

const DOCUMENT_TYPES = {
  // Maintain this order to ensure documents are displayed correctly in block tabs
  MANDATORY: 'Mandatory',
  MC_ADMIN_TIME_LEASHED: 'Mc-admin-time-leashed',
  TIME_LEASHED: 'Time-leashed',
  READ_ONLY: 'Read-only',
  OPTIONAL: 'Optional'
};

const DOCUMENT_REJECT_REASON = {
  DATA_MISMATCH: 'document_does_not_match_data',
  LOW_IMG_QUALITY: 'image_quality_too_low',
  OUT_OF_DATE: 'document_out_of_date',
  WRONG_DOCUMENT: 'wrong_document'
};

const PAGE_NAMES = {
  ADMIN_VIEW: 'AdminView',
  ADMIN: 'Admin',
  ADVANCED_FILTERS: 'AdvancedFilters',
  BAS_VIEW: 'BAsView',
  BULK_RELEASE_BLOCK: 'BulkReleaseBlock',
  CAMPAIGNS_VIEW: 'CampaignsView',
  CAMPAIGNS: 'Campaigns',
  CHECKIN: 'CheckIn',
  CREATE_USER: 'CreateUser',
  DAILY_FIGURES: 'DailyFigures',
  DASHBOARD: 'Dashboard',
  FEEDBACK: 'Feedback',
  ONBOARDING_PROFILE: 'OnboardingProfile',
  PASSWORD_UPDATE: 'PasswordUpdate',
  PROFILE: 'Profile',
  REJECT_DOCUMENT: 'RejectDocument',
  REJECT_SECTION: 'RejectSection',
  REPORTS: 'Reports',
  SETTINGS: 'Settings',
  SIGNUP: 'SignUp',
  TOOLS: 'Tools',
  VERSION_UPDATE: 'VersionUpdate'
};

const POSITION = {
  bottom: 'bottom',
  left: 'left',
  right: 'right',
  top: 'top'
};

const PROFILE_STATUS_COLORS = {
  GRAY: 'gray',
  GREEN: 'green',
  ORANGE: 'orange',
  RED: 'red'
};

const ONBOARDING_PROFILE_STATES = {
  BADGE_RELEASED: 'badge_released',
  OFFER_NOT_MADE: 'offer_not_made',
  ONBOARDED: 'onboarded',
  PROFILE_CANCELLED: 'profile_cancelled',
  PROFILE_IN_PROGRESS: 'profile_in_progress',
  READY_FOR_APPROVAL: 'ready_for_approval',
  READY_FOR_VETTING: 'ready_for_vetting',
  READY_TO_SEND_WELCOME_MESSAGE: 'ready_to_send_welcome_message',
  VETTING_UNDER_REVIEW: 'vetting_under_review',
  WAITING_FOR_TERMS: 'waiting_for_terms'
};

const EMPTY_TABS = {
  account_holder_name: 'bank_details',
  account_number: 'bank_details',
  additional_bank_info: 'bank_details',
  address_line1: 'contact',
  address_line2: 'contact',
  address_line3: 'contact',
  birth_department: 'identity',
  birth_location: 'identity',
  city: 'contact',
  country_of_birth: 'identity',
  country_of_citizenship: 'identity',
  country: 'contact',
  cpr_number: 'identity',
  date_of_birth: 'identity',
  email: 'profile',
  emergency_name: 'contact',
  emergency_phone_number: 'contact',
  first_name: 'profile',
  gender: 'identity',
  id_expiry_date: 'identity',
  last_name: 'profile',
  national_insurance_number: 'identity',
  nationality: 'identity',
  phone_number: 'profile',
  postcode: 'contact',
  regions: 'contact',
  siret_number: 'identity',
  ssn: 'identity'
};

const FIELD_NAMES = {
  MESSAGE: 'info_message'
};

const FILE_SIZE_LIMITS = {
  ATTACHMENTS: 10 * 1024 * 1024 // 10MB
};

const FILTERS = {
  ONBOARDING: 'onboarding',
  DAILY_FIGURES: 'dailyFigures'
};

const ICON_COLOR = {
  BLUE: '#0d8de7',
  GRAY: '#747474',
  LIGHT_GRAY: '#c1c1c1',
  RED: '#E15A55',
  WHITE: '#ffffff'
};

const MODAL_SIZES = {
  EXTRA_SMALL: 'extra-small',
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small'
};

const REGIONS = {
  BE: ['BE', 'DE'],
  UK: ['IE', 'UK']
};

const RESULT_STATUS_TYPES = {
  CANCELLED: 'cancelled',
  INACTIVE: 'inactive',
  INCOMPLETE: 'incomplete',
  OFFLINE: 'offline'
};

const SIGNATURE_COLORS = { ERROR: '#E15A55' };

const SIGNING_TYPES = {
  READ_ONLY: 'Read Only',
  SIGN: 'Sign Document',
  UPLOAD: 'Upload Document'
};

const TABLE_CONFIG = {
  PAGE: 1,
  ROWS: 15
};

const TABS = {
  BANK_DETAILS: 'bank_details',
  CONTACT: 'contact',
  DOCUMENTS: 'documents',
  IDENTITY: 'identity',
  PROFILE: 'profile'
};

const ONBOARDING_PROFILE_TABS = {
  BASIC_PROFILE: 'basic',
  BADGES: 'badges',
  BLOCK_1: 'block1',
  BLOCK_2: 'block2',
  BLOCK_3: 'block3',
  BLOCK_4: 'block4',
  DETAILS: 'details',
  ACTIVITY: 'activity'
};

const TEXT_FIELD_ICONS = {
  CHECKMARK: 'material:check'
};

const ONBOARDING_FIELDS = {
  BANK_DETAILS: ['account_holder_name', 'account_number', 'bank_name', 'bank_sort_code'],
  CONTACT: ['address_line1', 'city', 'country', 'postcode'],
  EMERGENCY: ['emergency_name', 'emergency_phone_number'],
  IDENTITY: [
    'birth_department',
    'birth_location',
    'country_of_birth',
    'country_of_citizenship',
    'cpr_number',
    'date_of_birth',
    'gender',
    'national_insurance_number',
    'nationality',
    'siret_number',
    'id_expiry_date',
    'ssn'
  ],
  PROFILE: ['email', 'first_name', 'last_name', 'phone_number']
};

const PROFILE_FIELDS_DETAILS = {
  BANK_DETAILS: 'bank_details',
  CONTACT: ['home_address', 'emergency'],
  IDENTITY: 'general_details',
  PROFILE: 'general_details'
};

const ROLES = {
  ADMINS: ['Admin', 'MC Admin', 'Owner'],
  APPLICANT: 'Applicant',
  MC_ADMINISTRATIVE: ['MC Admin', 'Owner']
};

const ALERT_TYPES = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success'
};

const ALERT_COLORS = {
  ERROR: '#F6CCCA',
  INFO: '#FDEAB0',
  SUCCESS: '#C0E7D9'
};

const ADDRESS_FLOW_COMPONENT = {
  KEYS: [
    'City',
    'ExtraInfo',
    'ExtraInfo2',
    'Letter',
    'Number',
    'PostCode',
    'PostalDistrict',
    'StreetName',
    'search_address'
  ]
};

const ADMIN_ROLES = {
  ADMIN: 'Admin',
  MC_ADMIN: 'MC Admin',
  OWNER: 'Owner'
};

const USER_LANGUAGES = {
  LANGUAGES: [
    { label: I18n.t('settings:english'), value: 'en' },
    { label: I18n.t('settings:french'), value: 'fr' },
    { label: I18n.t('settings:dutch'), value: 'nl' }
  ]
};

export {
  ACCEPTED_IMAGE_TYPES,
  ADDRESS_FLOW_COMPONENT,
  ADMIN_ROLES,
  ALERT_COLORS,
  ALERT_TYPES,
  ALIGNMENT,
  APP_MODES,
  CAMPAIGNS_TYPE,
  CLIENT_TYPE,
  COUNTRIES_ADDRESS,
  COUNTRIES,
  CREATE_USER_OPTIONS,
  DAILY_FIGURES_CAMPAIGNS,
  DAILY_FIGURES_TAB_VIEWS,
  DAILY_FIGURES_TYPE,
  DAILY_FIGURES_VIEW,
  DATE_FORMAT,
  DOCUMENT_REJECT_REASON,
  DOCUMENT_STATES,
  DOCUMENT_TYPES,
  EMPTY_TABS,
  FIELD_NAMES,
  FILE_SIZE_LIMITS,
  FILTERS,
  FONT_SIZE,
  ICON_COLOR,
  MODAL_SIZES,
  NEEDS_LEURKAART,
  ONBOARDING_FIELDS,
  ONBOARDING_PROFILE_STATES,
  ONBOARDING_PROFILE_TABS,
  PAGE_NAMES,
  POSITION,
  PROFILE_FIELDS_DETAILS,
  PROFILE_STATUS_COLORS,
  REGIONS,
  RESULT_STATUS_TYPES,
  ROLES,
  SHORT_CODE_COUNTRIES,
  SIGNATURE_COLORS,
  SIGNING_TYPES,
  TABLE_CONFIG,
  TABS,
  TEXT_ALIGN,
  TEXT_FIELD_ICONS,
  USER_LANGUAGES
};
