import { createActions, createReducer } from 'reduxsauce';

import { setInitial, updateProp, updateProps } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    get: null,
    getError: null,
    set: ['payload', 'userId'],
    setCampaignsAndDefault: ['payload'],
    setSelectedCampaign: ['payload'],
    setSelectedCampaignFilterId: ['payload'],
    updateProp: ['key', 'value'],
    updateProps: ['props']
  },
  { prefix: 'campaign/' }
);

const initialState = {};

const initialUserState = {
  campaigns: []
};

export const campaigns = state => state.campaign;

export const set = (state, { userId, payload }) => {
  const newState = { ...state };
  if (!newState[userId]) {
    newState[userId] = { ...initialUserState };
  }

  payload.forEach(newItem => {
    const campaign = newState[userId]?.campaigns?.find(item => item.id === newItem.id);
    if (campaign && campaign.icon_logo_url !== newItem.icon_logo_url) {
      campaign.icon_logo_url = newItem.icon_logo_url;
    }
  });

  newState[userId].campaigns = payload;

  return newState;
};

export const setSelectedCampaign = (state, { userId, payload }) => {
  const newState = setInitial(state, userId, initialState);
  newState[userId]['selectedCampaign'] = payload;
  return newState;
};

export const setSelectedCampaignFilterId = (state, { userId, payload }) => {
  const newState = setInitial(state, userId, initialState);
  const id = typeof payload === 'object' ? payload?.id : payload;
  newState[userId]['selectedCampaignFilterId'] = id;
  return newState;
};

export default createReducer(initialState, {
  [Types.SET_SELECTED_CAMPAIGN_FILTER_ID]: setSelectedCampaignFilterId,
  [Types.SET_SELECTED_CAMPAIGN]: setSelectedCampaign,
  [Types.SET]: set,
  [Types.UPDATE_PROP]: updateProp,
  [Types.UPDATE_PROPS]: updateProps
});
