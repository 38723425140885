import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'framework7-react';

import { I18n } from 'Locales';
import { TextField } from 'Components';
import { ColumnView } from 'Containers';
import { handleOnChangeWithValidations, mockFunction, onFieldChange, validations } from 'Helpers';

const WelcomeEmailModal = ({
  custom_footer = '',
  email = '',
  email_error_message = '',
  email_has_error = false,
  isOneRowSelected = '',
  selectedRows = [],
  updateTransientProps = mockFunction,
  updateTransientPropWithValidations = mockFunction
}) => (
  <>
    <ColumnView>
      {isOneRowSelected && (
        <List noHairlines className="w-full pt-4">
          <TextField
            label={I18n.t('admin:welcomeEmailModal.email')}
            onChange={handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'email', [
              validations.isRequired,
              validations.emailValidation
            ])}
            errorMessageForce={email_has_error}
            errorMessage={email_error_message}
            type="text"
            value={email}
          />
        </List>
      )}

      <div className="text-container pb-16">
        <List noHairlines className="w-full">
          <TextField
            label={I18n.t('admin:welcomeEmailModal.customMessage')}
            onTextEditorChange={onFieldChange.bind(null, updateTransientProps, 'custom_footer')}
            outline={true}
            type="texteditor"
            value={custom_footer}
            textEditorParams={{
              buttons: []
            }}
          />
        </List>
      </div>
    </ColumnView>

    <div className="welcome-email-names-container ">
      <div className="text-sm pr-4">
        {I18n.t('admin:welcomeEmailModal.emailWillBeSentTo', {
          count: selectedRows?.length,
          selectedRows: selectedRows?.length
        })}
      </div>
      {selectedRows.map((profile, index) => (
        <div key={index} className="text-sm font-bold mr-4">
          {profile.name}
          {index < selectedRows.length - 1 && ','}
        </div>
      ))}
    </div>
  </>
);

WelcomeEmailModal.propTypes = {
  custom_footer: PropTypes.string,
  email_error_message: PropTypes.string,
  email_has_error: PropTypes.bool,
  email: PropTypes.string,
  isOneRowSelected: PropTypes.bool,
  selectedRows: PropTypes.array,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func
};

export default WelcomeEmailModal;
