import React from 'react';
import moment from 'moment';
import { f7 } from 'framework7-react';

import Config from 'Config';
import { I18n } from 'Locales';
import { Button, Bubble } from 'Components';
import { F7, NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import {
  ADMIN_ROLES,
  DOCUMENT_STATES,
  DOCUMENT_TYPES,
  ICON_COLOR,
  ONBOARDING_PROFILE_STATES,
  PAGE_NAMES,
  ROLES,
  SIGNING_TYPES
} from 'Constants';
import {
  calculateTimeSince,
  capitalize,
  handleOnChangeWithValidations,
  ibanMaskConfig,
  mockFunction,
  validations
} from 'Helpers';
import {
  CheckMark,
  DocumentLock,
  Exit,
  PDFPending,
  PendingEye,
  StateActive,
  StateDisabled,
  StateRejected,
  TrashCan
} from 'Icons';

const renderOnboardingProfileNavButtons = (isFirstPage, isLastPage, prevPage, nextPage, updateAdminProps) => (
  <RowView gap={24} paddingHorizontal={20} paddingBottom={16}>
    {!isFirstPage && (
      <Button.Outline width={'100%'} onClick={updateAdminProps.bind(null, { currentPage: prevPage })}>
        {I18n.t('general:back')}
      </Button.Outline>
    )}
    {!isLastPage && (
      <Button.Primary width={'100%'} onClick={updateAdminProps.bind(null, { currentPage: nextPage })}>
        {I18n.t('general:next')}
      </Button.Primary>
    )}
  </RowView>
);

const renderReleaseNextBlock = (currentPage, blocks, userOnboardingProfile, updateTransientProps) => {
  const blockNumber = parseInt(currentPage.replace('block', ''), 10);
  const isMcAdminAccepted = userOnboardingProfile?.accepted_by_mc_admin;
  const isProfileCancelled = userOnboardingProfile.state === ONBOARDING_PROFILE_STATES.PROFILE_CANCELLED;

  if (blockNumber === blocks?.length && blockNumber < 4) {
    const isDisabled = (!isMcAdminAccepted && (blockNumber === 2 || blockNumber === 3)) || isProfileCancelled;
    const nextBlockNumber = blockNumber + 1;
    const key = `releaseBlock${nextBlockNumber}Modal`;

    const handleClick = () => {
      updateTransientProps({
        nextBlock: nextBlockNumber,
        modalKey: key,
        block: nextBlockNumber,
        [key]: true,
        releaseNextBlock: true
      });
    };

    return (
      <RowView gap={24} paddingHorizontal={20} paddingBottom={16}>
        <Button.Outline width={'100%'} disabled={isDisabled} onClick={handleClick}>
          {I18n.t('admin:releaseNextBlock')}
        </Button.Outline>
      </RowView>
    );
  }
};

const renderVetProfile = (isDisabled = false, handleClick = mockFunction) => (
  <RowView gap={24} paddingHorizontal={20} paddingBottom={16}>
    <Button.Outline width={'100%'} disabled={isDisabled} onClick={handleClick}>
      {I18n.t('admin:vetProfile')}
    </Button.Outline>
  </RowView>
);

const renderSelectOptions = (userOptions, selectTranslationKey, fieldName) => {
  const firstLetterUpperCase = `select${fieldName?.charAt(0).toUpperCase()}${fieldName?.slice(1)}`;
  const defaultOption = fieldName
    ? I18n.t(`${selectTranslationKey}.${firstLetterUpperCase}`)
    : I18n.t('general:select');
  const options = userOptions.map((option, index) => (
    <option key={option.id || index} value={option}>
      {I18n.t(`${selectTranslationKey}.${option}`)}
    </option>
  ));

  options.unshift(
    <option key="default" value="" disabled selected={true}>
      {defaultOption}
    </option>
  );

  return options;
};

const getDateRange = config => {
  const { dateRange } = config;

  if (dateRange && (dateRange.max === 'today' || dateRange.min === 'today')) {
    const today = moment().format('YYYY-MM-DD');
    return {
      min: dateRange.min === 'today' ? today : dateRange.min,
      max: dateRange.max === 'today' ? today : dateRange.max
    };
  }
  return dateRange && (dateRange.max || dateRange.min)
    ? {
        min: dateRange.min ? moment(dateRange.min).format('YYYY-MM-DD') : undefined,
        max: dateRange.max ? moment(dateRange.min).format('YYYY-MM-DD') : undefined
      }
    : undefined;
};

const updateUserChanges = ({ profileId, transient, updateOnboardingUserProfile, updateTransientProps, fieldName }) => {
  const filteredTransient = Object.keys(transient).reduce((acc, key) => {
    const errorFlagKey = `${key}_has_error`;
    if (!transient[errorFlagKey]) {
      acc[key] = transient[key];
    } else {
      updateTransientProps({ [errorFlagKey]: false });
    }
    return acc;
  }, {});
  const newUser = {
    data: {
      attributes: {
        ...filteredTransient,
        nationality: transient.nationality?.nationality,
        country_of_birth: transient.country_of_birth?.short_code,
        country_of_citizenship: transient?.country_of_citizenship?.short_code
      }
    }
  };

  updateOnboardingUserProfile(newUser, profileId, fieldName);
};

const handleChangeBankAccount = (validateIban, key, e) => {
  validateIban(key, e.target.value);
};

const getFormattedValue = (config, value, transient) => {
  if (value === undefined) return '';
  if (config.iBanMaskValue) return ibanMaskConfig(value);
  if (config.fieldName === 'regions') return renderMultipleValues(config.selectTranslationKey, value);

  const translatedValue = config.translateValue
    ? I18n.t(`admin:translatedValues.${transient?.[config.fieldName]}`) || ''
    : value;
  return config.capitalizeValue ? capitalize(translatedValue) || '' : translatedValue;
};

const renderMultipleValues = (selectTranslationKey, value = []) =>
  value?.map(value => I18n.t(`${selectTranslationKey}.${value}`)).join(', ');

const isDisabled = (config, readOnly) => (config ? config : readOnly);

const readDocument = (readAttachment, document) => {
  window.open(document.attachment_url);
  readAttachment(document);
};

const viewDocument = url => {
  window.open(url);
};

const handleFileUpload = (updateUserDocument, id, e) => {
  const { files } = e.target;
  if (!files) return;
  updateUserDocument({
    file: files[0],
    attachable_id: id
  });
};

const deleteFile = (deleteUserAttachment, selectedDocument) => {
  const title = I18n.t('profile:documentsTab.deleteAlert');

  F7.dialog
    .create({
      title,
      buttons: [
        { text: I18n.t('general:cancel') },
        { text: I18n.t('general:ok'), onClick: deleteUserAttachment.bind(null, selectedDocument) }
      ]
    })
    .open();
};

const onButtonClick = ref => ref && ref?.click();

const handleRef = (files, id, el) => (files.current[id] = el);

const handleDocumentClick = ({ defaultSignerName, document, updateGrowlProps, userId, viewDocument }) => {
  const signer = document.document_signers.find(item => item.user_id.toString() === userId);
  const displayGrowlNotification =
    document.document_signers.length < 1 && document.admin_signed_on === null && document.applicant_signed_on === null;

  if (displayGrowlNotification) {
    updateGrowlProps({
      visible: true,
      title: I18n.t('growl:error.noSignerAssigned.title'),
      body: I18n.t('growl:error.noSignerAssigned.body'),
      kind: 'info'
    });

    setTimeout(() => {
      updateGrowlProps({
        visible: false
      });
    }, Config.GROWL_AUTOHIDE);
  } else if (signer && (document.admin_signed_on === null || document.applicant_signed_on === null)) {
    viewDocument(signer.in_sign_url);
  } else if (
    signer?.user_id.toString() !== userId &&
    (document.admin_signed_on === null || document.applicant_signed_on === null)
  ) {
    updateGrowlProps({
      visible: true,
      title: I18n.t('growl:error.signer.title'),
      body: I18n.t('growl:error.signer.body', { singleSignerName: defaultSignerName }),
      kind: 'error'
    });

    setTimeout(() => {
      updateGrowlProps({
        visible: false
      });
    }, Config.GROWL_AUTOHIDE);
  } else {
    viewDocument(document.attachment_url);
  }
};

const getTimeSinceMessage = createdAt => {
  const { days, hours, minutes } = calculateTimeSince(createdAt);

  if (days > 0) {
    return I18n.t('admin:releasedDaysAgoCard.releasedDaysAgo', {
      days,
      count: days
    });
  } else if (hours > 0) {
    return I18n.t('admin:releasedDaysAgoCard.releasedHoursAgo', {
      hours,
      count: hours
    });
  } else {
    return I18n.t('admin:releasedDaysAgoCard.releasedMinutesAgo', {
      minutes,
      count: minutes
    });
  }
};

const getExpirationDisplay = expiration_date => {
  const today = moment();
  const expirationDate = moment(expiration_date);

  const daysUntilExpiration = expirationDate.diff(today, 'days');

  return daysUntilExpiration > 30
    ? expirationDate.format('MMM DD, YYYY')
    : moment(Date.parse(expiration_date)).fromNow();
};

const renderDocument = ({
  deleteUserAttachment,
  document,
  files,
  index,
  isOffline,
  readAttachment,
  removeAttachment,
  role_name,
  transient,
  updateGrowlProps,
  updateOnboardingProfileDocuments,
  updateTransientProps,
  updateUserDocument,
  userId
}) => {
  const textColor =
    document?.state === DOCUMENT_STATES.ACCEPTED
      ? 'text-green'
      : document?.state === DOCUMENT_STATES.REJECTED
      ? ' text-red'
      : 'text-blue';

  const getDocumentStatus = document => {
    if (document?.state === DOCUMENT_STATES.ACCEPTED) {
      return I18n.t('admin:documentStatus.accepted');
    }

    if (document?.state === DOCUMENT_STATES.REJECTED) {
      return I18n.t('admin:documentStatus.rejected');
    }

    if (document?.document_type === DOCUMENT_TYPES.READ_ONLY) {
      return document?.applicant_read_on === null
        ? I18n.t('admin:documentStatus.waitingForRead')
        : I18n.t('admin:documentStatus.readOn');
    }

    if (
      document?.applicant_signed_on !== null ||
      document?.applicant_read_on !== null ||
      document?.uploaded_on !== null
    ) {
      return I18n.t('admin:documentStatus.waitingForApproval');
    }

    return I18n.t('admin:documentStatus.waitingForUpload');
  };

  const documentStatus = getDocumentStatus(document);
  const hasAutoDeletionDate = transient?.selectedDocument?.deletion_date !== null;
  const acceptedOnNotNull = transient?.selectedDocument?.accepted_on !== null;
  const hasAttachmentUrl = transient?.selectedDocument?.attachment_url !== null;
  const removeAttachmentDisabled = acceptedOnNotNull || !hasAttachmentUrl;

  const documentActionMessage =
    document?.signing_type === SIGNING_TYPES.SIGN &&
    (document.admin_signed_on === null || document.applicant_signed_on === null)
      ? I18n.t('profile:documentsTab.documentsButtons:signButton')
      : I18n.t('profile:documentsTab.documentsButtons:viewButton');

  const readyForApprovalStateDisabled =
    transient?.state === ONBOARDING_PROFILE_STATES.READY_FOR_APPROVAL && ROLES.ADMINS.includes(role_name);

  const vettingUnderReviewDisabled =
    transient?.state === ONBOARDING_PROFILE_STATES.VETTING_UNDER_REVIEW && role_name !== ADMIN_ROLES.ADMIN;

  const onboardedDisabled = transient?.state === ONBOARDING_PROFILE_STATES.ONBOARDED && role_name === ADMIN_ROLES.ADMIN;

  const acceptDocumentDisabled =
    transient?.selectedDocument?.attachment_url === null ||
    readyForApprovalStateDisabled ||
    vettingUnderReviewDisabled ||
    onboardedDisabled;

  const rejectDocumentDisabled =
    transient?.selectedDocument?.attachment_url === null ||
    readyForApprovalStateDisabled ||
    vettingUnderReviewDisabled ||
    onboardedDisabled;

  const uploadDocumentDisabled =
    role_name === ADMIN_ROLES.ADMIN || readyForApprovalStateDisabled || vettingUnderReviewDisabled;

  const deleteDocumentDisabled =
    transient?.selectedDocument?.attachment_url === null ||
    role_name === ADMIN_ROLES.MC_ADMIN ||
    transient?.selectedDocument?.deletion_date !== null ||
    transient?.selectedDocument?.deleted_at === null ||
    readyForApprovalStateDisabled ||
    vettingUnderReviewDisabled;

  const items = [
    {
      onClick: () => {
        const data = {
          data: {
            attributes: {
              accepted_on: new Date().toISOString(),
              uploaded: true,
              state: 'accepted',
              rejection_reason: ''
            },
            id: transient?.selectedDocument?.id
          }
        };
        updateOnboardingProfileDocuments(data, transient?.selectedDocument?.id);
      },
      label: I18n.t('admin:actions.accept'),
      icon: CheckMark,
      disabled: acceptDocumentDisabled
    },
    {
      onClick: NavigationService.navigate.bind(null, {
        name: PAGE_NAMES.REJECT_DOCUMENT
      }),
      label: I18n.t('admin:actions.reject'),
      icon: Exit,
      disabled: rejectDocumentDisabled
    },
    {
      onClick: () =>
        hasAutoDeletionDate
          ? deleteFile(deleteUserAttachment, transient?.selectedDocument)
          : removeAttachment(transient?.selectedDocument?.attachment_id),
      label: I18n.t('admin:actions.delete'),
      icon: TrashCan,
      disabled: hasAutoDeletionDate ? deleteDocumentDisabled : removeAttachmentDisabled
    },
    {
      onClick:
        transient?.selectedDocument?.signing_type === SIGNING_TYPES.UPLOAD
          ? () => onButtonClick(files.current[transient?.selectedDocument?.id])
          : mockFunction,
      label: I18n.t('admin:actions.upload'),
      disabled: uploadDocumentDisabled,
      icon: PendingEye
    },
    {
      onClick: () => {
        viewDocument(
          transient?.selectedDocument?.attachment_url?.length > 0
            ? transient?.selectedDocument?.attachment_url
            : mockFunction
        );
      },
      label: I18n.t('admin:actions.view'),
      icon: PendingEye,
      disabled: transient?.selectedDocument?.attachment_url === null
    }
  ];

  const loading = transient?.loadingOnboardingDocuments === document.id;
  const singleSignerId = document?.single_signer_id?.toString();
  const signer = transient?.signers?.find(s => s?.signer?.id == singleSignerId);
  const defaultSignerName = signer?.signer?.full_name;
  const isDocumentTypeImage = document.file_content_type?.includes('image');

  return (
    <ColumnView key={index} id={'document-info'}>
      <RowView paddingHorizontal={20} paddingVertical={12}>
        <RowView
          height={80}
          width={80}
          onClick={updateTransientProps.bind(null, { showDocumentDetails: true, selectedDocument: document })}>
          <img
            slot="media"
            className="document-img"
            src={
              document?.deleted_at?.length > 0 && document?.state?.length > 0
                ? DocumentLock
                : document?.attachment_url?.length > 0 &&
                  document?.signing_type !== SIGNING_TYPES.SIGN &&
                  isDocumentTypeImage
                ? document?.attachment_url
                : PDFPending
            }
          />
        </RowView>
        <ColumnView
          onClick={updateTransientProps.bind(null, { showDocumentDetails: true, selectedDocument: document })}
          paddingLeft={20}
          paddingRight={4}
          alignItems={'flex-start'}
          height={80}>
          <RowView justifyContent={'space-between'}>
            <PendingEye fillColor={document?.deleted_at?.length > 0 ? ICON_COLOR.GRAY : ICON_COLOR.BLUE} />
            <RowView gap={2} width={'auto'}>
              <img
                className="h-14 w-14"
                src={
                  (document?.attachment_url !== null && document?.uploaded) ||
                  document?.applicant_signed_on !== null ||
                  document?.applicant_read_on !== null
                    ? StateActive
                    : StateDisabled
                }
              />
              <img
                className="h-14 w-14"
                src={
                  (document?.accepted_on !== null || document?.admin_signed_on) && document?.rejection_reason === ''
                    ? StateActive
                    : document?.rejection_reason !== ''
                    ? StateRejected
                    : StateDisabled
                }
              />
            </RowView>
          </RowView>
          <div className="text-sm line-height-normal font-medium pb-4">{document.name}</div>

          <div className="text-xxs pb-4">
            {document?.deleted_at?.length > 0
              ? I18n.t('admin:anonymized')
              : document?.document_type === DOCUMENT_TYPES.MC_ADMIN_TIME_LEASHED ||
                document?.document_type === DOCUMENT_TYPES.TIME_LEASHED
              ? `${I18n.t('admin:dueDate')} ${getExpirationDisplay(document?.expiration_date)}`
              : getTimeSinceMessage(document?.created_at)}
          </div>

          <div className={`text-xxs ${textColor} pb-4`}>{documentStatus}</div>
        </ColumnView>

        {(!document?.ba_only_signature || document?.applicant_signed_on !== null) && (
          <ColumnView width={'auto'} onClick={updateTransientProps.bind(null, { selectedDocument: document })}>
            {document?.signing_type === SIGNING_TYPES.SIGN ? (
              <Button.OutlineSmall
                onClick={handleDocumentClick.bind(null, {
                  defaultSignerName,
                  document,
                  updateGrowlProps,
                  userId,
                  viewDocument
                })}
                width={'85px'}>
                {documentActionMessage}
              </Button.OutlineSmall>
            ) : document?.signing_type === SIGNING_TYPES.UPLOAD ? (
              <Bubble
                loading={loading}
                position={'top'}
                width={'85px'}
                disabled={isOffline || document?.deleted_at?.length > 0}
                items={items}
                label={I18n.t('profile:documentsTab.documentsButtons:actionsButton')}
              />
            ) : (
              <Button.OutlineSmall width={'85px'} onClick={readDocument.bind(null, readAttachment, document)}>
                {documentActionMessage}
              </Button.OutlineSmall>
            )}
          </ColumnView>
        )}
        <input
          type="file"
          ref={handleRef.bind(null, files, transient?.selectedDocument?.id)}
          className="display-none"
          onChange={handleFileUpload.bind(null, updateUserDocument, transient?.selectedDocument?.id)}
          slot={'fixed'}
          //eslint-disable-next-line
          accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />
      </RowView>
    </ColumnView>
  );
};

const initDropDown = (
  {
    autocompleteDropdownAll = {},
    updateTransientPropWithValidations,
    countries,
    userOnboardingProfile,
    transient,
    updateOnboardingUserProfile,
    updateTransientProps,
    updateUserChanges
  },
  page
) => {
  const profileId = userOnboardingProfile.user_id;

  autocompleteDropdownAll.current = f7.autocomplete.create({
    inputEl: page.$el.find('#userNationality-dropdown'),
    openIn: 'dropdown',
    valueProperty: 'nationality',
    textProperty: 'translated_nationality',
    source(query, render) {
      const results = [];
      const uniqueNationalities = new Set();

      for (let i = 0; i < countries?.length; i += 1) {
        const country = countries[i];
        const { translated_nationality, nationality } = country;

        if (translated_nationality.toLowerCase().includes(query.toLowerCase())) {
          if (!uniqueNationalities.has(nationality)) {
            uniqueNationalities.add(nationality);
            results.push({ translated_nationality, nationality });
          }
        }
      }

      render(results);
    },
    on: {
      change: countries =>
        handleOnChangeWithValidations(
          updateTransientPropWithValidations,
          'nationality',
          [validations.isObjectRequired],
          {
            target: { value: countries[0] }
          }
        ),
      closed: () => {
        updateUserChanges({
          profileId,
          transient,
          updateOnboardingUserProfile,
          updateTransientProps,
          fieldName: 'nationality'
        });
      }
    }
  });

  autocompleteDropdownAll.current = f7.autocomplete.create({
    inputEl: page.$el.find('#countryOfCitizenship-dropdown'),
    openIn: 'dropdown',
    valueProperty: 'short_code',
    textProperty: 'translated_name',
    source(query, render) {
      const results = [];
      for (let i = 0; i < countries?.length; i += 1) {
        if (countries[i].translated_name.toLowerCase().indexOf(query.toLowerCase()) >= 0)
          results.push({
            translated_name: countries[i].translated_name,
            short_code: countries[i].short_code
          });
      }

      render(results);
    },
    on: {
      change: countries =>
        handleOnChangeWithValidations(
          updateTransientPropWithValidations,
          'country_of_citizenship',
          [validations.isObjectRequired],
          {
            target: { value: countries[0] }
          }
        ),
      closed: () => {
        updateUserChanges({
          profileId,
          transient,
          updateOnboardingUserProfile,
          updateTransientProps,
          fieldName: 'country_of_citizenship'
        });
      }
    }
  });

  autocompleteDropdownAll.current = f7.autocomplete.create({
    inputEl: page.$el.find('#birthCountry-dropdown'),
    openIn: 'dropdown',
    valueProperty: 'short_code',
    textProperty: 'translated_name',
    source(query, render) {
      const results = [];
      for (let i = 0; i < countries?.length; i += 1) {
        if (countries[i].translated_name.toLowerCase().indexOf(query.toLowerCase()) >= 0)
          results.push({
            translated_name: countries[i].translated_name,
            short_code: countries[i].short_code
          });
      }

      render(results);
    },
    on: {
      change: countries =>
        handleOnChangeWithValidations(
          updateTransientPropWithValidations,
          'country_of_birth',
          [validations.isObjectRequired],
          {
            target: { value: countries[0] }
          }
        ),
      closed: () => {
        updateUserChanges({
          profileId,
          transient,
          updateOnboardingUserProfile,
          updateTransientProps,
          fieldName: 'country_of_birth'
        });
      }
    }
  });
};

const getAgentDocumentStatus = document => {
  const formattedUploadedOnDate = document?.uploaded_on ? moment(document.uploaded_on).format('DD/MM/YYYY') : '';
  const formattedSignedOnDate = document?.applicant_signed_on
    ? moment(document.applicant_signed_on).format('DD/MM/YYYY')
    : '';
  const formattedReadOnDate = document?.applicant_read_on
    ? moment(document.applicant_read_on).format('DD/MM/YYYY')
    : '';

  if (document?.uploaded_on?.length && document.uploaded && document.signing_type === SIGNING_TYPES.UPLOAD) {
    return `${I18n.t('admin:uploadedOn')} ${formattedUploadedOnDate}`;
  } else if (document?.applicant_signed_on !== null && document.signing_type === SIGNING_TYPES.SIGN) {
    return `${I18n.t('admin:signedOn')} ${formattedSignedOnDate}`;
  } else if (document?.applicant_read_on !== null && document.signing_type === SIGNING_TYPES.READ_ONLY) {
    return `${I18n.t('admin:readOn')} ${formattedReadOnDate}`;
  } else {
    return I18n.t('admin:pending');
  }
};

const getAdminDocumentStatus = document => {
  const formattedAcceptedOnDate = document?.accepted_on ? moment(document.accepted_on).format('DD/MM/YYYY') : '';
  const formattedAdminSignedOnDate = document?.admin_signed_on
    ? moment(document.admin_signed_on).format('DD/MM/YYYY')
    : '';

  if (document?.rejection_reason?.length) {
    return I18n.t('admin:rejected');
  } else if (document?.accepted_on?.length && document.signing_type !== SIGNING_TYPES.SIGN) {
    return `${I18n.t('admin:acceptedOn')} ${formattedAcceptedOnDate}`;
  } else if (document?.admin_signed_on?.length && document.signing_type === SIGNING_TYPES.SIGN) {
    return `${I18n.t('admin:signedOn')} ${formattedAdminSignedOnDate}`;
  } else {
    return I18n.t('admin:pending');
  }
};

export {
  getAdminDocumentStatus,
  getAgentDocumentStatus,
  getDateRange,
  getExpirationDisplay,
  getFormattedValue,
  getTimeSinceMessage,
  handleChangeBankAccount,
  handleFileUpload,
  initDropDown,
  isDisabled,
  renderDocument,
  renderOnboardingProfileNavButtons,
  renderReleaseNextBlock,
  renderSelectOptions,
  renderVetProfile,
  updateUserChanges,
  viewDocument
};
