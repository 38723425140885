import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { Modal } from 'Components';
import { mockFunction } from 'Helpers';
import { ColumnView, RowView } from 'Containers';
import { DOCUMENT_STATES, DOCUMENT_TYPES, MODAL_SIZES, SIGNING_TYPES } from 'Constants';
import { DocumentLock, PDFPending, StateActive, StateDisabled, StateRejected } from 'Icons';

import './style.scss';
import {
  getAdminDocumentStatus,
  getAgentDocumentStatus,
  getExpirationDisplay,
  getTimeSinceMessage
} from '../../../utils/helpers';

const DocumentDetails = ({
  popupKey = '',
  selectedDocument = {},
  showPopup = false,
  updateTransientProps = mockFunction
}) => {
  const {
    accepted_on,
    approved_on,
    deletion_date,
    admin_signed_on,
    applicant_read_on,
    applicant_signed_on,
    attachment_url,
    created_at,
    deleted_at,
    document_type,
    expiration_date,
    name,
    rejection_reason,
    state,
    uploaded_on,
    uploaded
  } = selectedDocument;

  const getDocumentStatus = (state, document_type, applicant_read_on, applicant_signed_on, uploaded_on) => {
    if (state === DOCUMENT_STATES.ACCEPTED) {
      return I18n.t('admin:documentStatus.accepted');
    }

    if (state === DOCUMENT_STATES.REJECTED) {
      return I18n.t('admin:documentStatus.rejected');
    }

    if (document_type === DOCUMENT_TYPES.READ_ONLY) {
      return applicant_read_on === null
        ? I18n.t('admin:documentStatus.waitingForRead')
        : I18n.t('admin:documentStatus.readOn');
    }

    if (applicant_signed_on !== null || applicant_read_on !== null || uploaded_on !== null) {
      return I18n.t('admin:documentStatus.waitingForApproval');
    }

    return I18n.t('admin:documentStatus.waitingForUpload');
  };

  const renderDocumentInfo = (title, message, iconSrc, className) => (
    <RowView justifyContent={'space-between'}>
      <RowView width={'auto'}>
        <div className="gray pr-2">{title}</div>
        <div className={`pl-2 pt-2 ${className}`}>{message}</div>
      </RowView>
      {iconSrc && (
        <RowView width={'auto'}>
          <img className="h-14 w-14" src={iconSrc} />
        </RowView>
      )}
    </RowView>
  );

  const documentStatus = getDocumentStatus(state, document_type, applicant_read_on, applicant_signed_on, uploaded_on);

  const textColor =
    state === DOCUMENT_STATES.ACCEPTED ? 'text-green' : state === DOCUMENT_STATES.REJECTED ? ' text-red' : 'text-blue';

  const agentDocumentIconStatus =
    (attachment_url !== null && uploaded) || applicant_signed_on !== null || applicant_read_on !== null
      ? StateActive
      : StateDisabled;

  const adminDocumentIconStatus =
    (accepted_on !== null || admin_signed_on !== null) && rejection_reason === ''
      ? StateActive
      : rejection_reason !== ''
      ? StateRejected
      : StateDisabled;

  const displayAgentMessage = getAgentDocumentStatus(selectedDocument);
  const displayMCAdminMessage = getAdminDocumentStatus(selectedDocument);
  const isDocumentTypeImage = selectedDocument.file_content_type?.includes('image');
  const displayApprovedMessage = moment(approved_on).format('DD/MM/YYYY');
  const displayRejectedMessage = I18n.t(`admin:declineDocument.${rejection_reason}`);

  const documentInfoGap = deletion_date || deleted_at ? 2 : approved_on || state === DOCUMENT_STATES.REJECTED ? 8 : 24;

  return (
    <Modal
      id={'document-info'}
      open={showPopup}
      size={MODAL_SIZES.SMALL}
      closeByBackdropClick={false}
      onClose={updateTransientProps.bind(null, { [popupKey]: false })}>
      <Modal.Title closeButton={true}>
        <RowView>
          <div className="title-md">{name}</div>
        </RowView>
      </Modal.Title>

      <Modal.Content>
        <ColumnView gap={deletion_date || deleted_at ? 16 : 24}>
          <RowView>
            <RowView height={80} width={80}>
              <img
                slot="media"
                className="document-img"
                src={
                  deleted_at?.length > 0 && state?.length > 0
                    ? DocumentLock
                    : attachment_url?.length > 0 && document?.signing_type !== SIGNING_TYPES.SIGN && isDocumentTypeImage
                    ? attachment_url
                    : PDFPending
                }
              />
            </RowView>
            <ColumnView
              onClick={updateTransientProps.bind(null, { [popupKey]: true })}
              paddingLeft={20}
              paddingRight={4}
              alignItems={'flex-start'}
              height={80}
              gap={4}>
              <div className="text-xxs gray font-medium pb-4">{document_type}</div>
              <RowView justifyContent={'space-between'}>
                <div className="text-sm line-height-normal font-medium pb-4">{name}</div>
                <RowView gap={2} width={'auto'}>
                  <img className="h-14 w-14" src={agentDocumentIconStatus} />
                  <img className="h-14 w-14" src={adminDocumentIconStatus} />
                </RowView>
              </RowView>

              <div className="text-xxs pb-4">
                {deleted_at?.length > 0
                  ? I18n.t('admin:anonymized')
                  : document_type === DOCUMENT_TYPES.MC_ADMIN_TIME_LEASHED ||
                    document_type === DOCUMENT_TYPES.TIME_LEASHED
                  ? `${I18n.t('admin:dueDate')} ${getExpirationDisplay(expiration_date)}`
                  : getTimeSinceMessage(created_at)}
              </div>
              <div className={`text-xxs ${textColor} pb-4`}>{documentStatus}</div>
            </ColumnView>
          </RowView>

          <ColumnView justifyContent={'space-between'} gap={documentInfoGap}>
            {renderDocumentInfo(I18n.t('admin:agentStatus'), displayAgentMessage, agentDocumentIconStatus)}
            {renderDocumentInfo(I18n.t('admin:mcAdminStatus'), displayMCAdminMessage, adminDocumentIconStatus)}
            {approved_on !== null && renderDocumentInfo(I18n.t('admin:approved'), displayApprovedMessage)}
            {state === DOCUMENT_STATES.REJECTED &&
              renderDocumentInfo(I18n.t('admin:reason'), displayRejectedMessage, '', 'text-red')}

            {(deletion_date || deleted_at) &&
              renderDocumentInfo(
                I18n.t(deleted_at !== null ? 'admin:deleted' : 'admin:deletionIn'),
                deleted_at
                  ? moment(deleted_at).format('DD/MM/YYYY')
                  : `${moment(selectedDocument.deletion_date).diff(moment(), 'days')} days`
              )}
          </ColumnView>
        </ColumnView>
      </Modal.Content>
    </Modal>
  );
};

DocumentDetails.propTypes = {
  popupKey: PropTypes.string.isRequired,
  selectedDocument: PropTypes.object.isRequired,
  showPopup: PropTypes.bool.isRequired,
  updateTransientProps: PropTypes.func.isRequired
};

export default DocumentDetails;
