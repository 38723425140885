import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'framework7-react';

import { ColumnView } from 'Containers';

import './style.scss';
import { Component } from '../../subviews';

const Page = props => {
  const {
    components,
    currentCheckInAdress,
    currentFlowData,
    currentPageIndex,
    flowCountry,
    getUKAddress,
    id: pageId,
    invalidateComponent,
    isOffline,
    mode,
    name,
    pageIsInvalidOnNext,
    referenced_products,
    transient,
    updateCurrentFlowData,
    updateTransientProps,
    userFullName,
    validateComponent,
    validateOnlineBankAccount
  } = props;

  return name ? (
    <ColumnView justifyContent={'flex-start'} alignItems={'stretch'}>
      <List noHairlines>
        <ul>
          {components.map(component => (
            <Component
              pageId={pageId}
              isValid={currentFlowData[component.component_key]?.isValid}
              visible={currentFlowData[component.component_key]?.visible}
              key={component.id}
              component={component}
              currentPageIndex={currentPageIndex}
              getUKAddress={getUKAddress}
              referenced_products={referenced_products}
              updateCurrentFlowData={updateCurrentFlowData}
              forceUpdate={currentFlowData[component.component_key]?.forceUpdate}
              componentFlowValue={currentFlowData[component.component_key]?.value || ''}
              componentFlowAgreementValue={currentFlowData[component.component_key]?.agreement || false}
              isDirty={currentFlowData[component.component_key]?.value !== undefined}
              isOffline={isOffline}
              currentCheckInAdress={currentCheckInAdress}
              invalidateComponent={invalidateComponent}
              validateComponent={validateComponent}
              validateOnlineBankAccount={validateOnlineBankAccount}
              pageIsInvalidOnNext={pageIsInvalidOnNext}
              transient={transient}
              userFullName={userFullName}
              updateTransientProps={updateTransientProps}
              mode={mode}
              country={component?.country}
              flowCountry={flowCountry}
            />
          ))}
        </ul>
      </List>
    </ColumnView>
  ) : null;
};

Page.propTypes = {
  components: PropTypes.array,
  currentCheckInAdress: PropTypes.array,
  currentFlowData: PropTypes.object,
  currentPageIndex: PropTypes.number,
  flowCountry: PropTypes.string,
  getUKAddress: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  invalidateComponent: PropTypes.func,
  isOffline: PropTypes.bool,
  mode: PropTypes.string,
  name: PropTypes.string,
  pageIsInvalidOnNext: PropTypes.bool,
  referenced_products: PropTypes.array,
  transient: PropTypes.object,
  updateCurrentFlowData: PropTypes.func,
  updateTransientProps: PropTypes.func,
  userFullName: PropTypes.string,
  validateComponent: PropTypes.func,
  validateOnlineBankAccount: PropTypes.func
};

export default Page;
