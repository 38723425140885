import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { isSmallDevice } from 'Helpers';
import { SimpleTable } from 'Components';
import { FONT_SIZE, TEXT_ALIGN } from 'Constants';

const columns = [
  {
    name: I18n.t('admin:dailyFiguresTableHeaders.campaign'),
    field: 'name',
    sorting: true,
    itemSize: FONT_SIZE.SMALL,
    headerAlign: TEXT_ALIGN.START,
    itemAlign: TEXT_ALIGN.START
  }
];

const DonationAmount = ({ dailyFigures = {}, transient = {} }) => {
  const { getAdminDailyFiguresLoading } = transient;
  const { campaigns = [], campaigns_products_headers = [] } = dailyFigures;

  const amountColumns = (campaigns_products_headers || []).map(amount => ({
    field: `${amount}`,
    name: `${amount}`,
    itemSize: FONT_SIZE.SMALL,
    itemAlign: TEXT_ALIGN.CENTER
  }));
  const allColumns = [...columns, ...amountColumns];

  return (
    <SimpleTable
      columns={allColumns}
      rows={campaigns}
      maxRowWidth={isSmallDevice ? 'w-120' : 'w-auto'}
      processing={getAdminDailyFiguresLoading}
    />
  );
};

DonationAmount.propTypes = {
  dailyFigures: PropTypes.object,
  transient: PropTypes.object
};

export default DonationAmount;
