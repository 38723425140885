import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from 'framework7-react';

import { I18n } from 'Locales';
import { RowView } from 'Containers';
import { mockFunction } from 'Helpers';
import { MODAL_SIZES } from 'Constants';
import { Button, Modal } from 'Components';

const handleClick = ({
  admin = {},
  getMcPin = mockFunction,
  transient = {},
  updateAdminProps = mockFunction,
  updateTransientProps = mockFunction
}) => {
  const { selectedMcPin } = transient;
  updateTransientProps({ mcPinsModal: false, selectedRows: [] });
  if (selectedMcPin !== undefined && selectedMcPin !== '') {
    updateAdminProps({ selectedMcPin: selectedMcPin });
    getMcPin(selectedMcPin);
  } else {
    updateAdminProps({ selectedMcPin: admin?.selectedMcPin });
    getMcPin(admin?.selectedMcPin);
  }
};

const SwitchMcPinModal = ({
  admin = {},
  getMcPin = mockFunction,
  mcPinsModal = false,
  transient = {},
  updateAdminProps = mockFunction,
  updateTransientProps = mockFunction,
  user = {}
}) => (
  <Modal
    id={'mc-switch'}
    open={mcPinsModal}
    size={MODAL_SIZES.MEDIUM}
    onClose={updateTransientProps.bind(null, {
      mcPinsModal: false,
      selectedMcPin: admin.selectedMcPin || ''
    })}>
    <Modal.Title>
      <RowView>
        <div className="text-md font-bold">{I18n.t('admin:marketingCompany')}</div>
      </RowView>
      <RowView>
        <div>{I18n.t('admin:selectMarketingCompany')}</div>
      </RowView>
    </Modal.Title>

    <Modal.Content>
      <List noHairlines className="w-full">
        {user?.mc_pins?.map((item, index) => (
          <ListItem
            key={index}
            radio
            radioIcon={'start'}
            title={`${item} - ${user?.mc_names[index]}`}
            checked={transient?.selectedMcPin ? item === transient?.selectedMcPin : item === admin?.selectedMcPin}
            onChange={updateTransientProps.bind(null, { selectedMcPin: item })}
          />
        ))}
      </List>
    </Modal.Content>

    <Modal.Actions>
      <Button.OutlineSmall onClick={updateTransientProps.bind(null, { mcPinsModal: false, selectedMcPin: '' })}>
        {I18n.t('general:cancel')}
      </Button.OutlineSmall>
      <Button.PrimarySmall
        onClick={handleClick.bind(null, { admin, getMcPin, transient, updateAdminProps, updateTransientProps })}>
        {I18n.t('general:ok')}
      </Button.PrimarySmall>
    </Modal.Actions>
  </Modal>
);

SwitchMcPinModal.propTypes = {
  admin: PropTypes.object,
  getMcPin: PropTypes.func,
  mcPinsModal: PropTypes.bool,
  transient: PropTypes.object,
  updateAdminProps: PropTypes.func,
  updateTransientProps: PropTypes.func,
  user: PropTypes.object
};

export default SwitchMcPinModal;
