import moment from 'moment';

import { DATE_FORMAT } from 'Constants';

// Date picker works with this format only.
const yesterday = moment().subtract(1, 'days').format(DATE_FORMAT.INTERNAL);

// Ensure the 'name' property of each filter matches the key sent to the server.
export const filters = {
  onboarding: {
    number: [
      { name: 'mc_pin', type: 'search' },
      { name: 'badge_number', type: 'search' }
    ],
    select: [
      { name: 'active_onboarding', type: 'checkbox', quickFilter: true, defaultActive: true },
      { name: 'active_users', type: 'checkbox', quickFilter: true, defaultActive: true },
      { name: 'incomplete_profile_only', type: 'checkbox' }
    ],

    name: [
      { name: 'agent_name', type: 'search' },
      { name: 'by_campaign_name', type: 'search' },
      { name: 'by_client_name', type: 'search' },
      { name: 'by_state', type: 'search' },
      { name: 'by_marketing_company_name', type: 'search' }
    ]
  },

  dailyFigures: {
    name: [
      { name: 'filterText', type: 'search', displayInView: ['BAsView'], quickFilter: true },
      { name: 'filterNumber', type: 'search', displayInView: ['BAsView'] }
    ],

    date: [{ name: 'dailyFiguresDate', type: 'date', quickFilter: true, defaultDate: yesterday }],

    select: [
      {
        name: 'mc_pins',
        type: 'multiple',
        displayProperty: 'pin',
        displayForRole: ['Admin'],
        selectProperty: 'pin',
        optionsKey: 'marketingCompanies',
        quickFilter: true
      },
      {
        name: 'campaign_id',
        type: 'single',
        selectProperty: 'id',
        displayProperty: 'name',
        displayInView: ['BAsView', 'CampaignsView'],
        optionsKey: 'filtersActiveCampaigns',
        quickFilter: true
      },
      {
        name: 'client_id',
        type: 'single',
        selectProperty: 'id',
        displayProperty: 'name',
        optionsKey: 'clients',
        displayInView: ['AdminView'],
        quickFilter: true
      }
    ]
  }
};
