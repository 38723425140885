import { delay, put } from 'redux-saga/effects';

import Config from 'Config';
import { Types as GrowlTypes } from 'Reducers/growl';

// EXPORTED
export const alert = function* ({ persist = false }) {
  if (!persist) {
    yield delay(Config.GROWL_AUTOHIDE);
    yield put({
      type: GrowlTypes.UPDATE_PROP,
      key: 'visible',
      value: false
    });
  }
};
