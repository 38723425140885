import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { I18n } from 'Locales';
import { TextField } from 'Components';
import { ColumnView } from 'Containers';
import { COUNTRIES, REGIONS } from 'Constants';
import { valueSanitization, FormattedLabel } from 'Helpers';

import '../style.scss';
import { getFieldProp, getIconColor } from '../../../shared';

const sanitizedAddress = ({ setValues, value }, e) => {
  const sanitizedValue = valueSanitization(e);
  setValues(value, { AddressLine1: sanitizedValue });
};

const AddressFields = props => {
  const {
    country,
    fields,
    isOffline,
    isUnder10Chars,
    isValid,
    required,
    setValues,
    shouldShowError,
    transient,
    updateIsUnder10Chars,
    value
  } = props;
  const { address_field_disabled = true } = transient;
  const isUKRegions = REGIONS.UK.includes(country);
  const isUK = country === COUNTRIES.UK;
  const fieldDisabled = isUK && address_field_disabled && !isOffline;

  let addressValues = value;
  if (!addressValues) {
    addressValues = {
      City: '',
      AddressLine1: '',
      AddressLine2: '',
      AddressLine3: '',
      PostCode: '',
      search_address: ''
    };
  }

  const cityProp = getFieldProp(fields, 'City');
  const line1Prop = getFieldProp(fields, 'AddressLine1');
  const line2Prop = getFieldProp(fields, 'AddressLine2');
  const line3Prop = getFieldProp(fields, 'AddressLine3');
  const postCodeProp = getFieldProp(fields, 'PostCode');

  const { City, AddressLine1, AddressLine2, AddressLine3, PostCode } = addressValues;

  useEffect(() => {
    !isUKRegions && AddressLine1 !== '' && updateIsUnder10Chars(AddressLine1);
  }, []);

  const postCodeValidation = {
    FR: PostCode.length > 0 && !/^\d{5}$/.test(PostCode)
  };

  const postCodeValidationMessage = {
    FR: postCodeValidation[country] ? I18n.t('validations:postCodeFR') : ''
  };

  return (
    <ColumnView>
      <div className="w-full">
        <TextField
          disabled={fieldDisabled}
          label={FormattedLabel({ required, name: I18n.t('flow:components.address.line1') })}
          type={'text'}
          placeholder={line1Prop?.placeholder}
          value={AddressLine1}
          onChange={e => setValues(value, { AddressLine1: e.target.value })}
          onBlur={sanitizedAddress.bind(null, { setValues, value })}
          errorMessageForce={!isValid}
          errorMessage={
            !isValid
              ? shouldShowError && AddressLine1 === ''
                ? I18n.t('flow:generalError')
                : isUnder10Chars && !isUKRegions
                ? I18n.t('validations:min10Characters')
                : ''
              : ''
          }
          icon="material:check"
          iconColor={getIconColor(
            !isValid
              ? shouldShowError && AddressLine1 === ''
                ? true
                : isUnder10Chars && !isUKRegions
                ? true
                : false
              : false,
            AddressLine1
          )}
        />

        <TextField
          disabled={fieldDisabled}
          label={I18n.t('flow:components.address.line2')}
          type={'text'}
          placeholder={line2Prop?.placeholder}
          value={AddressLine2}
          onChange={e => setValues(value, { AddressLine2: e.target.value })}
          icon="material:check"
        />

        <TextField
          disabled={fieldDisabled}
          label={I18n.t('flow:components.address.line3')}
          type={'text'}
          placeholder={line3Prop?.placeholder}
          value={AddressLine3}
          onChange={e => setValues(value, { AddressLine3: e.target.value })}
          icon="material:check"
        />

        <TextField
          disabled={fieldDisabled}
          label={FormattedLabel({ required, name: I18n.t('flow:components.address.postalCodeLabel') })}
          type={'text'}
          placeholder={postCodeProp?.placeholder}
          value={PostCode}
          onChange={e => setValues(value, { PostCode: e.target.value })}
          errorMessageForce={(shouldShowError && PostCode === '') || postCodeValidation[country]}
          errorMessage={
            shouldShowError && PostCode === '' ? I18n.t('flow:generalError') : postCodeValidationMessage[country]
          }
          icon="material:check"
          iconColor={getIconColor((shouldShowError && PostCode === '') || postCodeValidation[country], PostCode)}
        />

        <TextField
          disabled={fieldDisabled}
          label={FormattedLabel({ required, name: I18n.t('flow:components.address.cityLabel') })}
          type={'text'}
          placeholder={cityProp?.placeholder}
          value={City}
          onChange={e => setValues(value, { City: e.target.value })}
          errorMessageForce={shouldShowError && City === ''}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(shouldShowError && City === '', City)}
        />
      </div>
    </ColumnView>
  );
};

AddressFields.propTypes = {
  component_key: PropTypes.any,
  country: PropTypes.string,
  currentCheckInAdress: PropTypes.array,
  fields: PropTypes.array,
  isOffline: PropTypes.bool,
  isUnder10Chars: PropTypes.bool,
  isValid: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  setValues: PropTypes.func,
  shouldShowError: PropTypes.bool,
  transient: PropTypes.object,
  updateIsUnder10Chars: PropTypes.func,
  updateValue: PropTypes.func,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.object)
};

export default AddressFields;
