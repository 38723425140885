import React from 'react';
import PropTypes from 'prop-types';

import { ColumnView } from 'Containers';

const POSITION = {
  'flex-start': 'flex-start',
  center: 'center',
  'flex-end': 'flex-end'
};

const Content = ({ children = undefined, contentPosition = 'flex-start' }) => (
  <div className="scrollable-content">
    <ColumnView height={'100%'} gap={16} justifyContent={POSITION[contentPosition]}>
      {children}
    </ColumnView>
  </div>
);

Content.propTypes = {
  children: PropTypes.any,
  contentPosition: PropTypes.string
};

export default Content;
