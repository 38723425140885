import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { Modal } from 'Components';
import { mockFunction } from 'Helpers';
import { MODAL_SIZES } from 'Constants';

import './style.scss';

const RejectionReasonModal = ({
  fieldConfig = [],
  isOpen = false,
  onClose = mockFunction,
  rejectedFields = {},
  rejectedReason = ''
}) => (
  <Modal id={'rejection-modal'} open={isOpen} size={MODAL_SIZES.MEDIUM} onClose={onClose} closeByBackdropClick={false}>
    <Modal.Title closeButton>
      <div className="rejection-modal__title">{I18n.t('profile:rejectionTitle')}</div>
    </Modal.Title>

    <Modal.Content>
      <div className="rejection-modal__content">
        <div className="rejection-modal__subtitle">{I18n.t('profile:rejectionText')}</div>
        <div className="rejection-modal__tags">
          {rejectedFields?.fields?.map((fieldName, index) => (
            <div key={index} className="rejection-modal__tags--tag">
              {fieldConfig
                .map(group => I18n.t(group.find(field => field.fieldName === fieldName)?.label))
                .filter(label => label)}
            </div>
          ))}
        </div>
        <div className="rejection-modal__reason">{rejectedReason}</div>
      </div>
    </Modal.Content>
  </Modal>
);

RejectionReasonModal.propTypes = {
  fieldConfig: PropTypes.arrayOf(PropTypes.array).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  rejectedFields: PropTypes.object,
  rejectedReason: PropTypes.string
};

export default RejectionReasonModal;
