import moment from 'moment';
import { put, select } from 'redux-saga/effects';

import { Api } from 'Core';
import { I18n } from 'Locales';
import { AdminDailyFigures } from 'Repositories';
import { Types as GrowlTypes } from 'Reducers/growl';
import { DAILY_FIGURES_TYPE, DATE_FORMAT } from 'Constants';
import { transient as transientSelector, Types as TransientTypes } from 'Reducers/transient';
import { adminDailyFigures as adminDailyFiguresSelector, Types as DailyFiguresTypes } from 'Reducers/adminDailyFigures';

export const getAdminDailyFigures = function* ({ data }) {
  const transient = yield select(transientSelector);
  const adminDailyFigures = yield select(adminDailyFiguresSelector);
  const dailyFigureType = data.dailyFigureType !== undefined ? data.dailyFigureType : adminDailyFigures.dailyFigureType;
  const dailyFigureView = data.dailyFigureView !== undefined ? data.dailyFigureView : adminDailyFigures.dailyFigureView;
  const sortColumn = data?.sortColumn || transient?.sort?.sortColumn;

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: DailyFiguresTypes.GET_ADMIN_DAILY_FIGURES_SUCCESS },
      fail: { type: DailyFiguresTypes.GET_ADMIN_DAILY_FIGURES_FAIL }
    },
    promise: AdminDailyFigures.getAdminDailyFigures({ ...data, dailyFigureType, dailyFigureView, sortColumn })
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: { getAdminDailyFiguresLoading: true }
  });
};

export const getAdminDailyFiguresSuccess = function* ({ payload }) {
  yield put({
    type: DailyFiguresTypes.UPDATE_PROPS,
    props: { dailyFigures: { ...payload } }
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: { getAdminDailyFiguresLoading: false }
  });
};

export const getAdminDailyFiguresFail = function* ({ error }) {
  yield put({ type: Api.API_ERROR, error });
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: { getAdminDailyFiguresLoading: false }
  });
};

export const getCampaignsByClientType = function* ({ data }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: DailyFiguresTypes.GET_CAMPAIGNS_BY_CLIENT_TYPE_SUCCESS },
      fail: { type: Api.API_ERROR }
    },

    promise: AdminDailyFigures.getCampaignsByClientType(data)
  });
};

export const getCampaignsByClientTypeSuccess = function* ({ payload }) {
  const activeCampaigns = [...payload].filter(campaign => campaign.active === true);

  const allCampaignsOption = { id: '', name: I18n.t('filters:dailyFigures.allCampaigns') };
  const filtersActiveCampaigns = [allCampaignsOption, ...activeCampaigns];

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: { activeCampaigns: [...activeCampaigns], filtersActiveCampaigns }
  });
};

export const getMarketingCompanies = function* () {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: DailyFiguresTypes.GET_MARKETING_COMPANIES_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: AdminDailyFigures.getMarketingCompanies()
  });
};

export const getMarketingCompaniesSuccess = function* ({ payload }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: { marketingCompanies: payload }
  });
};

export const getClientsByClientType = function* ({ data }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: DailyFiguresTypes.GET_CLIENTS_BY_CLIENT_TYPE_SUCCESS },
      fail: { type: Api.API_ERROR }
    },

    promise: AdminDailyFigures.getClientsByClientType(data)
  });
};

export const getClientsByClientTypeSuccess = function* ({ payload }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: { clients: [...payload] }
  });
};

export const manualCheckIn = function* ({ data }) {
  const formData = new FormData();
  const yesterday = moment().subtract(1, 'days').format(DATE_FORMAT.UI);

  formData.append('campaign_id', data.campaign_id);
  formData.append('start_date', data.start_date || yesterday);
  formData.append('end_date', data.end_date || yesterday);
  formData.append('user_id', data.user_id);
  formData.append('checked', data.checked);

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: DailyFiguresTypes.MANUAL_CHECK_IN_SUCCESS },
      fail: { type: DailyFiguresTypes.MANUAL_CHECK_IN_FAIL }
    },
    promise: AdminDailyFigures.manualCheckIn(formData)
  });
};

export const manualCheckInSuccess = function* ({ payload }) {
  const transient = yield select(transientSelector);
  const { message, success } = payload;
  const filters = {
    campaign_id: transient?.filters?.campaign_id || '',
    dailyFiguresDate_start_date: transient?.filters?.dailyFiguresDate_start_date,
    dailyFiguresDate_end_date: transient?.filters?.dailyFiguresDate_end_date,
    daily_figure_type: DAILY_FIGURES_TYPE.CHARITY,
    client_id: transient?.filters?.client_id,
    filterText: transient?.filters?.filterText,
    mc_pins: transient?.filters?.mc_pins,
    filterNumber: transient?.filters?.filterNumber
  };

  if (!success) {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.generalError.title'),
      body: message,
      kind: 'error'
    });
  } else {
    yield put({
      type: DailyFiguresTypes.GET_ADMIN_DAILY_FIGURES,
      data: filters
    });
  }

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      row: {},
      campaignId: '',
      showManualCheckInModal: false
    }
  });
};

export const manualCheckInFail = function* ({ error }) {
  yield put({ type: Api.API_ERROR, error });

  const errorMessage = error?.response?.data?.errors?.[0] || I18n.t('growl:error.generalError.message');
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.generalError.title'),
    body: errorMessage,
    kind: 'error'
  });
};

export const manualCheckInCommercial = function* ({ data }) {
  const formData = new FormData();
  const yesterday = moment().subtract(1, 'days').format(DATE_FORMAT.UI);

  formData.append('campaign_id', data.campaign_id);
  formData.append('start_date', data.start_date || yesterday);
  formData.append('end_date', data.end_date || yesterday);
  formData.append('user_id', data.user_id);
  formData.append('checked', data.checked);

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: DailyFiguresTypes.MANUAL_CHECK_IN_COMMERCIAL_SUCCESS },
      fail: { type: DailyFiguresTypes.MANUAL_CHECK_IN_COMMERCIAL_FAIL }
    },
    promise: AdminDailyFigures.manualCheckInCommercial(formData)
  });
};

export const manualCheckInCommercialSuccess = function* ({ payload }) {
  const transient = yield select(transientSelector);
  const { message, success } = payload;
  const filters = {
    campaign_id: transient?.filters?.campaign_id || '',
    dailyFiguresDate_start_date: transient?.filters?.dailyFiguresDate_start_date,
    dailyFiguresDate_end_date: transient?.filters?.dailyFiguresDate_end_date,
    daily_figure_type: DAILY_FIGURES_TYPE.COMMERCIAL,
    client_id: transient?.filters?.client_id,
    filterText: transient?.filters?.filterText,
    mc_pins: transient?.filters?.mc_pins,
    filterNumber: transient?.filters?.filterNumber
  };

  if (!success) {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.generalError.title'),
      body: message,
      kind: 'error'
    });
  } else {
    yield put({
      type: DailyFiguresTypes.GET_ADMIN_DAILY_FIGURES,
      data: filters
    });
  }

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      row: {},
      campaignId: '',
      showManualCheckInModal: false
    }
  });
};

export const manualCheckInCommercialFail = function* ({ error }) {
  yield put({ type: Api.API_ERROR, error });

  const errorMessage = error?.response?.data?.errors?.[0] || I18n.t('growl:error.generalError.message');
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.generalError.title'),
    body: errorMessage,
    kind: 'error'
  });
};

export const updateSales = function* ({ data }) {
  const formData = new FormData();

  formData.append('campaign_id', data.campaign_id);
  formData.append('date', data.date);
  formData.append('user_id', data.user_id);
  formData.append('sales', data.sales);

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: DailyFiguresTypes.UPDATE_SALES_SUCCESS },
      fail: { type: DailyFiguresTypes.UPDATE_SALES_FAIL }
    },
    promise: AdminDailyFigures.updateSales(formData)
  });
};

export const updateSalesSuccess = function* () {
  const transient = yield select(transientSelector);
  const data = {
    campaign_id: transient?.filters?.campaign_id || '',
    dailyFiguresDate_start_date: transient?.filters?.dailyFiguresDate_start_date,
    dailyFiguresDate_end_date: transient?.filters?.dailyFiguresDate_end_date,
    daily_figure_type: DAILY_FIGURES_TYPE.COMMERCIAL,
    client_id: transient?.filters?.client_id,
    filterText: transient?.filters?.filterText,
    mc_pins: transient?.filters?.mc_pins,
    filterNumber: transient?.filters?.filterNumber
  };

  yield put({
    type: DailyFiguresTypes.GET_ADMIN_DAILY_FIGURES,
    data
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      row: {},
      sales: '',
      campaignId: '',
      date: '',
      showUpdateSalesModal: false
    }
  });
};

export const updateSalesFail = function* ({ error }) {
  yield put({ type: Api.API_ERROR, error });

  const errorMessage = error?.response?.data?.errors?.[0] || I18n.t('growl:generalError.message');
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.generalError.title'),
    body: errorMessage,
    kind: 'error'
  });
};
