import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { isSmallDevice } from 'Helpers';
import { SimpleTable } from 'Components';
import { FONT_SIZE, TEXT_ALIGN } from 'Constants';

const ProductSplit = ({ dailyFigures = {}, transient = {} }) => {
  const { users = [], users_products_headers = [] } = dailyFigures;
  const tableHeaders = (users_products_headers || [])?.map(header => ({
    name: header,
    field: header,
    itemSize: FONT_SIZE.SMALL,
    itemAlign: TEXT_ALIGN.CENTER
  }));

  const columns = [
    {
      name: I18n.t('admin:baName'),
      field: 'name',
      itemSize: FONT_SIZE.SMALL,
      headerAlign: TEXT_ALIGN.START
    }
  ];

  const allColumns = [...columns, ...tableHeaders];

  const { getAdminDailyFiguresLoading = false } = transient;

  return (
    <SimpleTable
      columns={allColumns}
      rows={users}
      maxRowWidth={isSmallDevice ? 'w-120' : 'w-auto'}
      processing={getAdminDailyFiguresLoading}
    />
  );
};

ProductSplit.propTypes = {
  dailyFigures: PropTypes.object,
  transient: PropTypes.object
};

export default ProductSplit;
