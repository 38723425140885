import React from 'react';
import PropTypes from 'prop-types';
import { Link, Tab, Tabs, Toolbar as ToolbarF7 } from 'framework7-react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { ColumnView } from 'Containers';
import { QuickFilters } from 'Components';
import { DAILY_FIGURES_TAB_VIEWS, FILTERS, PAGE_NAMES } from 'Constants';

import '../style.scss';
import Status from '../../subviews/Status';
import ProductSplit from '../../subviews/ProductSplit';

const Charity = ({
  adminDailyFigures = {},
  adminFilters = {},
  getAdminDailyFigures = mockFunction,
  manualCheckIn = mockFunction,
  transient = {},
  updateDailyFiguresProps = mockFunction,
  updateGrowlProps = mockFunction,
  updateTransientProps = mockFunction,
  updateUserProps = mockFunction,
  userRole = ''
}) => {
  const { baViewsTabs = [], currentTableView = DAILY_FIGURES_TAB_VIEWS.STATUS, dailyFigures = {} } = adminDailyFigures;
  const pages = { status: Status, productSplit: ProductSplit };

  return (
    <ColumnView id={'charity-view'}>
      <QuickFilters
        filtersType={FILTERS.DAILY_FIGURES}
        fetchTableItems={getAdminDailyFigures}
        quickFilters={adminFilters}
        role={userRole}
        transient={transient}
        updateTransientProps={updateTransientProps}
        updateUserProps={updateUserProps}
        view={PAGE_NAMES.BAS_VIEW}
      />

      <ToolbarF7 className="navbar-style" position={'top'} tabbar noShadow noHairLine>
        {baViewsTabs?.map((page, index) => (
          <Link
            key={index}
            tabLink={currentTableView}
            tabLinkActive={page === currentTableView}
            onClick={updateDailyFiguresProps.bind(null, { currentTableView: page })}>
            <span className={`tab-label ${page === currentTableView && 'active'}`}>
              {I18n.t(`admin:baViewsTabs.${page}`)}
            </span>
          </Link>
        ))}
      </ToolbarF7>

      <Tabs className="w-full h-full">
        {baViewsTabs.map((page, index) => {
          const PageComp = pages[page] || pages[DAILY_FIGURES_TAB_VIEWS.STATUS];
          return (
            <Tab id={currentTableView} key={index} tabActive={page === currentTableView}>
              <PageComp
                currentTableView={currentTableView}
                dailyFigures={dailyFigures}
                userRole={userRole}
                updateGrowlProps={updateGrowlProps}
                getAdminDailyFigures={getAdminDailyFigures}
                manualCheckIn={manualCheckIn}
                transient={transient}
                updateTransientProps={updateTransientProps}
              />
            </Tab>
          );
        })}
      </Tabs>
    </ColumnView>
  );
};

Charity.propTypes = {
  adminDailyFigures: PropTypes.object,
  adminFilters: PropTypes.object,
  getAdminDailyFigures: PropTypes.func,
  manualCheckIn: PropTypes.func,
  transient: PropTypes.object,
  updateDailyFiguresProps: PropTypes.func,
  updateGrowlProps: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateUserProps: PropTypes.func,
  userRole: PropTypes.string
};

export default Charity;
