import React from 'react';
import PropTypes from 'prop-types';

import { RowView } from 'Containers';

const Actions = ({ children = undefined }) => (
  <RowView justifyContent={'flex-end'} gap={8} paddingTop={8}>
    {children}
  </RowView>
);

Actions.propTypes = {
  children: PropTypes.any
};

export default Actions;
