import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ArrowUp } from 'Icons';
import { ColumnView, RowView } from 'Containers';

import './style.scss';

const Accordion = ({ items = [], title = '' }) => {
  const [open, setOpen] = useState(false);
  const filteredItems = items.filter(({ hide }) => !hide);

  return (
    <div id="accordion">
      <ColumnView paddingHorizontal={12} paddingVertical={12} gap={16}>
        <RowView onClick={setOpen.bind(null, !open)} gap={8}>
          <span className="title">{title}</span>
          <ArrowUp className={`${open ? 'rotate180' : 'rotate0'}`} />
        </RowView>

        {open && (
          <ColumnView gap={12}>
            {filteredItems.map((item, index) => (
              <div key={index} className="item" onClick={item?.onClick}>
                {item?.name}
              </div>
            ))}
          </ColumnView>
        )}
      </ColumnView>
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string
};

export default Accordion;
