import React from 'react';
import Cleave from 'cleave.js';

import { I18n } from 'Locales';
import { TextField } from 'Components';
import { FormattedLabel, idFilter } from 'Helpers';

import { componentPropTypes } from '../../shared';

const getType = component_type => {
  switch (component_type) {
    case 'birth_date':
    case 'date':
      return 'date';

    case 'email':
      return 'email';

    case 'phone':
      // Changed to text because number type allows special characters like -  or +.
      // Which can not be restricted effectively
      return 'text';

    case 'product':
    case 'select':
      return 'select';

    case 'area':
      return 'textarea';

    default:
      return 'text';
  }
};

class Text extends React.Component {
  constructor() {
    super();
    this.state = {
      cleave: null
    };
  }
  componentDidUpdate = () => {
    if (this.props.component_type === 'bank_registration_lookup' && !this.state.cleave) {
      this.setState({ cleave: this.createCleave() });
    }
  };

  createCleave = () => {
    const { component_key } = this.props;
    const cleaveIdentifier = `.cleave-iban-input-${component_key} input:first-of-type`;
    return new Cleave(cleaveIdentifier, {
      delimiter: ' ',
      delimiterLazyShow: true,
      blocks: [4, 4, 4, 4, 4, 4, 4, 4, 2], // up to 34 chars
      uppercase: true,
      onValueChanged: this.update
    });
  };

  render = () => {
    const { component_type, fields, name, required, shouldShowError, value } = this.props;
    const type = getType(component_type);
    const isIBAN = component_type === 'bank_registration_lookup';
    let componentValue = value;
    if (isIBAN) {
      componentValue = value[fields[0].name] || '';
    }

    if (component_type === 'birth_date' || component_type === 'date') {
      const [day, month, year] = value.split('/');
      const renderDateFormat = `${year}-${month}-${day}`;
      componentValue = renderDateFormat;
    }

    return (
      <TextField
        className="custom-date-input"
        label={FormattedLabel({ required, name })}
        type={type}
        placeholder={fields[0]?.placeholder}
        value={componentValue}
        onChange={this.update}
        errorMessageForce={shouldShowError}
        errorMessage={I18n.t('flow:generalError')}>
        {type === 'select' && <option key={'placeholderSelect'}>{fields[0]?.placeholder}</option>}

        {type === 'select' && this.renderSelectOptions()}
      </TextField>
    );
  };

  renderSelectOptions = () => {
    const { component_type, fields, referenced_products } = this.props;
    if (component_type === 'product') {
      return referenced_products.map(rp => (
        <option key={rp.id} value={rp.id}>
          {rp.name}
        </option>
      ));
    }

    return fields[0].choices.map(choice => (
      <option key={choice} value={choice}>
        {choice}
      </option>
    ));
  };

  update = e => {
    const { updateValue, id, component_type: component, fields, referenced_products } = this.props;
    const { value } = e.target;
    const isProduct = component === 'product';

    const data = {
      value,
      id,
      component
    };

    if (component === 'bank_registration_lookup') {
      const bankValue = {};
      bankValue[`${fields[0].name}`] = data.value;
      data.value = bankValue;
    }

    if (isProduct) {
      data.isProduct = true;
      data.productValue = referenced_products.filter(idFilter.bind(this, parseInt(data.value)))[0].id;
    }

    if (component === 'first_name' || component === 'last_name') {
      const isValid = !value.trim() || /^[a-zA-Z\p{L}\p{M}'\-\s]+$/u.test(value.trim());
      if (!isValid) return;
    }

    if (component === 'phone') {
      const isValid = !value.trim() || /^[0-9]+$/.test(value.trim());
      if (!isValid) return;
    }

    if (component === 'birth_date' || component === 'date') {
      const [year, month, day] = value.split('-');
      const newDateFormat = `${day}/${month}/${year}`;
      data.value = newDateFormat;
    }

    updateValue(data);
  };

  updateDate = e => {
    if (e.value[0]) {
      this.update({
        target: {
          value: new Date(e.value[0]).toLocaleDateString('en-GB')
        }
      });
    }
  };
}

Text.propTypes = {
  ...componentPropTypes
};

export default Text;
