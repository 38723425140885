import PropTypes from 'prop-types';
import { Page } from 'framework7-react';
import React, { useEffect } from 'react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { NavigationService } from 'Services';
import { AppHeader, Pagination } from 'Components';
import { DAILY_FIGURES_CAMPAIGNS, DAILY_FIGURES_TAB_VIEWS, PAGE_NAMES, TABLE_CONFIG } from 'Constants';

import Charity from './views/Charity';
import Commercial from './views/Commercial';

const handleGoBack = resetTransient => {
  resetTransient();
  NavigationService.goBack({ name: PAGE_NAMES.DAILY_FIGURES });
};

const handlePaginationNavigation = (defaultParams, filters, getAdminDailyFigures, sort) => {
  getAdminDailyFigures({ ...filters, ...sort, ...defaultParams });
};

const BAsView = ({
  adminDailyFigures = {},
  adminFilters = {},
  dailyFigures = {},
  getAdminDailyFigures = mockFunction,
  manualCheckIn = mockFunction,
  manualCheckInCommercial = mockFunction,
  resetTransient = mockFunction,
  transient = {},
  updateDailyFiguresProps = mockFunction,
  updateGrowlProps = mockFunction,
  updateSales = mockFunction,
  updateTransientProps = mockFunction,
  updateTransientPropWithValidations = mockFunction,
  updateUserProps = mockFunction,
  userRole = ''
}) => {
  const { meta = {} } = dailyFigures;
  const { filters = {}, getAdminDailyFiguresLoading = false, sort = {} } = transient;

  useEffect(
    () => () => {
      updateDailyFiguresProps({ currentTableView: DAILY_FIGURES_TAB_VIEWS.STATUS });
    },
    []
  );

  return (
    <Page id={'ba-view'}>
      <AppHeader
        goBack
        hideAvatar
        showMcPins={false}
        title={I18n.t('admin:campaignsViews.basView')}
        handleClick={handleGoBack.bind(null, resetTransient)}
      />

      <>
        {adminDailyFigures.campaignsName === DAILY_FIGURES_CAMPAIGNS.CHARITY_CAMPAIGNS ? (
          <Charity
            adminDailyFigures={adminDailyFigures}
            adminFilters={adminFilters}
            getAdminDailyFigures={getAdminDailyFigures}
            manualCheckIn={manualCheckIn}
            transient={transient}
            updateDailyFiguresProps={updateDailyFiguresProps}
            updateGrowlProps={updateGrowlProps}
            updateTransientProps={updateTransientProps}
            updateUserProps={updateUserProps}
            userRole={userRole}
          />
        ) : (
          <Commercial
            adminDailyFigures={adminDailyFigures}
            adminFilters={adminFilters}
            getAdminDailyFigures={getAdminDailyFigures}
            manualCheckInCommercial={manualCheckInCommercial}
            transient={transient}
            updateGrowlProps={updateGrowlProps}
            updateSales={updateSales}
            updateTransientProps={updateTransientProps}
            updateTransientPropWithValidations={updateTransientPropWithValidations}
            updateUserProps={updateUserProps}
            userRole={userRole}
          />
        )}

        <Pagination
          loading={getAdminDailyFiguresLoading}
          totalNumberOfPages={meta?.total_pages}
          onNavigate={handlePaginationNavigation.bind(null, filters, sort, getAdminDailyFigures)}
          numberOfElements={TABLE_CONFIG.ROWS}
          externalPageNumber={meta?.page}
        />
      </>
    </Page>
  );
};

BAsView.propTypes = {
  adminDailyFigures: PropTypes.object,
  adminFilters: PropTypes.object,
  dailyFigures: PropTypes.object,
  getAdminDailyFigures: PropTypes.func,
  manualCheckIn: PropTypes.func,
  manualCheckInCommercial: PropTypes.func,
  resetTransient: PropTypes.func,
  transient: PropTypes.object,
  updateDailyFiguresProps: PropTypes.func,
  updateGrowlProps: PropTypes.func,
  updateSales: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  updateUserProps: PropTypes.func,
  userRole: PropTypes.string
};

export default BAsView;
