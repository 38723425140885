import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Config from 'Config';
import { Edit } from 'Icons';
import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { ColumnView } from 'Containers';
import { QuickFilters, Table } from 'Components';
import { ALIGNMENT, FILTERS, FONT_SIZE, PAGE_NAMES, ROLES, TEXT_ALIGN } from 'Constants';

import { ManualCheckIn, UpdateSales } from '../../../Modals';

const Commercial = ({
  adminDailyFigures = {},
  adminFilters = {},
  getAdminDailyFigures = mockFunction,
  manualCheckInCommercial = mockFunction,
  transient = {},
  updateGrowlProps = mockFunction,
  updateSales = mockFunction,
  updateTransientProps = mockFunction,
  updateTransientPropWithValidations = mockFunction,
  updateUserProps = mockFunction,
  userRole = {}
}) => {
  const { dailyFigures = {} } = adminDailyFigures;
  const { users } = dailyFigures;

  const {
    getAdminDailyFiguresLoading = false,
    showManualCheckInModal = false,
    showUpdateSalesModal = false,
    filters = {},
    selectedRow = {},
    activeCampaigns = []
  } = transient;
  const { campaigns } = selectedRow;

  const { campaign_id = '' } = filters;

  const showGrowlError = today => {
    const body = today ? I18n.t('growl:error.todayCheckInError.body') : I18n.t('growl:error.manualCheckInError.body');

    updateGrowlProps({
      visible: true,
      title: I18n.t('growl:error.generalError.title'),
      body: body,
      kind: 'error'
    });
    setTimeout(() => updateGrowlProps({ visible: false }), Config.GROWL_AUTOHIDE);
  };

  const dailyManualCheckIn = row => {
    manualCheckInCommercial({
      campaign_id: filters.campaign_id,
      start_date: filters.dailyFiguresDate_start_date,
      end_date: filters.dailyFiguresDate_end_date,
      user_id: row.user_id,
      checked: !row.ba_in_field,
      current_mc_pin: filters?.pin || '',
      sales: row.sales
    });
  };

  const isOwnerOrMcAdmin = ROLES.MC_ADMINISTRATIVE.includes(userRole);
  const dateDifference = moment(filters.dailyFiguresDate_end_date).diff(
    moment(filters.dailyFiguresDate_start_date),
    'days'
  );
  const isStartDateToday =
    filters.dailyFiguresDate_start_date && moment(filters.dailyFiguresDate_start_date).isSame(moment(), 'day');
  const isEndDateToday =
    filters.dailyFiguresDate_end_date && moment(filters.dailyFiguresDate_end_date).isSame(moment(), 'day');
  const today = isStartDateToday || isEndDateToday;

  const columns = [
    {
      name: I18n.t('admin:baBadge'),
      field: 'badge_number',
      itemAlign: TEXT_ALIGN.START,
      headerAlign: ALIGNMENT.START,
      itemSize: FONT_SIZE.SMALL,
      width: '25%'
    },
    {
      name: I18n.t('admin:baName'),
      field: 'name',
      itemAlign: TEXT_ALIGN.START,
      headerAlign: ALIGNMENT.START,
      itemSize: FONT_SIZE.SMALL,
      width: '30%',
      sorting: true,
      sortingAction: getAdminDailyFigures
    },
    {
      name: I18n.t('admin:baInTheField'),
      field: 'ba_in_field',
      headerAlign: ALIGNMENT.CENTER,
      itemSize: FONT_SIZE.SMALL,
      itemAlign: TEXT_ALIGN.CENTER,
      itemType: 'checkbox',
      width: '20%',
      action: row => {
        if (isOwnerOrMcAdmin && dateDifference > 1) {
          showGrowlError();
        } else if (today) {
          showGrowlError(today);
        } else if (campaign_id !== '') {
          dailyManualCheckIn(row);
        } else {
          updateTransientProps({
            showManualCheckInModal: true,
            selectedRow: row
          });
        }
      }
    },
    {
      name: I18n.t('admin:results'),
      field: 'sales',
      headerAlign: ALIGNMENT.CENTER,
      itemSize: FONT_SIZE.SMALL,
      itemAlign: TEXT_ALIGN.CENTER,
      width: '25%',
      sorting: true,
      sortingAction: getAdminDailyFigures,
      itemType: 'edit',
      action: row => {
        updateTransientProps({
          campaignId: filters?.campaign_id,
          selectedRow: row,
          showUpdateSalesModal: true
        });
      },
      icon: Edit
    }
  ];

  return (
    <ColumnView>
      <QuickFilters
        filtersType={FILTERS.DAILY_FIGURES}
        fetchTableItems={getAdminDailyFigures}
        quickFilters={adminFilters}
        role={userRole}
        transient={transient}
        updateTransientProps={updateTransientProps}
        updateUserProps={updateUserProps}
        view={PAGE_NAMES.BAS_VIEW}
      />

      <Table
        columns={columns}
        filters={filters}
        multipleSelection={false}
        processing={getAdminDailyFiguresLoading}
        requiresDataTransformation={false}
        rows={users}
      />

      <ManualCheckIn
        campaigns={activeCampaigns}
        isOpen={showManualCheckInModal}
        manualCheckIn={manualCheckInCommercial}
        onClose={updateTransientProps.bind(null, { showManualCheckInModal: false })}
        transient={transient}
        updateGrowlProps={updateGrowlProps}
        updateTransientProps={updateTransientProps}
      />

      <UpdateSales
        isOpen={showUpdateSalesModal}
        onClose={updateTransientProps.bind(null, { showUpdateSalesModal: false })}
        updateTransientProps={updateTransientProps}
        updateTransientPropWithValidations={updateTransientPropWithValidations}
        transient={transient}
        updateGrowlProps={updateGrowlProps}
        updateSales={updateSales}
        campaigns={campaigns}
      />
    </ColumnView>
  );
};

Commercial.propTypes = {
  adminDailyFigures: PropTypes.object,
  adminFilters: PropTypes.object,
  getAdminDailyFigures: PropTypes.func,
  manualCheckInCommercial: PropTypes.func,
  transient: PropTypes.object,
  updateGrowlProps: PropTypes.func,
  updateSales: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  updateUserProps: PropTypes.func,
  userRole: PropTypes.string
};

export default Commercial;
