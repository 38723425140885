import React from 'react';
import PropTypes from 'prop-types';
import { BlockTitle, Block } from 'framework7-react';

import { I18n } from 'Locales';
import { Card } from 'Components';
import { RowView } from 'Containers';
import { TABS, EMPTY_TABS } from 'Constants';
import { isObjEmpty, mockFunction } from 'Helpers';

import ProfileTabSegment from '../../../Profile/ProfileTabSegment';

const findTabForEmptyFields = (blankFields = [], rejectedFields = [], emptyTabs = {}, profilePages = []) => {
  const missingProfileKeys = [...rejectedFields, ...blankFields];
  for (const tab of profilePages) {
    //check if on blank_fileds or rejected_fields we find any key from EMPTY_TABS in order of profilePages(tabs)
    const keyFound = Object.keys(emptyTabs).find(key => missingProfileKeys.includes(key) && emptyTabs[key] === tab);

    //return the tab where a missing key is found
    if (keyFound) {
      return tab;
    }
  }

  return TABS.DOCUMENTS;
};

export const Onboarding = ({
  campaign = {},
  currentPage = '',
  handleNavigate = mockFunction,
  profilePages = [],
  selectedCampaign = {},
  updateApplicationProp = mockFunction,
  updateCampaignProps = mockFunction,
  updateProp = mockFunction,
  user = {}
}) => {
  const { profile_complete = false, incomplete_documents = [], needed_for_check_in = [] } = user;
  const tab = findTabForEmptyFields(user?.blank_fields, user?.rejected_fields, EMPTY_TABS, profilePages);
  const callback = tab === TABS.DOCUMENTS ? true : false;

  return (
    <>
      <BlockTitle medium className="font-bold font-size-20">
        <RowView justifyContent={'flex-start'}>
          {I18n.t('campaign:welcome')}
          <div className="pl-4">{user.first_name}</div>
        </RowView>
      </BlockTitle>

      <div className="px-20">
        <Card
          title={I18n.t('campaign:dashboardCard.profile')}
          description={
            !profile_complete || needed_for_check_in.length !== 0
              ? I18n.t('campaign:dashboardCard.description')
              : incomplete_documents.length === 0
              ? I18n.t('campaign:underReview.profileUnderReview')
              : `${I18n.t('campaign:underReview.profileUnderReview')} ${I18n.t('campaign:underReview.hasOptionalDocs')}`
          }
          onClick={handleNavigate.bind(null, {
            callback,
            campaign,
            selectedCampaign,
            updateApplicationProp,
            updateCampaignProps,
            user,
            value: tab
          })}
          {...(incomplete_documents.length !== 0 && { buttonLabel: I18n.t('campaign:dashboardCard.takeMeThere') })}
        />

        {!isObjEmpty(user) && (
          <>
            {profilePages?.map((page, index) => (
              <Block className="profile-tab-card mt-20 pb-10" key={index}>
                <RowView
                  onClick={handleNavigate.bind(null, {
                    callback,
                    campaign,
                    selectedCampaign,
                    updateApplicationProp,
                    updateCampaignProps,
                    user,
                    value: page
                  })}>
                  <RowView width={'20%'}>
                    <div className="pt-6">
                      <ProfileTabSegment
                        currentPage={currentPage}
                        updateProp={updateProp}
                        user={user}
                        viewTypeColumn={false}
                        page={page}
                        key={index}
                      />
                    </div>
                  </RowView>
                  <RowView>
                    <div className="pr-50 profile-tab-card-description">{I18n.t(`profile:tabs.${page}`)}</div>
                  </RowView>
                </RowView>
              </Block>
            ))}
          </>
        )}
      </div>
    </>
  );
};

Onboarding.propTypes = {
  campaign: PropTypes.object,
  currentPage: PropTypes.string,
  handleNavigate: PropTypes.func,
  handleVersionUpdateConsent: PropTypes.func,
  handleVersionUpdateModalClose: PropTypes.func,
  profilePages: PropTypes.array,
  selectedCampaign: PropTypes.object,
  updateApplicationProp: PropTypes.func,
  updateCampaignProps: PropTypes.func,
  updateProp: PropTypes.func,
  user: PropTypes.object
};

export default Onboarding;
