import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { Link } from 'Components';
import { RowView } from 'Containers';
import { mockFunction } from 'Helpers';
import { ArrowNarrowLeft } from 'Icons';
import { TABLE_CONFIG } from 'Constants';

import './style.scss';

const handleNavigation = ({
  currentPageNumber,
  direction,
  numberOfElements,
  onNavigate,
  setCurrentPageNumber,
  totalNumberOfPages,
  updateTransientProps
}) => {
  if (
    totalNumberOfPages === 0 ||
    (direction === 'back' && currentPageNumber === 1) ||
    (direction === 'next' && currentPageNumber === totalNumberOfPages)
  ) {
    return;
  }
  const newPageNumber = direction === 'next' ? currentPageNumber + 1 : currentPageNumber - 1;

  setCurrentPageNumber(newPageNumber);
  onNavigate({ page: newPageNumber, per_page: numberOfElements });
  updateTransientProps({ selectedRows: [] });
};

const Pagination = ({
  externalPageNumber = null,
  loading = false,
  numberOfElements = TABLE_CONFIG.ROWS,
  onNavigate = mockFunction,
  totalNumberOfPages = '',
  updateTransientProps = mockFunction
}) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  useEffect(() => {
    if (totalNumberOfPages && totalNumberOfPages < currentPageNumber) {
      setCurrentPageNumber(totalNumberOfPages);
    } else if (currentPageNumber === 0 && totalNumberOfPages > 0) {
      setCurrentPageNumber(1);
    }
  }, [totalNumberOfPages]);

  useEffect(() => {
    if (externalPageNumber && externalPageNumber !== currentPageNumber) {
      setCurrentPageNumber(externalPageNumber);
    }
  }, [externalPageNumber]);

  return (
    !loading &&
    totalNumberOfPages !== 0 && (
      <div className="w-full">
        <RowView paddingVertical={10} paddingHorizontal={16} backgroundColor={colors.white} width={'100%'}>
          <RowView width={'auto'}>
            <Link
              onClick={handleNavigation.bind(null, {
                currentPageNumber,
                direction: 'back',
                numberOfElements,
                onNavigate,
                setCurrentPageNumber,
                totalNumberOfPages,
                updateTransientProps
              })}>
              <ArrowNarrowLeft />
            </Link>
          </RowView>

          <RowView>
            <span className="pagination">{I18n.t('general:pageOf', { currentPageNumber, totalNumberOfPages })}</span>
          </RowView>

          <RowView width={'auto'}>
            <Link
              onClick={handleNavigation.bind(null, {
                currentPageNumber,
                direction: 'next',
                numberOfElements,
                onNavigate,
                setCurrentPageNumber,
                totalNumberOfPages,
                updateTransientProps
              })}>
              <ArrowNarrowLeft className={'rotate180'} />
            </Link>
          </RowView>
        </RowView>
      </div>
    )
  );
};

Pagination.propTypes = {
  externalPageNumber: PropTypes.number,
  loading: PropTypes.bool,
  meta: PropTypes.object,
  numberOfElements: PropTypes.number,
  onNavigate: PropTypes.func,
  totalNumberOfPages: PropTypes.number,
  updateTransientProps: PropTypes.func
};

export default Pagination;
