import { regularPagesOnly, thankYouPageOnly } from 'Helpers';

const checkInFilter = (state, ci) => ci.published_flow.id === flowId(state);

const checkInObject = state => {
  try {
    return state.checkIn[state.user.id]?.checkIns.filter(checkInFilter.bind(null, state))[0];
  } catch (e) {
    return null;
  }
};

const checkInFlows = state => {
  try {
    return state.checkIn[state.user.id]?.checkIns;
  } catch (e) {
    return null;
  }
};

const flow = state => state.flow.flows[state.flow.currentFlowIndex];

const flowId = state => {
  try {
    return state.campaign[state.user.id]?.selectedCampaign?.published_flows?.[0].id || undefined;
  } catch (e) {
    return undefined;
  }
};

const flowReady = state => (state.flow.flows[flowId(state)] ? true : false);

const flowPages = state => flow(state)?.flow_pages.filter(regularPagesOnly) || [];

const getCurrentRouteName = state => state.application.routing.history[state.application.routing.history.length - 1];

const hasValidBadge = state => validBadgeNumber(state);

const isCheckedIn = state => !!checkInObject(state);

const isSubmittedOffline = state => lastResultId(state)?.startsWith(state.user.id);

const lastResultId = state => state.application?.lastTransaction?.id;

const offlineFlows = state => state.result[state.user.id]?.offline || [];

const recentResults = state => state.result[state.user.id]?.results || [];

const selectedCampaignFilter = (state, item) => item.id == state.user.campaign?.id;

const selectedCampaignName = state => {
  const uc = userCampaigns(state);
  return uc.length > 0 ? uc.filter(selectedCampaignFilter.bind(this, state))[0]?.name : '';
};

const flowCountry = state => {
  const userId = state?.user?.id;
  const selectedCampaign = state?.campaign?.[userId]?.selectedCampaign;
  const publishedFlowId = selectedCampaign?.published_flows?.[0]?.id;
  const flow = state?.flow?.flows?.[publishedFlowId];

  return flow?.country;
};
const selectedCampaignPublishedFlowId = state =>
  state?.campaign[state?.user?.id]?.selectedCampaign?.published_flows?.find(item => item)?.id;

const thankYouPageFields = state => flow(state)?.flow_pages?.filter(thankYouPageOnly)[0]?.components[0]?.fields || [];

const toggledCampaigns = state => state.campaign?.[state.user.id]?.toggleStatus || {};

const userCampaigns = state => {
  try {
    return state.campaign[state.user.id]?.campaigns || [];
  } catch (e) {
    return [];
  }
};

const selectedOfflineCampaign = state => state.campaign[state.user.id]?.selectedCampaign || {};

const userActiveBadge = item => item.campaign.id;

const validBadgeNumber = state => {
  const filteredBadges = state.user?.active_badges
    ?.map(userActiveBadge)
    .includes(state.campaign[state.user.id]?.selectedCampaign?.id);
  return filteredBadges;
};

const offlineResults = state => state?.result[state.user.id]?.offline;

const selectedCampaign = state => state?.campaign[state.user?.id]?.selectedCampaign;

const filteredCampaignId = state => state?.campaign[state.user?.id]?.selectedCampaignFilterId;

const filteredCampaignName = state => state.user?.campaigns?.find(item => item?.id === filteredCampaignId(state))?.name;

const onboardingCampaigns = state => state?.user?.onboarding_profiles?.flatMap(profile => profile?.campaigns);

export {
  checkInFlows,
  checkInObject,
  filteredCampaignId,
  filteredCampaignName,
  flowCountry,
  flowId,
  flowPages,
  flowReady,
  getCurrentRouteName,
  hasValidBadge,
  isCheckedIn,
  isSubmittedOffline,
  offlineFlows,
  offlineResults,
  onboardingCampaigns,
  recentResults,
  selectedCampaign,
  selectedCampaignName,
  selectedCampaignPublishedFlowId,
  selectedOfflineCampaign,
  thankYouPageFields,
  toggledCampaigns,
  userCampaigns,
  validBadgeNumber
};
