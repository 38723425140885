import React from 'react';
import PropTypes from 'prop-types';

import { checkSearchParams } from 'Helpers';

const Conditions = ({ language, user }) => {
  let user_locale = checkSearchParams(window.location.search).locale;

  if (user.locale !== language) {
    user_locale = language;
  }

  return (
    <div className="padding-agreements">
      {(user_locale === 'en' || user.locale === 'en') && (
        <div>
          <h3>Agent #1 Terms of Service (TFO – BA)</h3>
          <h3>1.Recital</h3>
          <p>
            DataByte is an online platform as described within the document, the Platform operates with three (3) user
            groups; (i) TFO Group as the System Administrator, (ii) a Marketing Company Administrator (as defined as
            “Administrator”), who has purchased a licence from TFO Group to use the Platform, who are fully responsible
            for the setting up and interactions with the Users. Group (iii) are the Users as defined in Definitions and
            they have a relationship with the Marketing Companies.
          </p>
          <p>
            Le Groupe TFO fournit un service aux utilisateurs au nom des sociétés de marketing qui ont acheté une
            licence du Groupe TFO.
          </p>
          <h2>2.Acceptable Use of Our Services</h2>
          <h3>Our Terms and Policies.</h3>
          <p>
            You must use our Services according to our Terms and Policies. If you violate our Terms or Policies, we may
            take action with respect to your account, including disabling or suspending your account and, if we do, you
            must not create another account without our permission.
          </p>
          <h3>Legal and Acceptable Use.</h3>
          <p>
            You must access and use our Services only for legal, authorized, and acceptable purposes. You will not use
            (or assist others in using) our Services in ways that:{' '}
          </p>
          <ol type="a">
            <li>
              violate, misappropriate, or infringe the rights of TFO Group, our Users, or others, including privacy,
              publicity, intellectual property, or other proprietary rights;{' '}
            </li>
            <li>
              are illegal, obscene, defamatory, threatening, intimidating, harassing, hateful, racially or ethnically
              offensive, or instigate or encourage conduct that would be illegal or otherwise inappropriate, including
              promoting violent crimes;{' '}
            </li>
            <li>involve publishing falsehoods, misrepresentations, or misleading statements; </li>
            <li>impersonate someone; </li>
            <li>involve any non-personal use of our Services unless otherwise authorized by us.</li>
          </ol>
          <h2>3.Parties</h2>
          <p>
            <b>The Firm Organisation Ltd t/a TFO Group</b> here in referenced to as “DataByte” (Company Reg. No. 637481)
            Registered Office: 32 Merrion Street, Dublin 2, Ireland and you the <b>"User"</b>.
          </p>
          <h2>4.Definitions</h2>
          <p>
            In these terms of service, the following concepts shall have the meaning described in this article (when
            written with a capital letter).
          </p>
          <p>
            <b>"Access Rights"</b> shall mean all contractual rights to access, receive and use the Services according
            to the technical protocols and procedures established by TFO Group pursuant to the Agreement;
          </p>
          <p>
            <b>"Administrator"</b> shall mean the Marketing Company user that has purchased a licence from TFO Group and
            are responsible for the setting up and interactions with the Users. They shall be able to (i) use the Tool,
            App and Services, (ii) change the configuration settings (including but not limited to adding additional
            Services or features) and (iii) creating additional accounts for Users. For the purposes of this Agreement,
            the User is contracted to the Marketing Company and the Marketing Company shall be Data Controller for the
            User Personal Data as defined by the General Data Protection Regulation 2016/679.
          </p>
          <p>
            <b>"Marketing Company"</b> shall mean the Company that is contracted to a TFO Group Company and uses the
            DataByte Platform as part of their delivery of services. The Marketing Company shall be the Administrator as
            defined.{' '}
          </p>
          <p>
            <b>"App"</b> shall mean the mobile Progressive Web Application (PWA) of the Tool through which the Services
            can be used;
          </p>
          <p>
            <b>"Communication"</b> shall mean any communication sent by the Tool via email at regular times concerning
            Services-related announcements, administrative e-mails and up-dates;
          </p>
          <p>
            <b>"User"</b> shall mean every natural person, as well as anyone who uses the Services from the Tool. Every
            natural person is considered to be at least 18 years of age, For the purposes of this agreement, the user
            can be referred to as “you”;
          </p>
          <p>
            <b>"User Data"</b> shall mean any and all content, information and data – including personal data –
            pertaining to prospects, business partners, clients and/or customers of the User (non-limitative) entered
            and uploaded into the Tool and/or App by the User by using the Services;
          </p>
          <p>
            <b>"Documentation"</b> shall mean any documentation provided by TFO Group regarding the Tool, App and
            Services as well as the Website and including any documentation, tutorials or other available on the
            Website;
          </p>
          <p>
            <b>"Privacy Policy"</b> shall mean the privacy declaration of TFO Group as available on the tfo.group
            website.
          </p>
          <p>
            <b>"Services"</b> shall mean the online services which facilitate online management and cooperation, and
            consists among other things of a CRM system, Electronic Point of Sale (EPOS), Onboardings / Vetting and
            Academy / upskilling.
          </p>
          <p>
            <b>"Indemnitee"</b> shall mean every TFO Group officer, director, partner, employee and affiliate;
          </p>
          <p>
            <b>"Term"</b> shall mean the period of time for which you are using the Tool.
          </p>
          <p>
            <b>"Tool" & "Platform"</b> shall mean the online application, developed by DataByte, with the brand name
            “DataByte”.
          </p>
          <p>
            <b>"User Account"</b> shall mean the user account, which can be accessed by a User through his/her personal
            login in order to make use of the he Tool, App and Services;
          </p>
          <p>
            <b>"Website"</b> shall mean website that DataByte uses for the deployment of the Tool and every DataByte
            website of the country in which DataByte operates.
          </p>
          <h2>5.Construction</h2>
          <ol>
            <li>
              Words importing the singular shall include the plural and vice versa, and words importing the masculine
              gender shall include the feminine or neuter gender and vice versa.
            </li>
            <li>
              Words importing persons shall include individuals, corporations (wherever incorporated), unincorporated
              associations (including partnerships), trusts, any form of governmental body, agency or authority, and any
              other organisation of any nature (in each case, whether or not having separate legal personality), and
              expressions referring to writing shall be considered as reference to typewriting, printing lithography,
              photography and other modes of representing or reproducing words in a visible form.
            </li>
            <li>
              References to any statutory provisions shall be deemed to include all statutory modifications,
              re-enactments, replacements and extensions of such provision on or before the date hereof or thereafter
              re-enacted without substantive amendment and shall further include all statutory instruments or orders
              from time to time made pursuant thereto.
            </li>
            <li>
              The headings and captions in this Agreement are inserted for convenience of reference and shall not be
              considered a part of or affect the construction or interpretation of this Agreement.
            </li>
          </ol>
          <h2>6.Scope</h2>
          <p>
            TFO Group developed and offers a Tool for Customer Relationship Management (‘CRM’), Electronic Point of Sale
            (EPOS), Onboardings / Vetting and Academy / upskilling. This Tool, which is available in the form of a
            Progressive Web Application (PWA) and online application, offers an integration of various services that
            ensure a more efficient business administration of its Users.
          </p>
          <p>
            Every commercial relationship between TFO Group and the User shall be governed by the terms of service as
            laid down in this document.
          </p>
          <p>
            By setting up an account on the Tool, the Users acknowledges to have read and accepted these terms of
            service. The terms of service always take precedence over the terms and conditions of the User, even if they
            stipulate that they are the only valid conditions.
          </p>
          <p>
            TFO Group reserves the right to change its terms of service whenever it wishes to do so by a change on its
            Website. TFO Group undertakes to notify the User thereof through 
          </p>
          <ol type="i">
            <li>
              an announcement on its Website within a reasonable term prior to the application of the amended terms of
              service and/or
            </li>
            <li>through the DataByte Communication.</li>
          </ol>
          <p>
            These terms of service must be read together with Privacy Policy and may at all times be consulted on the
            Website.
          </p>
          <h2>7.Offer</h2>
          <p>
            Catalogues, brochures, newsletters, folders, TFO Group Communications and other publicity announcements, as
            well as announcements on the Website are entirely non-binding, and may only be regarded by the User or
            Marketing Company as an “invitation to treat”, unless explicitly specified otherwise.
          </p>
          <p>Every offer shall only be valid for</p>
          <ol type="i">
            <li>a specific order and shall thus not automatically apply to subsequent (similar) orders as well as</li>
            <li>the duration as stipulated therein.</li>
          </ol>
          <h2>8.Administrator Account/ User Account</h2>
          <p>
            The User shall be able to access the Tool and App as well as make use of the Services through the
            Administrator Account. The Administrator shall be solely responsible for every use and activity of the
            Administrator Account.
          </p>
          <p>
            The Administrator determines the extent of the rights of the User Accounts and the Administrator shall
            always remain responsible for every use of the User Accounts.
          </p>
          <p>
            Each User Account (including the Administrator Account) belongs to one person only and may therefore not be
            shared with other persons. The Administrator as well as every User is advised to create a unique password,
            to change it frequently and/or to use two-factor authentication.
          </p>
          <p>
            The User shall be solely responsible for the protection and security of the Administration Account and the
            User Accounts. In this context, the Administrator and every User must ensure the confidentiality of their
            respective account, including the confidentiality of the login-data such as the password.
          </p>
          <h2> 9.Use of the Services</h2>
          <p>
            For as long as this agreement remains in effect, the User can make use of the Tool, App and the Services
            within the scope of the Access Rights of the User, of which the scope is determined when ordering the
            Services. The scope of such Access Rights can be limited or extended during the Term of the agreement.
          </p>
          <p>The User agrees that compliance with this article is an essential basis of the agreement.</p>
          <p>
            Use of and access to the Services by the User presupposes an Internet connection and the use of a modern web
            browser (such as but not limited to the most recent version of Google Chrome). If an obsolete web browser is
            used, the User may not be able to use all functions of the Services or these functions may not operate
            optimally.
          </p>
          <p>
            The User shall be fully responsible for compliance with these terms of service, as well as for the acts and
            omissions of all Users who make use of the Tool, App and Services through the Administrator Account and/or
            User Account. The User shall not authorize access to or permit use of the Tool, App and Services nor the
            Documentation by persons other than the Administrator or the Users.
          </p>
          <h2>10.Restrictions</h2>
          User agrees not to misuse the Access Rights and shall thus not, without this list being exhaustive;
          <ul>
            <li>
              Sell, resell, license, sublicense, rent, lease or distribute the Tool, the App, and any Services, or
              include any Services or any derivative works thereof in a service bureau or outsourcing offering to any
              third party;
            </li>
            <li>
              Copy, modify, adapt, alter, translate or make derivative works based upon the Services (other than any
              copies, modifications or derivative works made exclusively from the reports or overviews which are created
              solely for Users internal business purposes);
            </li>
            <li>
              Engage in, nor authorize others to engage in, the reverse engineering, disassembly or the decompilation of
              the Tool, App and/or Services.
            </li>
            <li>
              Use the Tool, App, and Services for illegal or unlawful purposes or for the transmission of data which is
              illegal, defamatory, invasive of another's privacy, abusive, threatening, harmful or infringes on
              someone's intellectual property (non- exhaustive list).
            </li>
            <li>Use the Tool, App, and Services to conduct or promote any illegal activities;</li>
            <li>
              Use the Tool, App, and Services for the transfer of “junk mail”, “spam”, “chain mail”, “phishing” or other
              undesired mass circulation of e-mails;
            </li>
            <li>Use the Services to stalk, harass or harm another individual;</li>
            <li>
              Disturb the good operation of the Tool, App and/or Website. This includes that User should refrain from
              the use of viruses, worms, Trojans or other software that may infringe the services and interests of both
              TFO Group and its User. User should also refrain from any content that may burden or disturb the websites
              infrastructure and its proper functioning;
            </li>
            <li>Send unsolicited and/or commercial messages, such as junk mail, spamming and chain letters;</li>
            <li>
              Add content that can be described as not-appropriate regarding the aim of the Tool and/or App. TFO Group
              reserves a large discretion and may notify a User if they believe that their contract is not appropriate
            </li>
            <li>Use the Tool, App, and Services to act in a way to damage TFO Group;</li>
            <li>
              Use the Services – partly or integrally – nor the Tool, App or Website in any manner that may give a false
              or misleading impression, attribution, or statement as to TFO Group, or any third party.
            </li>
          </ul>
          <h2>11.Intellectual property</h2>
          <h3>Intellectual property rights of TFO Group</h3>
          <p>
            The User explicitly acknowledges that TFO Group is and remains the sole owner of the Tool, App and Services
            and/or other intellectual property rights relating thereto. All such rights and goodwill are, and shall
            remain, vested with TFO Group.
          </p>
          <p>
            Consequently, the Access Rights granted to the User solely imply the right to use – at a charge – the Tool,
            App and Services and no implied licenses shall be granted under this agreement.
          </p>
          <p>
            Under no circumstances such right entails a transfer of ownership of the Tool, App, Services and
            Documentation by TFO Group to the User Grants the User any rights to or interests in the Tool, App,
            Services, any trade names and/or or trademarks of TFO Group, or Grants the User the right to request TFO
            Group to deliver a copy of any software or other products utilized by TFO Group to provide the Services.
          </p>
          <p>
            The User shall thus not use any trademark, tradename, or brand name of TFO Group (such as but not limited to
            the use thereof in metatags, keywords or hidden text), without the explicit written approval from TFO Group.
          </p>
          <p>
            Without prejudice to the right of the User or any third party to challenge the validity of any intellectual
            property of TFO Group, the User shall not perform or authorize any third party to perform any act which
            would or might invalidate or be inconsistent with any intellectual property of TFO Group – including without
            being limited to any patent, copyright, registered design, trade mark or other industrial or intellectual
            property rights – and shall not omit or authorize any third party to omit to do any act which, by its
            omission, would have that effect.
          </p>
          <p>
            The User undertakes to notify TFO Group of any actual, threatened or suspected infringement of any
            intellectual property rights of TFO Group which comes to the User’s notice, and of any claim by any third
            party due to use of the Tool, App and Services.
          </p>
          <h2>12.Termination</h2>
          <p>
            TFO Group in its sole discretion can terminate the agreement with a User at any time. In the event of such
            termination by TFO Group, termination will happen without a period of notice or compensation.
          </p>
          <h2>13.Liability</h2>
          <p>
            The intended use of the Tool, App and Services by the User, Administrator and/or Users is determined under
            their full responsibility and at their own risk. TFO Group cannot be held liable in any way for any direct
            or indirect damage resulting from the use of the Tool and App. Therefore, the User, Administrator and/or
            User shall thus be solely responsible for any damage to its computer (programs), wireless devices and/or
            other equipment consequential to the Tool, App and Services.
          </p>
          <h2>14.TFO Group shall neither be liable for:</h2>
          <ul>
            <li>
              Indirect and/or consequential damage (including but not limited to loss of income, loss of goodwill and
              damage to the property of the User caused by the Tool, App and Services). This limitation of liability
              also applies when TFO Group has been specifically informed of the potential loss by the User;
            </li>
            <li>
              Defects that have been caused directly or indirectly by an act on the part of the User or a third party,
              irrespective of whether they are caused by an error or negligence;
            </li>
            <li>
              Damage caused by using the Tool, App and Services for a different purpose than the purpose for which it
              has been developed or is intended by TFO Group;
            </li>
            <li>
              Additional damage caused by continued use by the User, Administrator and/or Users after a defect has been
              detected;
            </li>
            <li>
              Damage caused by non-compliance with any advice and/or guidelines that may be given by TFO Group, which
              the latter always provides on a discretionary basis;
            </li>
          </ul>
          <p>
            Furthermore, the User accepts that TFO Group does not offer any guarantee that the Tool, App and Services
            comply with the regulations or requirements which apply in any legal area, with the exception of the
            regulations or requirements that apply in Ireland on the moment of conclusion of the agreement. TFO Group
            can thus not be held liable for any subsequent changes of whatever nature in such law and/or regulations.
          </p>
          <p>
            The User shall indemnify and/or hold harmless TFO Group and/or a TFO Group Indemnitee from and against all
            claims of whatever nature that might arise from the existence, implementation, non-compliance and/or
            termination of these terms of service and which have been caused by his own negligence, fault or
            carelessness or by an Administrator and/or any of his Users.
          </p>
          <h2>15.Applicable law and jurisdiction</h2>
          <p>
            All issues, questions and disputes concerning the validity, interpretation, enforcement, performance or
            termination of this agreement shall be governed by and construed in accordance with the Irish law.
          </p>
          <h2>16.Data Protection</h2>
          <p>TFO Group shall be a Data Processor and the Administrator shall be the Data Controller.</p>
          <ol>
            <li>
              The parties acknowledge and agree that in order for TFO Group to provide the Services:
              <ol type="a">
                <li>
                  The User shall supply data to TFO Group which is within the meaning of ‘personal data’ as defined in
                  the General Data Protection Regulation 2016/679; and
                </li>
                <li>TFO Group shall need to process the User Personal Data on behalf of User’s; and</li>
                <li>
                  TFO Group shall be a data processor (as defined by the General Data Protection Regulation 2016/679)
                  and
                </li>
                <li>
                  The Administrator shall be the data controller (as defined in the General Data Protection Regulation
                  2016/679).
                </li>
              </ol>
            </li>
            <li>
              TFO Group shall process and use the User Personal Data only for the provision of services and in
              accordance with the provisions of this Agreement and together with any reasonable instructions from User.
            </li>
            <li>
              TFO Group declares it shall, only to the extent necessary and only with the necessary means required to
              perform the Services, process the User Personal Data.
            </li>
            <li>
              TFO Group shall not, except with the prior written permission of User or Administrator Data Controller:
              <ol type="a">
                <li>transfer or process any of the User Personal Data outside of the European Economic Area;</li>
                <li>sub-contract any of its obligations regarding or in relation to the User Personal Data.</li>
              </ol>
            </li>
            <li>
              TFO Group shall employ such technical and organisation resources and measures as are necessary to comply
              with its obligations under the General Data Protection Regulation 2016/679 and to prevent any unlawful or
              unauthorised use or processing of the User Personal Data or against accidental loss or destruction of, or
              damage to, personal data. Without prejudice to the generality of the forgoing sentence, TFO Group shall
              also comply with any reasonable instructions that User shall provide from time-to-time.
            </li>
          </ol>
          <h2>17.Agreement in its Entirety</h2>
          <ol>
            <li>
              This agreement constitutes the entire agreement between the parties and supersedes and extinguishes all
              previous agreements, promises, assurances, warranties, representations and understandings between them,
              whether written or oral, relating to its subject matter.{' '}
            </li>
            <li>
              Each Party agrees that it shall have no remedies in respect of any statement, representation, assurance or
              warranty (whether made innocently or negligently) that is not set out in this Agreement.
            </li>
            <li>
              Each Party agrees that it shall have no claim for innocent or negligent misrepresentation or negligent
              misstatement based on any statement in this Agreement.
            </li>
          </ol>
          <h2>18.Force Majeure</h2>
          <ol>
            <li>
              Force Majeure Event means any circumstance not within TFO Group’s reasonable control including, without
              limitation:
              <ol type="a">
                <li>acts of God, flood, drought, earthquake or other natural disaster;</li>
                <li>epidemic or pandemic;</li>
                <li>
                  terrorist attack, civil war, civil commotion or riots, war, threat of or preparation for war, armed
                  conflict, imposition of sanctions, embargo, or breaking off of diplomatic relations;
                </li>
                <li>nuclear, chemical or biological contamination or sonic boom;</li>
                <li>
                  any law or any action taken by a government or public authority, including without limitation imposing
                  an export or import restriction, quota or prohibition, or failing to grant a necessary licence or
                  consent;
                </li>
                <li>collapse of buildings, fire, explosion or accident; and</li>
                <li>any labour or trade dispute, strikes, industrial action or lockouts</li>
                <li>interruption or failure of TFO Group service.</li>
              </ol>
            </li>
            <li>
              The corresponding obligations of DataByte will be suspended, and it’s time for performance of such
              obligations extended.
            </li>
            <li>
              3. TFO Group shall:
              <ol type="a">
                <li>
                  as soon as reasonably practicable after the start of the Force Majeure Event but no later than 72
                  hours from its start, notify the other User of the Force Majeure Event, the date on which it started,
                  it’s likely or potential duration, and the effect of the Force Majeure Event on its ability to perform
                  any of its obligations under the agreement; and
                </li>
                <li>
                  use all best endeavours to mitigate the effect of the Force Majeure Event on the performance of its
                  obligations.
                </li>
              </ol>
            </li>
          </ol>
          <h2>19.Severability </h2>
          <p>
            If any provision or part-provision of this Agreement is or becomes invalid, illegal or unenforceable, it
            shall be deemed deleted, but that shall not affect the validity and enforceability of the rest of this
            Agreement.
          </p>
          <h2>20.Language</h2>
          <p>
            Unless expressly agreed otherwise, the User acknowledges that the language of these conditions will also be
            the working language in all commercial transactions with TFO Group.
          </p>
          <p>
            The original language of these conditions is English. Translations or documents drawn up in a different
            language will at all times be regarded as a bonus for the User. In the event of any conflict, the English
            version will always prevail.
          </p>
          <h2>21.Location</h2>
          <p>
            Please note that enabling Location Services is required to utilize this app, as it helps prevent fraud
            during the signup process. This feature is used exclusively for verifying data accuracy. Rest assured, your
            GPS data and location are not tracked, stored, or monitored beyond the scope of this verification.
          </p>
        </div>
      )}
      {(user_locale === 'fr' || user.locale === 'fr') && (
        <div>
          <h3>Conditions de service de l'agent no 1 (TFO - BA)</h3>
          <h3>1.Clause d'introduction</h3>
          <p>
            DataByte est une plate-forme en ligne telle que décrite dans le document, la plate-forme fonctionne avec
            trois (3) groupes d'utilisateurs ; (i) le groupe TFO en tantqu'administrateur du système, (ii) un
            administrateur de la société de marketing (telque défini comme "Administrateur"), qui a acheté une licence
            du groupe TFO pourutiliser la plate-forme, qui sont entièrement responsables de la mise en place et
            desinteractions avec les utilisateurs. Groupe (iii) sont les Utilisateurs tels que définis dansles
            Définitions et ils ont une relation avec les Sociétés de Marketing.
          </p>
          <p>
            Le Groupe TFO fournit un service aux utilisateurs au nom des sociétés de marketing qui ont acheté une
            licence du Groupe TFO.
          </p>

          <h2>2.Utilisation acceptable de nos services</h2>
          <h3>Nos conditions et politiques.</h3>
          <p>
            Vous devez utiliser nos services conformément à nos conditions et politiques. Si vousenfreignez nos
            conditions ou politiques, nous pouvons prendre des mesures à l'égardde votre compte, y compris le désactiver
            ou le suspendre et, si nous le faisons, vousne devez pas créer un autre compte sans notre autorisation.
          </p>
          <h3>Utilisation légale et acceptable. </h3>
          <p>
            Vous devez accéder et utiliser nos services uniquement à des fins légales, autoriséeset acceptables. Vous
            n'utiliserez pas (ou n'aiderez pas d'autres personnes à utiliser) nos services de manière qui:{' '}
          </p>

          <ol type="a">
            <li>
              enfreint, détourne ou viole les droits du Groupe TFO, de nos utilisateurs oud'autres personnes, y compris
              la confidentialité la publicité, la propriétéintellectuelle ou d'autres droits de propriété;
            </li>
            <li>
              est illégale, obscène, diffamatoire, menaçante, intimidante, harcelante,haineuse, offensante sur le plan
              racial ou ethnique, ou qui incite ou encourageune conduite qui serait illégale ou autrement inappropriée,
              y compris lapromotion de crimes violents{' '}
            </li>
            <li>implique la publication de mensonges, de fausses déclarations ou dedéclarations trompeuses;</li>
            <li>permet de se faire passer pour quelqu'un;</li>
            <li>
              implique toute utilisation non personnelle de nos services, sauf autorisationcontraire de notre part.
            </li>
          </ol>

          <h2>3.Parties</h2>
          <p>
            <b>The Firm Organisation Ltd t/b TFO Group</b> ici désignée sous le nom de "DataByte" (Numéro
            d'enregistrement De la société 637481) Siège social: 32 Merrion Street, Dublin 2, Irlande et vous "l'
            <b>Utilisateur</b>".
          </p>

          <h2>4.Définitions</h2>
          <p>
            Dans les présentes conditions de service, les concepts suivants ont la signification décrite dans le présent
            article (lorsqu'ils sont écrits avec une majuscule).
          </p>

          <p>
            <b>"Droits d'accès"</b> désigne tous les droits contractuels d'accès, de réception et d'utilisation des
            Services conformément aux protocoles et procédures techniques établis par le groupe TFO en vertu du Contrat
            ;
          </p>
          <p>
            <b>"Administrateur"</b> désigne l'utilisateur de la société de marketing qui a acheté une licence du groupe
            TFO et qui est responsable de la mise en place et des interactions avec les utilisateurs. Ils doivent
            pouvoir (i) utiliser l'Outil, l'Application et les Services, (ii) modifier les paramètres de configuration
            (y compris, mais sans s'y limiter, l'ajout de Services ou de fonctionnalités supplémentaires) et (iii) créer
            des comptes supplémentaires pour les Utilisateurs. Pour les besoins de ce Contrat, l'utilisateur est sous
            contrat avec la société de marketing et la société de marketing sera le contrôleur de données pour les
            données personnelles de l'utilisateur comme défini par le règlement général sur la protection des données
            2016/679.
          </p>
          <p>
            <b>"Société de marketing"</b> désigne la Société qui est sous contrat avec une Société du Groupe TFO et qui
            utilise la Plateforme DataByte dans le cadre de la prestation de ses services. La Société de Marketing est
            l'Administrateur tel que défini.{' '}
          </p>
          <p>
            <b>"Application"</b> désigne l'application Web mobile progressive (PWA) de l'outil par laquelle les services
            peuvent être utilisés ;
          </p>
          <p>
            <b>"Communication"</b> désigne toute communication envoyée par l'Outil par courrier électronique à des
            heures régulières concernant les annonces liées aux Services, les courriers électroniques administratifs et
            les mises à jour{' '}
          </p>
          <p>
            <b>"Utilisateur"</b> désigne toute personne physique, ainsi que toute personne qui utilise les Services de
            l'Outil. Toute personne physique est considérée comme étant âgée d'au moins 18 ans, Aux fins du présent
            contrat, l'utilisateur peut être désigné par le terme " vous " ;
          </p>
          <p>
            <b>"Données de l'utilisateur"</b> désigne tout contenu, toute information et toute donnée - y compris les
            données personnelles - concernant les prospects, les partenaires commerciaux, les clients de l'Utilisateur
            (non limitatif) saisis et téléchargés dans l'Outil et/ou l'Application par l'Utilisateur en utilisant les
            Services ;
          </p>
          <p>
            <b>"Documentation"</b> désigne toute documentation fournie par le groupe TFO concernant l'outil,
            l'application et les services ainsi que le site Web, y compris toute documentation, tutoriel ou autre
            disponible sur le site Web ;
          </p>
          <p>
            <b>"Politique de confidentialité"</b> désigne la déclaration de confidentialité du groupe TFO telle que
            disponible sur le site tfo.group.
          </p>
          <p>
            <b>"Services"</b> sont les services en ligne qui facilitent la gestion et la coopération en ligne, et
            consistent entre autres en un système CRM, un point de vente électronique (EPOS), provisionnement  /
            vérification et l'académie / l'amélioration des compétences.
          </p>
          <p>
            <b>"Indemnisé"</b> désigne chaque dirigeant, administrateur, partenaire, employé et membre du groupe TFO ;
          </p>
          <p>
            <b>"Terme"</b> signifie la période de temps pendant laquelle vous utilisez l'outil.
          </p>
          <p>
            <b>"Outil" & "Plate-forme"</b> désigne l'application en ligne, développée par DataByte, sous la marque
            "DataByte".
          </p>
          <p>
            <b>"Compte d'utilisateur"</b> désigne le compte d'utilisateur auquel l'Utilisateur peut accéder par son
            login personnel pour utiliser l'Outil, l'Application et les Services ;
          </p>
          <p>
            <b>"Site Web"</b> désigne le site web que DataByte utilise pour le déploiement de l'Outil et chaque site web
            DataByte du pays dans lequel DataByte opère.
          </p>

          <h2>5.Construction</h2>

          <ol>
            <li>
              Les mots au singulier comprennent le pluriel et vice versa, et les mots au masculin comprennent le féminin
              ou le genre neutre et vice versa.
            </li>
            <li>
              Les mots qui importent des personnes comprennent les personnes physiques, les sociétés (quel que soit leur
              lieu de constitution), les associations non constituées en sociétés (y compris les sociétés de personnes),
              les fiducies, toute forme d'organisme, d'agence ou d'autorité gouvernementale et toute autre organisation
              de quelque nature que ce soit (dans chaque cas, qu'elle ait ou non une personnalité juridique distincte),
              et les expressions faisant référence à l'écrit sont considérées comme faisant référence à la
              dactylographie, à la lithographie, à la photographie et aux autres modes de représentation ou de
              reproduction des mots sous une forme visible.
            </li>
            <li>
              Les références à toute disposition législative sont réputées comprendre toutes les modifications,
              réadoptions, remplacements et prolongations de cette disposition à la date des présentes ou avant ou après
              cette date, sans modification de fond, et comprennent également tous les textes réglementaires ou
              ordonnances pris de temps à autre en vertu de ces dispositions
            </li>
            <li>
              Les titres et les légendes du présent Contrat sont insérés pour faciliter la consultation et ne doivent
              pas être considérés comme faisant partie du présent Contrat ni comme ayant une incidence sur son
              interprétation.
            </li>
          </ol>

          <h2>6.Champ d'application</h2>
          <p>
            Le groupe TFO a développé et propose un outil de gestion de la relation client ('CRM'), de point de vente
            électronique (EPOS), de provisionnement / vérification et une académie / amélioration des compétences. Cet
            Outil, disponible sous la forme d'une application Web progressive (PWA) et d'une application en ligne, offre
            une intégration de divers services qui assurent une administration plus efficace des activités de ses
            utilisateurs.
          </p>
          <p>
            Toute relation commerciale entre le groupe TFO et l'Utilisateur sera régie par les conditions de service
            telles que définies dans le présent document.
          </p>
          <p>
            En créant un compte sur l'Outil, l'Utilisateur reconnaît avoir lu et accepté les présentes conditions
            générales d'utilisation. Les conditions de service ont toujours la priorité sur les conditions de
            l'utilisateur, même si elles stipulent qu'elles sont les seules conditions valables.
          </p>
          <p>
            Le Groupe TFO se réserve le droit de modifier ses conditions de service chaque fois qu'il le souhaite par un
            changement sur son site Web. Le groupe TFO s'engage à en informer l'utilisateur par
          </p>

          <ol type="i">
            <li>
              le biais d'une annonce sur son site Web dans un délai raisonnable avant l'application des conditions de
              service modifiées et/ou
            </li>
            <li>par le biais de la communication DataByte.</li>
          </ol>
          <p>
            Ces conditions de service doivent être lues en même temps que la Politique de confidentialité et peuvent
            être consultées à tout moment sur le Site Web.
          </p>

          <h2>7.Offre</h2>
          <p>
            Les catalogues, brochures, bulletins d'information, dossiers, communications du groupe TFO et autres
            annonces publicitaires, ainsi que les annonces sur le site Web sont entièrement non contraignants, et ne
            peuvent être considérés par l'Utilisateur ou la Société de marketing que comme une " invitation à entrer ",
            sauf indication contraire explicite.
          </p>
          <p>Chaque offre n'est valable que</p>
          <ol type="i">
            <li>
              pour une commande spécifique et ne s'applique donc pas automatiquement aux commandes suivantes
              (similaires)
            </li>
            <li>ni à la durée qui y est stipulée.</li>
          </ol>

          <h2>8.Compte d'Administrateur/ Compte d'Utilisateur</h2>
          <p>
            L'Utilisateur pourra accéder à l'Outil et à l'Application ainsi qu'utiliser les Services par le biais du
            Compte Administrateur. L'Administrateur est le seul responsable de toute utilisation et activité du compte
            de l'Administrateur.
          </p>
          <p>
            L'Administrateur détermine l'étendue des droits des comptes d'Utilisateur et l'Administrateur reste toujours
            responsable de chaque utilisation des comptes d'Utilisateur.
          </p>
          <p>
            Chaque compte utilisateur (y compris le compte Administrateur) appartient à une seule personne et ne peut
            donc pas être partagé avec d'autres personnes. Il est conseillé à l'Administrateur ainsi qu'à chaque
            Utilisateur de créer un mot de passe unique, de le modifier fréquemment et/ou d'utiliser une
            authentification à deux facteurs.
          </p>
          <p>
            L'Utilisateur est seul responsable de la protection et de la sécurité du compte d'Administration et des
            comptes d'Utilisateur. Dans ce contexte, l'Administrateur et chaque Utilisateur doivent assurer la
            confidentialité de leur compte respectif, y compris la confidentialité des données de connexion telles que
            le mot de passe.{' '}
          </p>

          <h2>9.Utilisation des services</h2>
          <p>
            Tant que le présent contrat est en vigueur, l'Utilisateur peut utiliser l'Outil, l'Application et les
            Services dans le cadre des Droits d'accès de l'Utilisateur, dont l'étendue est déterminée lors de la
            commande des Services. La portée de ces Droits d'accès peut être limitée ou étendue pendant le Terme du
            contrat.
          </p>
          <p>L'Utilisateur convient que le respect de cet article est une base essentielle du contrat.</p>
          <p>
            L'utilisation et l'accès aux Services par l'Utilisateur présuppose une connexion Internet et l'utilisation
            d'un navigateur Web moderne (tel que, mais sans s'y limiter, la version la plus récente de Google Chrome).
            Si un navigateur Web obsolète est utilisé, l'Utilisateur peut ne pas être en mesure d'utiliser toutes les
            fonctions des Services ou ces fonctions peuvent ne pas fonctionner de façon optimale.
          </p>
          <p>
            L'Utilisateur est entièrement responsable du respect des présentes conditions de service, ainsi que des
            actes et omissions de tous les Utilisateurs qui utilisent l'Outil, l'Application et les Services par
            l'intermédiaire du Compte d'Administrateur et/ou du Compte d'Utilisateur. L'Utilisateur ne doit pas
            autoriser l'accès ou l'utilisation de l'Outil, de l'Application et des Services ni de la Documentation par
            des personnes autres que l'Administrateur ou les Utilisateurs.
          </p>

          <h2>10.Restrictions</h2>
          <p>
            L'Utilisateur s'engage à ne pas faire un usage abusif des Droits d'Accès et ne le fera donc pas, sans que
            cette liste soit exhaustive ;
          </p>

          <ul>
            <li>
              Vendre, revendre, octroyer une licence, une sous-licence, louer ou distribuer l'Outil, l'Application et
              tout Service, ou inclure tout Service ou toute œuvre dérivée de celui-ci dans une offre de service bureau
              ou d'externalisation à une tierce partie ;
            </li>
            <li>
              Copier, modifier, adapter, altérer, traduire ou réaliser des travaux dérivés basés sur les Services
              (autres que les copies, modifications ou travaux dérivés réalisés exclusivement à partir des rapports ou
              des aperçus qui sont créés uniquement à des fins commerciales internes des Utilisateurs) ;
            </li>
            <li>
              S'engager, ni autoriser d'autres personnes à s'engager dans la rétro-ingénierie, le démontage ou la
              décompilation de l'Outil, de l'Application et/ou des Services.
            </li>
            <li>
              Utiliser l'Outil, l'Application et les Services à des fins illégales ou pour la transmission de données
              illégales, diffamatoires, portant atteinte à la vie privée d'autrui, abusives, menaçantes, nuisibles ou
              portant atteinte à la propriété intellectuelle de quelqu'un (liste non exhaustive).
            </li>
            <li>Utiliser l'Outil, l'Application et les Services pour mener ou promouvoir toute activité illégale ;</li>
            <li>
              Utilisez l'Outil, l'Application et les Services pour le transfert de "junk mail", "spam", "chain mail",
              "phishing" ou toute autre circulation de masse non désirée de courriers électroniques ;
            </li>
            <li>Utiliser les Services pour traquer, harceler ou blesser une autre personne ;</li>
            <li>
              Perturber le bon fonctionnement de l'Outil, de l'Application et/ou du Site Web. Cela comprend que
              l'Utilisateur doit s'abstenir d'utiliser des virus, des vers, des chevaux de Troie ou d'autres logiciels
              qui pourraient enfreindre les services et les intérêts du groupe TFO et de son Utilisateur. L'Utilisateur
              doit également s'abstenir de tout contenu qui pourrait alourdir ou perturber l'infrastructure du site Web
              et son bon fonctionnement ;
            </li>
            <li>
              Envoyer des messages non sollicités et/ou commerciaux, tels que le courrier indésirable, le spamming et
              les chaînes de lettres ;
            </li>
            <li>
              Ajouter du contenu qui peut être décrit comme non approprié par rapport à l'objectif de l'Outil et/ou de
              l'Application. Le groupe TFO se réserve une grande discrétion et peut aviser un Utilisateur s'il croit que
              son contrat n'est pas approprié{' '}
            </li>
            <li>Utilisez l'outil, l'application et les services pour agir de manière à nuire au groupe TFO ;</li>
            <li>
              Utiliser les Services - en partie ou en totalité - ni l'Outil, l'Application ou le site Web d'une manière
              qui pourrait donner une impression, une attribution ou une déclaration fausse ou trompeuse à l'égard du
              groupe TFO ou d'un tiers.
            </li>
          </ul>

          <h2>11.Propriété intellectuelle</h2>
          <h3>Droits de propriété intellectuelle du groupe TFO</h3>

          <p>
            L'Utilisateur reconnaît explicitement que le groupe TFO est et demeure le seul propriétaire de l'Outil, de
            l'Application et des Services et/ou des autres droits de propriété intellectuelle s'y rapportant. Tous ces
            droits et ce fonds commercial sont, et demeureront, acquis au groupe TFO.
          </p>
          <p>
            Par conséquent, les Droits d'Accès accordés à l'Utilisateur impliquent uniquement le droit d'utiliser - à
            titre onéreux - l'Outil, l'Application et les Services et aucune licence implicite ne sera accordée en vertu
            du présent accord.
          </p>
          <p>
            En aucun cas, un tel droit n'entraîne un transfert de propriété des Outils, des Applications, des Services
            et de la Documentation par le groupe TFO à l'Utilisateur ; il n'accorde à l'Utilisateur aucun droit ou
            intérêt dans les outils, les applications, les services, les noms commerciaux et/ou les marques de commerce
            du groupe TFO, ni ne lui accorde le droit de demander au groupe TFO de lui livrer une copie de tout logiciel
            ou autre produit utilisé par le groupe TFO pour fournir les Services.
          </p>
          <p>
            L'utilisateur ne doit donc pas utiliser de marque de commerce, de nom commercial ou de nom de marque du
            groupe TFO (tel que, mais sans s'y limiter, l'utilisation de ceux-ci dans les métabalises, les mots clés ou
            le texte caché), sans l'approbation écrite explicite du groupe TFO.
          </p>
          <p>
            Sans préjudice du droit de l'Utilisateur ou de tout tiers de contester la validité de toute propriété
            intellectuelle du groupe TFO, l'Utilisateur ne doit pas accomplir ou autoriser un tiers à accomplir un acte
            qui invaliderait ou pourrait invalider ou être incompatible avec toute propriété intellectuelle du groupe
            TFO - y compris, sans s'y limiter, tout brevet, droit d'auteur, dessin enregistré, marque de commerce ou
            autre droit de propriété industrielle ou intellectuelle - et ne doit pas omettre ou autoriser un tiers à
            omettre d'accomplir un acte qui, par son omission, aurait cet effet.
          </p>
          <p>
            L'utilisateur s'engage à aviser le groupe TFO de toute violation réelle, menacée ou soupçonnée de tout droit
            de propriété intellectuelle du groupe TFO dont il a connaissance, et de toute réclamation d'un tiers en
            raison de l'utilisation de l'Outil, de l'Application et des Services.
          </p>

          <h2>12.Résiliation</h2>
          <p>
            Le groupe TFO peut, à sa seule discrétion, résilier le contrat avec un utilisateur en tout temps. Dans le
            cas d'une telle résiliation par le groupe TFO, la résiliation se fera sans préavis ni indemnité.
          </p>

          <h2>13.Responsabilité</h2>
          <p>
            L'utilisation prévue de l'Outil, de l'Application et des Services par l'Utilisateur, l'Administrateur et/ou
            les Utilisateurs est déterminée sous leur entière responsabilité et à leurs propres risques. Le groupe TFO
            ne peut en aucun cas être tenu pour responsable de tout dommage direct ou indirect résultant de
            l'utilisation de l'Outil et de l'App. Par conséquent, l'Utilisateur, l'Administrateur et/ou l'Utilisateur
            sera donc seul responsable de tout dommage à son ordinateur (programmes), à ses dispositifs sans fil et/ou à
            tout autre équipement suite à son utilisation de l'Outil, de l'Application et des Services.
          </p>

          <h2>14.Le groupe TFO n'est pas responsable de :</h2>
          <ul>
            <li>
              Les dommages indirects et/ou consécutifs (y compris, mais sans s'y limiter, la perte de revenus, la perte
              de clientèle et les dommages aux biens de l'Utilisateur causés par l'Outil, l'Application et les
              Services). Cette limitation de responsabilité s'applique également lorsque le groupe TFO a été
              spécifiquement informé de la perte potentielle par l'Utilisateur ;
            </li>
            <li>
              Les défauts qui ont été causés directement ou indirectement par un acte de la part de l'Utilisateur ou
              d'un tiers, qu'ils soient causés par une erreur ou une négligence ;
            </li>
            <li>
              Les dommages causés par l'utilisation de l'Outil, de l'Application et des Services dans un but différent
              de celui pour lequel ils ont été développés ou sont destinés par le groupe TFO ;
            </li>
            <li>
              Dommages supplémentaires causés par la poursuite de l'utilisation par l'Utilisateur, l'Administrateur
              et/ou les Utilisateurs après qu'un défaut a été détecté ;
            </li>
            <li>
              Les dommages causés par le non-respect de tout conseil et/ou de toute directive pouvant être donnés par le
              groupe TFO, que ce dernier fournit toujours de façon discrétionnaire ;
            </li>
          </ul>
          <p>
            En outre, l'utilisateur accepte que le groupe TFO n'offre aucune garantie que l'Outil, l'Application et les
            services sont conformes aux règlements ou exigences qui s'appliquent dans tout domaine juridique, à
            l'exception des règlements ou exigences qui s'appliquent en Irlande au moment de la conclusion du contrat.
            Le groupe TFO ne peut donc être tenu pour responsable de toute modification ultérieure, de quelque nature
            que ce soit, de ces lois et/ou règlements.
          </p>
          <p>
            L'Utilisateur doit indemniser et/ou dégager de toute responsabilité le groupe TFO et/ou un Indemnisé du
            groupe TFO contre toute réclamation de quelque nature que ce soit qui pourrait découler de l'existence, de
            la mise en œuvre, du non-respect et/ou de la résiliation des présentes conditions de service et qui a été
            causée par sa propre négligence, faute ou imprudence ou par un Administrateur et/ou l'un de ses
            Utilisateurs.
          </p>

          <h2>15.Loi et juridiction applicables</h2>
          <p>
            Tous les problèmes, questions et litiges concernant la validité, l'interprétation, l'application,
            l'exécution ou la résiliation du présent contrat seront régis par et interprétés conformément au droit
            irlandais.
          </p>

          <h2>16.Protection des données</h2>
          <p>
            Le groupe TFO doit être un Responsable du traitement des données et l'Administrateur doit être le Contrôleur
            des données.
          </p>
          <ol>
            <li>
              Les parties reconnaissent et conviennent que pour que le groupe TFO puisse fournir les Services :
              <ol type="a">
                <li>
                  L'Utilisateur doit fournir au groupe TFO des données qui sont au sens de " données personnelles "
                  telles que définies dans le règlement général sur la protection des données 2016/679 ; et
                </li>
                <li>
                  Le groupe TFO devra traiter les données personnelles de l'Utilisateur au nom de l'Utilisateur ; et
                </li>
                <li>
                  Le groupe TFO doit être un sous-traitant de données (tel que défini par le règlement général sur la
                  protection des données 2016/679) et
                </li>
                <li>
                  L'Administrateur est le Responsable du traitement des données (tel que défini dans le règlement
                  général sur la protection des données 2016/679).
                </li>
              </ol>
            </li>
            <li>
              Le groupe TFO traitera et utilisera les données personnelles de l'Utilisateur uniquement pour la
              prestation de services et conformément aux dispositions du présent Contrat et à toute instruction
              raisonnable de l'Utilisateur.
            </li>
            <li>
              Le groupe TFO déclare qu'il ne traitera les données personnelles de l'utilisateur que dans la mesure
              nécessaire et uniquement avec dans le cadre nécessaire à l'exécution des services.
            </li>
            <li>
              Le groupe TFO ne doit pas, sauf avec l'autorisation écrite préalable du Contrôleur de données de
              l'utilisateur ou de l'Administrateur :
              <ol type="a">
                <li>
                  transférer ou traiter toute donnée personnelle de l'Utilisateur en dehors de l'Espace économique
                  européen ;
                </li>
                <li>
                  sous-traiter l'une de ses obligations concernant ou en relation avec les Données Personnelles de
                  l'Utilisateur.
                </li>
              </ol>
            </li>
            <li>
              Le groupe TFO emploiera les ressources et mesures techniques et organisationnelles nécessaires pour se
              conformer à ses obligations en vertu du règlement général sur la protection des données 2016/679 et pour
              empêcher toute utilisation ou tout traitement illégal ou non autorisé des données personnelles de
              l'utilisateur ou contre la perte ou la destruction accidentelle ou la détérioration des données
              personnelles. Sans préjudice de la généralité de la phrase précédente, le groupe TFO devra également se
              conformer à toute instruction raisonnable que l'Utilisateur fournira de temps à autre.
            </li>
          </ol>

          <h2>17.Contrat dans son intégralité</h2>
          <ol>
            <li>
              Le présent contrat constitue l'intégralité de l'accord entre les parties et remplace et éteint tous les
              accords, promesses, assurances, garanties, déclarations et ententes antérieurs entre elles, qu'ils soient
              écrits ou oraux, relatifs à son objet.
            </li>
            <li>
              Chaque partie convient qu'elle n'a aucun recours à l'égard de toute déclaration, représentation, assurance
              ou garantie (faite de bonne foi ou par négligence) qui n'est pas énoncée dans le présent Contrat.
            </li>
            <li>
              Chaque partie convient qu'elle n'aura aucune réclamation pour fausse déclaration ou déclaration négligente
              de bonne foi ou négligente fondée sur une déclaration figurant dans le présent Contrat.
            </li>
          </ol>

          <h2>18.Force Majeure</h2>
          <ol>
            <li>
              Un événement de force majeure désigne toute circonstance qui n'est pas sous le contrôle raisonnable du
              groupe TFO, y compris, sans s'y limiter :
              <ol type="a">
                <li>
                  les actes de Dieu, les inondations, la sécheresse, les tremblements de terre ou autres catastrophes
                  naturelles ;
                </li>
                <li>épidémie ou pandémie ;</li>
                <li>
                  attaque terroriste, guerre civile, troubles ou émeutes, guerre, menace de guerre ou préparation à la
                  guerre, conflit armé, imposition de sanctions, embargo ou rupture des relations diplomatiques ;
                </li>
                <li>contamination nucléaire, chimique ou biologique ou boom sonique ;</li>
                <li>
                  toute loi ou toute mesure prise par un gouvernement ou une autorité publique, y compris, mais sans s'y
                  limiter, l'imposition d'une restriction, d'un quota ou d'une interdiction d'exportation ou
                  d'importation, ou le défaut d'accorder une licence ou un consentement nécessaire ;
                </li>
                <li>l'effondrement de bâtiments, un incendie, une explosion ou un accident; et</li>
                <li>tout conflit de travail ou de commerce, grève, action industrielle ou lock-out </li>
                <li>interruption ou défaillance du service du Groupe TFO.</li>
              </ol>
            </li>
            <li>
              Les obligations correspondantes de DataByte seront suspendues, et le temps d'exécution de ces obligations
              sera prolongé.
            </li>
            <li>
              Le groupe TFO doit :
              <ol type="a">
                <li>
                  dès que raisonnablement possible après le début de l'événement de force majeure, mais au plus tard 72
                  heures après son début, aviser l'autre Utilisateur de l'événement de force majeure, de la date à
                  laquelle il a commencé, de sa durée probable ou potentielle et de l'effet de l'événement de force
                  majeure sur sa capacité à s'acquitter de ses obligations en vertu du contrat ; et
                </li>
                <li>
                  faire tous les efforts possibles pour atténuer l'effet de l'événement de force majeure sur l'exécution
                  de ses obligations.
                </li>
              </ol>
            </li>
          </ol>

          <h2>19.Séparabilité </h2>
          <p>
            Si une disposition ou une partie d'une disposition du présent Contrat est ou devient invalide, illégale ou
            inapplicable, elle sera réputée supprimée, mais cela n'affectera pas la validité et l'applicabilité du reste
            du Contrat.
          </p>

          <h2>20.Langue</h2>
          <p>
            Sauf convention contraire expresse, l'Utilisateur reconnaît que la langue des présentes conditions sera
            également la langue de travail dans toutes les transactions commerciales avec le groupe TFO.
          </p>
          <p>
            La langue originale de ces conditions est l'anglais. Les traductions ou documents rédigés dans une autre
            langue seront toujours considérés comme un bonus pour l'Utilisateur. En cas de conflit, la version anglaise
            prévaudra toujours.
          </p>
          <h2>21.Emplacement</h2>
          <p>
            Veuillez noter que l'activation des services de localisation est requise pour utiliser cette application,
            car elle permet d'éviter la fraude lors du processus d'inscription. Cette fonctionnalité est utilisée
            exclusivement pour vérifier l'exactitude des données. Rassurez-vous, vos données GPS et votre localisation
            ne sont pas suivies, stockées ou surveillées au-delà de la portée de cette vérification.
          </p>
        </div>
      )}
      {(user_locale === 'nl' || user.locale === 'nl') && (
        <div>
          <h3>Agent #1 Servicevoorwaarden (TFO – BA)</h3>
          <h3>1.Overweging</h3>
          <p>
            DataByte is een online platform zoals beschreven in het document, het Platform werkt met drie (3)
            gebruikersgroepen; (i) TFO Group als de Systeembeheerder, (ii) een Marketing Bedrijfsbeheerder (zoals
            gedefinieerd als "Beheerder"), die een licentie van TFO Group heeft gekocht om het Platform te gebruiken,
            die volledig verantwoordelijk is voor het opzetten en de interacties met de Gebruikers. Groep (iii) zijn de
            Gebruikers zoals gedefinieerd in Definities en zij hebben een relatie met de Marketingbedrijven.
          </p>
          <p>
            TFO Group verleent een dienst aan de Gebruikers namens de Marketingbedrijven die een licentie van TFO Group
            hebben gekocht.
          </p>
          <h2>2.Aanvaardbaar gebruik van onze diensten</h2>
          <h3>Onze Voorwaarden en Beleid.</h3>
          <p>
            U moet onze Diensten gebruiken in overeenstemming met onze Voorwaarden en ons Beleid. Als u onze Voorwaarden
            of ons Beleid overtreedt, kunnen wij actie ondernemen met betrekking tot uw account, met inbegrip van het
            uitschakelen of opschorten van uw account en, als we dat doen, mag u geen andere account aanmaken zonder
            onze toestemming.
          </p>
          <h3>Legaal en aanvaardbaar gebruik.</h3>
          <p>
            U mag onze Diensten alleen raadplegen en gebruiken voor legale, geautoriseerde en acceptabele doeleinden. U
            zult onze Diensten niet gebruiken (of anderen helpen bij het gebruik) op manieren die:{' '}
          </p>
          <ol type="a">
            <li>
              de rechten van de TFO Group, onze Gebruikers of anderen schenden of verduisteren, inclusief privacy-,
              publiciteits-, intellectuele eigendoms-, of andere eigendomsrechten;{' '}
            </li>
            <li>
              onwettig, obsceen, lasterlijk, bedreigend, intimiderend, kwetsend, hatelijk, raciaal of etnisch beledigend
              zijn, of aanzetten tot gedrag dat onwettig of anderszins ongepast is, met inbegrip van het bevorderen van
              geweldsmisdrijven;{' '}
            </li>
            <li>
              gepaard gaan met het publiceren van onwaarheden, onjuiste voorstellingen van zaken, of misleidende
              verklaringen;{' '}
            </li>
            <li>zich als iemand voordoen; </li>
            <li>
              betrekking hebben op elk niet-persoonlijk gebruik van onze Diensten, tenzij anders door ons toegestaan.
            </li>
          </ol>
          <h2>3.Partijen</h2>
          <p>
            <b>The Firm Organisation Ltd t/a TFO Group</b> waarnaar hier wordt verwezen als "DataByte" (Bedrijfsregister
            nr. 637481) Maatschappelijke zetel: 32 Merrion Street, Dublin 2, Ierland en u de <b>"Gebruiker"</b>.
          </p>
          <h2>4.Definities</h2>
          <p>
            In deze servicevoorwaarden hebben de volgende begrippen de in dit artikel omschreven betekenis (wanneer zij
            met een hoofdletter worden geschreven).
          </p>
          <p>
            <b>"Toegangsrechten"</b> betekent alle contractuele rechten op toegang tot, ontvangst van en gebruik van de
            Diensten volgens de technische protocollen en procedures die door de TFO Group krachtens de Overeenkomst
            zijn vastgesteld;
          </p>
          <p>
            <b>"Beheerder"</b> betekent de gebruiker van het Marketingbedrijf die een licentie van TFO Group heeft
            gekocht en verantwoordelijk is voor de inrichting van en interacties met de Gebruikers. Zij zijn in staat om
            (i) de Tool, App en Diensten te gebruiken, (ii) de configuratie-instellingen te wijzigen (inclusief maar
            niet beperkt tot het toevoegen van extra Diensten of functies) en (iii) extra accounts voor Gebruikers aan
            te maken. Voor de toepassing van deze Overeenkomst wordt de Gebruiker gecontracteerd met het
            Marketingbedrijf en is het Marketingbedrijf de Gegevensbeheerder voor de Persoonsgegevens van de Gebruiker,
            zoals gedefinieerd in de Algemene Verordening Gegevensbescherming 2016/679.
          </p>
          <p>
            <b>"Marketingbedrijf"</b> Zal betekenen het Bedrijf dat gecontracteerd is met een Bedrijf van de TFO Groep
            en dat het DataByte Platform gebruikt als onderdeel van hun levering van diensten. Het Marketingbedrijf zal
            de Beheerder zijn zoals gedefinieerd.{' '}
          </p>
          <p>
            <b>"App"</b> etekent de mobiele Progressive Web Application (PWA) van de Tool via welke de Diensten kunnen
            worden gebruikt;
          </p>
          <p>
            <b>"Communicatie"</b> betekent elke communicatie verzonden door de Tool via e-mail op regelmatige
            tijdstippen betreffende diensten-gerelateerde aankondigingen, administratieve e-mails en updates;
          </p>
          <p>
            <b>"Gebruiker"</b> betekent elke natuurlijke persoon, evenals iedereen die de Diensten van de Tool gebruikt.
            Elke natuurlijke persoon wordt geacht minstens 18 jaar oud te zijn. In het kader van deze overeenkomst kan
            naar de gebruiker verwezen worden als "u";
          </p>
          <p>
            <b>"Gebruikersgegevens"</b> betekent alle inhoud, informatie en gegevens - inclusief persoonlijke gegevens -
            met betrekking tot prospects, zakenpartners, klanten en/of klanten van de Gebruiker (niet limitatief)
            ingevoerd en geüpload in de Tool en/of App door de Gebruiker door gebruik te maken van de Diensten;
          </p>
          <p>
            <b>"Documentatie"</b> betekent alle documentatie die door TFO Group wordt verstrekt met betrekking tot de
            Tool, App en Diensten alsmede de Website en inclusief alle documentatie, handleidingen of andere beschikbaar
            op de Website;
          </p>
          <p>
            <b>"Privacybeleid"</b> wordt verstaan de privacyverklaring van de TFO Group zoals beschikbaar op de website
            www.tfo.group.
          </p>
          <p>
            <b>"Diensten"</b> worden de online diensten verstaan die online beheer en samenwerking mogelijk maken, en
            onder meer bestaan uit een CRM-systeem, Electronic Point of Sale (EPOS), Onboarding- en Screeningproces en
            Opleiding en Bijscholing.
          </p>
          <p>
            <b>"Gevrijwaarde"</b> betekent iedere functionaris, directeur, partner, werknemer en filiaal van de TFO
            Group;
          </p>
          <p>
            <b>"Termijn"</b> betekent de tijdsperiode waarin u de Tool gebruikt.
          </p>
          <p>
            <b>"Tool" & "Platform"</b> betekent de online applicatie, ontwikkeld door DataByte, met de merknaam
            "DataByte".
          </p>
          <p>
            <b>"Gebruikersaccount"</b> betekent de gebruikersaccount, waartoe een Gebruiker toegang heeft via zijn/haar
            persoonlijke login om gebruik te maken van de Tool, App en Diensten;
          </p>
          <p>
            <b>"Website"</b> betekent website die DataByte gebruikt voor de inzet van de Tool en elke DataByte website
            van het land waar DataByte actief is.
          </p>
          <h2>5.Constructie</h2>
          <ol>
            <li>
              Woorden die betrekking hebben op het enkelvoud omvatten ook het meervoud en omgekeerd, en woorden die
              betrekking hebben op het mannelijk geslacht omvatten ook het vrouwelijk of onzijdig geslacht en omgekeerd.
            </li>
            <li>
              De woorden "personen" omvatten natuurlijke personen, rechtspersonen, verenigingen zonder
              rechtspersoonlijkheid (met inbegrip van personenvennootschappen), trusts, alle vormen van
              overheidsinstanties, -agentschappen of -autoriteiten en alle andere organisaties van welke aard dan ook
              (in elk geval ongeacht of deze wel of niet rechtspersoonlijkheid bezitten), en uitdrukkingen die
              betrekking hebben op geschriften worden beschouwd als betrekking hebbend op typemachines, drukmachines,
              lithografie, fotografie en andere manieren om woorden in een zichtbare vorm weer te geven of te
              reproduceren.
            </li>
            <li>
              Verwijzingen naar wettelijke bepalingen worden geacht mede te omvatten alle wettelijke wijzigingen,
              vernieuwingen, vervangingen en uitbreidingen van dergelijke bepalingen op of voor de datum hiervan of
              nadien opnieuw vastgesteld zonder inhoudelijke wijziging en omvatten voorts alle wettelijke instrumenten
              of besluiten van tijd tot tijd uitgevaardigd ingevolge deze bepalingen.
            </li>
            <li>
              De opschriften en titels in deze Overeenkomst zijn opgenomen voor verwijzingsdoeleinden en worden niet
              geacht deel uit te maken van of invloed te hebben op de constructie of interpretatie van deze
              Overeenkomst.
            </li>
          </ol>
          <h2>6.Werkingssfeer</h2>
          <p>
            TFO Group ontwikkelde en biedt een Tool aan voor Customer Relationship Management ('CRM'), Electronic Point
            of Sale (EPOS), Onboarding- en Screeningproces en Opleiding en Bijscholing. Deze Tool, die beschikbaar is in
            de vorm van een Progressive Web Application (PWA) en online applicatie, biedt een integratie van
            verschillende diensten die zorgen voor een efficiëntere bedrijfsadministratie van haar Gebruikers.
          </p>
          <p>
            Elke commerciële relatie tussen de TFO Group en de gebruiker wordt beheerst door de servicevoorwaarden die
            in dit document zijn vastgelegd.
          </p>
          <p>
            Door een account aan te maken op de Tool, erkent de Gebruiker deze servicevoorwaarden te hebben gelezen en
            aanvaard. De voorwaarden van de dienst hebben altijd voorrang op de voorwaarden van de Gebruiker, zelfs als
            deze bepalen dat zij de enige geldige voorwaarden zijn.
          </p>
          <p>
            De TFO Group behoudt zich het recht voor om haar servicevoorwaarden te wijzigen wanneer zij dat wenst door
            middel van een wijziging op haar Website. TFO Group verbindt zich ertoe de Gebruiker hiervan op de hoogte te
            brengen via  
          </p>
          <ol type="i">
            <li>
              een aankondiging op haar Website binnen een redelijke termijn voorafgaand aan de toepassing van de
              gewijzigde servicevoorwaarden en/of
            </li>
            <li>via de DataByte-communicatie.</li>
          </ol>
          <p>
            TDeze servicevoorwaarden moeten samen met het Privacybeleid worden gelezen en kunnen te allen tijde op de
            Website worden geraadpleegd.
          </p>
          <h2>7.Aanbod</h2>
          <p>
            Catalogi, brochures, nieuwsbrieven, folders, mededelingen van de TFO Group en andere publicitaire
            mededelingen, alsmede mededelingen op de Website zijn geheel vrijblijvend, en kunnen door de Gebruiker of
            Marketingbedrijf slechts worden beschouwd als een "uitnodiging tot behandeling", tenzij uitdrukkelijk anders
            is vermeld.
          </p>
          <p>Elk aanbod is slechts geldig voor</p>
          <ol type="i">
            <li>
              een specifieke bestelling en is derhalve niet automatisch van toepassing op latere (soortgelijke)
              bestellingen, alsmede
            </li>
            <li>de daarin bepaalde duur.</li>
          </ol>
          <h2>8.Beheerdersaccount/ Gebruikersaccount</h2>
          <p>
            De Gebruiker heeft toegang tot de Tool en App en kan gebruik maken van de Diensten via de Beheerdersaccount.
            De Beheerder is als enige verantwoordelijk voor elk gebruik en elke activiteit van de Beheerdersaccount.
          </p>
          <p>
            De Beheerder bepaalt de omvang van de rechten van de Gebruikersaccounts en de Beheerder blijft te allen
            tijde verantwoordelijk voor elk gebruik van de Gebruikersaccounts.
          </p>
          <p>
            Elke Gebruikersaccount (inclusief de Beheerdersaccount) behoort slechts toe aan één persoon en mag daarom
            niet met andere personen worden gedeeld. Zowel de Beheerder als elke Gebruiker wordt geadviseerd een uniek
            wachtwoord aan te maken, dit regelmatig te wijzigen en/of gebruik te maken van Twee-factor-Authenticatie
            (2FA).
          </p>
          <p>
            De Gebruiker is als enige verantwoordelijk voor de bescherming en beveiliging van de Beheerdersaccount en de
            Gebruikersaccounts. Daarom moeten de Beheerder en elke Gebruiker zorgen voor de geheimhouding van hun
            account, met inbegrip van de geheimhouding van de login-gegevens zoals het wachtwoord.
          </p>
          <h2> 9.Gebruik van de Diensten</h2>
          <p>
            Zolang deze overeenkomst van kracht is, kan de Gebruiker gebruik maken van de Tool, App en de Diensten
            binnen de reikwijdte van de Toegangsrechten van de Gebruiker, waarvan de reikwijdte wordt bepaald bij het
            bestellen van de Diensten. De reikwijdte van deze Toegangsrechten kan gedurende de looptijd van de
            overeenkomst worden beperkt of uitgebreid.
          </p>
          <p>
            De Gebruiker stemt ermee in dat de naleving van dit artikel een essentiële basis van de overeenkomst vormt.
          </p>
          <p>
            Het gebruik van en de toegang tot de Diensten door de Gebruiker veronderstelt een internetverbinding en het
            gebruik van een moderne webbrowser (zoals, maar niet beperkt tot, de meest recente versie van Google
            Chrome). Indien een verouderde webbrowser wordt gebruikt, is het mogelijk dat de Gebruiker niet alle
            functies van de Diensten kan gebruiken of dat deze functies niet optimaal functioneren.
          </p>
          <p>
            De Gebruiker is volledig verantwoordelijk voor de naleving van deze servicevoorwaarden, alsmede voor de
            handelingen en nalatigheden van alle Gebruikers die gebruik maken van de Tool, App en Diensten via de
            Beheerdersaccount en/of Gebruikersaccount. De Gebruiker zal geen toegang verlenen tot of gebruik toestaan
            van de Tool, App en Diensten of de Documentatie door andere personen dan de Beheerder of de Gebruikers.
          </p>
          <h2>10.Beperkingen</h2>
          De gebruiker stemt ermee in geen misbruik te maken van de toegangsrechten en zal dus niet, zonder dat deze
          opsomming limitatief is;
          <ul>
            <li>
              De Tool, de App en de Diensten verkopen, doorverkopen, in licentie geven, in onderlicentie geven,
              verhuren, leasen of distribueren, of de Diensten of daarvan afgeleide werken opnemen in een servicebureau
              of uitbestedingsaanbod aan een derde;
            </li>
            <li>
              Kopiëren, wijzigen, aanpassen, veranderen, vertalen of afgeleide werken maken op basis van de Diensten
              (met uitzondering van kopieën, wijzigingen of afgeleide werken die uitsluitend zijn gemaakt van de
              rapporten of overzichten die uitsluitend zijn gemaakt voor interne bedrijfsdoeleinden van Gebruikers);
            </li>
            <li>
              Zich inlaten met, en anderen niet toestaan zich in te laten met, reverse engineering, demontage of
              decompilatie van de Tool, App en/of Diensten.
            </li>
            <li>
              De Tool, App en Diensten te gebruiken voor illegale of onwettige doeleinden of voor de overdracht van
              gegevens die illegaal, lasterlijk, inbreukmakend op andermans privacy, beledigend, bedreigend, schadelijk
              of inbreukmakend op iemands intellectuele eigendom zijn (niet-uitputtende lijst).
            </li>
            <li>De Tool, App en Diensten gebruiken om illegale activiteiten uit te voeren of te promoten;</li>
            <li>
              De Tool, App en Diensten te gebruiken voor de overdracht van "junkmail", "spam", "kettingmail/ brieven",
              "phishing" of andere ongewenste massale circulatie van e-mails;
            </li>
            <li>
              De Diensten te gebruiken om een andere persoon te stalken, lastig te vallen of schade toe te brengen;
            </li>
            <li>
              De goede werking van de Tool, App en/of Website te verstoren. Dit houdt onder meer in dat de Gebruiker
              zich dient te onthouden van het gebruik van virussen, wormen, Trojaanse paarden of andere software die
              inbreuk kunnen maken op de diensten en belangen van zowel de TFO Group als de Gebruiker. De Gebruiker
              dient zich ook te onthouden van enige inhoud die de infrastructuur van de websites en de goede werking
              daarvan kan belasten of verstoren;
            </li>
            <li>Ongevraagde en/of commerciële berichten te versturen, zoals junkmail, spam en kettingbrieven;</li>
            <li>
              Inhoud toevoegen die kan worden omschreven als niet gepast met betrekking tot het doel van de Tool en/of
              App. TFO Group behoudt zich een grote discretie voor en kan een Gebruiker op de hoogte stellen als zij van
              mening is dat hun contract niet gepast is
            </li>
            <li>De Tool, App, en Diensten gebruiken om te handelen op een manier die de TFO Group schade berokkent;</li>
            <li>
              De Diensten - gedeeltelijk of integraal - noch de Tool, App of Website te gebruiken op een manier die een
              onjuiste of misleidende indruk, toeschrijving of verklaring met betrekking tot de TFO Group, of een derde
              partij kan geven.
            </li>
          </ul>
          <h2>11.Intellectuele eigendom</h2>
          <h3>Intellectuele eigendomsrechten van de TFO Group</h3>
          <p>
            De Gebruiker erkent uitdrukkelijk dat TFO Group de enige eigenaar is en blijft van de Tool, App en Diensten
            en/of andere intellectuele eigendomsrechten die daarmee verband houden. Al deze rechten en goodwill berusten
            bij TFO Group, en zullen dat ook blijven.
          </p>
          <p>
            Bijgevolg houden de aan de Gebruiker verleende Toegangsrechten uitsluitend het recht in om - tegen betaling
            - de Tool, App en Diensten te gebruiken en zullen er geen impliciete licenties worden verleend onder deze
            overeenkomst.
          </p>
          <p>
            Onder geen enkele omstandigheid houdt een dergelijk recht een eigendomsoverdracht in van de Tool, App,
            Diensten en Documentatie door TFO Groep aan de Gebruiker, of verleent de Gebruiker rechten op of belangen in
            de Tool, App, Diensten, handelsnamen en/of handelsmerken van TFO Groep, of verleent de Gebruiker het recht
            om TFO Groep te verzoeken een kopie te leveren van software of andere producten die door TFO Groep worden
            gebruikt om de Diensten te leveren. De Gebruiker zal dus geen gebruik maken van enig handelsmerk,
            handelsnaam, of merknaam van TFO Group (zoals maar niet beperkt tot het gebruik daarvan in metatags,
            trefwoorden of verborgen tekst), zonder de uitdrukkelijke schriftelijke toestemming van TFO Group.
          </p>
          <p>
            Onverminderd het recht van de Gebruiker of een derde partij om de geldigheid van enig intellectueel eigendom
            van de TFO Group aan te vechten, zal de Gebruiker geen handelingen verrichten of een derde partij machtigen
            om handelingen te verrichten die enig intellectueel eigendom van de TFO Group – inclusief, maar niet beperkt
            tot enig octrooi, auteursrecht, geregistreerd ontwerp, handelsmerk of andere industriële of intellectuele
            eigendomsrechten - ongeldig zouden maken of daarmee in strijd zouden kunnen zijn, en zal de Gebruiker geen
            handelingen nalaten of een derde partij machtigen om handelingen te nalaten die, door het nalaten daarvan,
            dat effect zouden hebben.
          </p>
          <p>
            De Gebruiker verplicht zich om TFO Group op de hoogte te stellen van elke daadwerkelijke, dreigende of
            vermoede inbreuk op intellectuele eigendomsrechten van TFO Group die de Gebruiker ter ore komt, en van elke
            claim van een derde partij als gevolg van het gebruik van de Tool, App en Diensten.
          </p>
          <h2>12.Beëindiging</h2>
          <p>
            TFO Group kan naar eigen goeddunken de overeenkomst met een Gebruiker op elk moment beëindigen. In het geval
            van een dergelijke beëindiging door TFO Group, zal beëindiging plaatsvinden zonder een opzegtermijn of
            schadevergoeding.
          </p>
          <h2>13.Aansprakelijkheid</h2>
          <p>
            Het beoogde gebruik van de Tool, App en Diensten door de Gebruiker, Beheerder en/of Gebruikers wordt bepaald
            onder hun volledige verantwoordelijkheid en op hun eigen risico. TFO Group kan op geen enkele wijze
            aansprakelijk worden gesteld voor enige directe of indirecte schade als gevolg van het gebruik van de Tool
            en App. Derhalve is de Gebruiker, Beheerder en/of Gebruiker als enige verantwoordelijk voor enige schade aan
            zijn computer(programma's), draadloze apparatuur en/of andere apparatuur die voortvloeit uit de Tool, App en
            Diensten.
          </p>
          <h2>14.TFO Group is niet aansprakelijk voor:</h2>
          <ul>
            <li>
              Indirecte schade en/of gevolgschade (inclusief maar niet beperkt tot inkomstenderving, verlies van
              goodwill en schade aan eigendommen van de Gebruiker veroorzaakt door de Tool, App en Diensten). Deze
              beperking van aansprakelijkheid geldt ook wanneer TFO Group specifiek door de Gebruiker op de hoogte is
              gesteld van de mogelijke schade;
            </li>
            <li>
              Gebreken die rechtstreeks of onrechtstreeks zijn veroorzaakt door een handeling van de Gebruiker of van
              een derde, ongeacht of zij het gevolg zijn van een fout of van nalatigheid;
            </li>
            <li>
              Schade veroorzaakt door het gebruik van de Tool, App en Diensten voor een ander doel dan waarvoor het is
              ontwikkeld of is bedoeld door TFO Group;
            </li>
            <li>
              Bijkomende schade veroorzaakt door het voortgezette gebruik door de Gebruiker, Beheerder en/of Gebruikers
              nadat een defect is vastgesteld;
            </li>
            <li>
              Schade veroorzaakt door het niet naleven van eventuele adviezen en/of richtlijnen die door de TFO Group
              worden gegeven, welke de laatste altijd op discretionaire basis verstrekt;
            </li>
          </ul>
          <p>
            Verder aanvaardt de Gebruiker dat TFO Group geen garantie biedt dat de Tool, App en Diensten voldoen aan de
            voorschriften of eisen die gelden op enig rechtsgebied, met uitzondering van de voorschriften of eisen die
            in Ierland gelden op het moment van het sluiten van de overeenkomst. TFO Group kan derhalve niet
            aansprakelijk worden gesteld voor eventuele latere wijzigingen van welke aard dan ook in die wet- en/of
            regelgeving.
          </p>
          <p>
            De Gebruiker vrijwaart en/of stelt TFO Group en/of een door TFO Group gevrijwaarde schadeloos van en tegen
            alle claims van welke aard dan ook die zouden kunnen voortvloeien uit het bestaan, de implementatie, de
            niet-naleving en/of de beëindiging van deze servicevoorwaarden en die zijn veroorzaakt door zijn eigen
            nalatigheid, fout of onvoorzichtigheid of door een Beheerder en/of een van zijn Gebruikers.
          </p>
          <h2>15.Toepasselijk recht en bevoegde rechter</h2>
          <p>
            Alle kwesties, vragen en geschillen in verband met de geldigheid, interpretatie, handhaving, uitvoering of
            beëindiging van deze overeenkomst worden beheerst door en geïnterpreteerd volgens het Ierse recht.
          </p>
          <h2>16.Gegevensbescherming</h2>
          <p>TFO Group is een Gegevensverwerker en de Beheerder is de Gegevensbeheerder. </p>
          <ol>
            <li>
              De partijen erkennen en komen overeen dat, om TFO Group in staat te stellen de Diensten te verlenen:
              <ol type="a">
                <li>
                  De gebruiker verstrekt gegevens aan TFO Group die vallen onder de betekenis van 'persoonsgegevens'
                  zoals gedefinieerd in de Algemene Verordening Gegevensbescherming 2016/679; en
                </li>
                <li>TFO Groep de Persoonsgegevens van de Gebruiker namens de Gebruiker moet verwerken; en</li>
                <li>
                  TFO Group is een gegevensverwerker (zoals gedefinieerd in de Algemene Verordening Gegevensbescherming
                  2016/679) en
                </li>
                <li>
                  De Beheerder is de verantwoordelijke voor de verwerking van de gegevens (zoals gedefinieerd in de
                  Algemene Verordening Gegevensbescherming 2016/679).
                </li>
              </ol>
            </li>
            <li>
              TFO Group zal de Persoonsgegevens van de Gebruiker uitsluitend verwerken en gebruiken voor het verlenen
              van diensten en in overeenstemming met de bepalingen van deze Overeenkomst en samen met alle redelijke
              instructies van de Gebruiker.
            </li>
            <li>
              TFO Group verklaart dat zij, alleen voor zover noodzakelijk en alleen met de noodzakelijke middelen die
              nodig zijn om de Diensten uit te voeren, de Persoonsgegevens van de Gebruiker zal verwerken.
            </li>
            <li>
              TFO Group zal niet, behalve met voorafgaande schriftelijke toestemming van Gebruiker of Beheerder
              Gegevensbeheerder:
              <ol type="a">
                <li>
                  de Persoonlijke Gegevens van de Gebruiker overdragen of verwerken buiten de Europese Economische
                  Ruimte (EER);
                </li>
                <li>
                  geen van diens verplichtingen met betrekking tot of in verband met de Persoonlijke Gegevens van de
                  Gebruiker uitbesteden.
                </li>
              </ol>
            </li>
            <li>
              TFO Group zal de technische en organisatorische middelen en maatregelen inzetten die nodig zijn om te
              voldoen aan diens verplichtingen op grond van de Algemene Verordening Gegevensbescherming 2016/679 en om
              onrechtmatig of ongeoorloofd gebruik of verwerking van de Persoonsgegevens van de Gebruiker te voorkomen
              of tegen onopzettelijk verlies of vernietiging van, of beschadiging van, de persoonsgegevens. Onverminderd
              de algemeenheid van de voorgaande zin, zal TFO Group ook voldoen aan alle redelijke instructies die de
              Gebruiker van tijd tot tijd zal geven.
            </li>
          </ol>
          <h2>17.Overeenkomst in zijn geheel</h2>
          <ol>
            <li>
              Deze overeenkomst vormt de volledige overeenkomst tussen de partijen en vervangt en annuleert alle eerdere
              overeenkomsten, beloften, toezeggingen, garanties, verklaringen en afspraken tussen hen, schriftelijk dan
              wel mondeling, met betrekking tot het onderwerp ervan.
            </li>
            <li>
              EElke partij stemt ermee in dat zij geen rechtsmiddelen heeft ten aanzien van enige verklaring,
              toezegging, verzekering of garantie (ongeacht of deze onschuldig of uit onachtzaamheid is gedaan) die niet
              in deze overeenkomst is opgenomen.
            </li>
            <li>
              Elke Partij stemt ermee in dat zij geen vordering heeft wegens onschuldige of nalatige onjuiste
              voorstelling van zaken of nalatige onjuiste verklaring op basis van enige verklaring in deze Overeenkomst.
            </li>
          </ol>
          <h2>18.Overmacht</h2>
          <ol>
            <li>
              Gebeurtenis van overmacht betekent elke omstandigheid waarover de TFO Group redelijkerwijs geen controle
              heeft, met inbegrip van, maar niet beperkt tot:
              <ol type="a">
                <li>overmacht, overstroming, droogte, aardbeving of andere natuurrampen;</li>
                <li>epidemie of pandemie;</li>
                <li>
                  terroristische aanslag, burgeroorlog, onlusten of rellen, oorlog, oorlogsdreiging of voorbereiding van
                  oorlog, gewapend conflict, oplegging van sancties, embargo, of verbreking van diplomatieke
                  betrekkingen;
                </li>
                <li>nucleaire, chemische of biologische besmetting of een sonische dreun;</li>
                <li>
                  een wet of een maatregel van een regering of overheidsinstantie, met inbegrip van maar niet beperkt
                  tot het opleggen van een uitvoer- of invoerbeperking, -contingent of -verbod, of het niet verlenen van
                  een vereiste vergunning of toestemming;
                </li>
                <li>instorting van gebouwen, brand, explosie of ongeval; en</li>
                <li>arbeids- of handelsgeschillen, stakingen, vakbondsacties of uitsluitingen</li>
                <li>onderbreking of uitval van de dienst van de TFO Group.</li>
              </ol>
            </li>
            <li>
              De desbetreffende verplichtingen van DataByte worden opgeschort en de termijn voor nakoming van deze
              verplichtingen wordt verlengd.
            </li>
            <li>
              3. TFO Group zal:
              <ol type="a">
                <li>
                  zo snel als redelijkerwijs mogelijk is na het begin van de Overmacht-situatie, maar niet later dan 72
                  uur na het begin ervan, de andere Gebruiker op de hoogte stellen van de Overmacht-situatie, de datum
                  waarop deze is begonnen, de waarschijnlijke of potentiële duur ervan, en het effect van de
                  Overmacht-situatie op zijn vermogen om een van zijn verplichtingen onder de overeenkomst na te komen;
                  en
                </li>
                <li>
                  al het mogelijke te doen om het effect van de overmacht-situatie op de nakoming van haar
                  verplichtingen te beperken.
                </li>
              </ol>
            </li>
          </ol>
          <h2>19.Scheidbaarheid </h2>
          <p>
            Indien een bepaling of een deel van een bepaling van deze Overeenkomst ongeldig, onwettig of
            niet-afdwingbaar is of wordt, wordt deze geacht te zijn geschrapt, maar dit zal geen invloed hebben op de
            geldigheid en afdwingbaarheid van de rest van deze Overeenkomst.
          </p>
          <h2>20.Taal</h2>
          <p>
            Tenzij uitdrukkelijk anders overeengekomen, erkent de Gebruiker dat de taal van deze voorwaarden ook de
            werktaal zal zijn in alle commerciële transacties met de TFO Group.
          </p>
          <p>
            De oorspronkelijke taal van deze voorwaarden is het Engels. Vertalingen of documenten die in een andere taal
            zijn opgesteld, zullen te allen tijde worden beschouwd als een bonus voor de Gebruiker. In geval van
            tegenstrijdigheid is de Engelse versie steeds doorslaggevend.
          </p>
          <h2>21.Locatie</h2>
          <p>
            Houd er rekening mee dat het inschakelen van Locatieservices vereist is om deze app te gebruiken, omdat dit
            fraude tijdens het aanmeldingsproces helpt voorkomen. Deze functie wordt uitsluitend gebruikt voor het
            verifiëren van de nauwkeurigheid van gegevens. U kunt er zeker van zijn dat uw GPS-gegevens en locatie niet
            worden gevolgd, opgeslagen of gecontroleerd buiten het bereik van deze verificatie.
          </p>
        </div>
      )}
    </div>
  );
};

Conditions.propTypes = {
  language: PropTypes.string,
  user: PropTypes.object
};

Conditions.defaultProps = {
  language: '',
  user: {}
};

export default Conditions;
