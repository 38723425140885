import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { MODAL_SIZES } from 'Constants';
import { Button, Modal } from 'Components';

const updateUserDocumentsAgreement = ({ updateUser, user, updateTransientProps }) => {
  updateUser({ data: { attributes: { document_confirmation: !user.document_confirmation } } });
  updateTransientProps({ legal_rights_modal: false });
};

const DocumentsLegalRightsModal = ({
  legal_rights_modal = false,
  updateTransientProps = mockFunction,
  updateUser = mockFunction,
  user = {}
}) => (
  <Modal
    id={'documents'}
    open={legal_rights_modal}
    size={MODAL_SIZES.SMALL}
    onClose={updateTransientProps.bind(null, { legal_rights_modal: false })}>
    <Modal.Title>
      <div className="text-md font-bold">{I18n.t('profile:documentsTab.documentsAgreementTitle')}</div>
    </Modal.Title>

    <Modal.Content contentPosition={'center'}>
      <div className="text-sm font-light">{I18n.t('profile:documentsTab.documentsAgreement')}</div>
    </Modal.Content>

    <Modal.Actions>
      <Button.OutlineSmall onClick={updateTransientProps.bind(null, { legal_rights_modal: false })}>
        {I18n.t('general:cancel')}
      </Button.OutlineSmall>
      <Button.PrimarySmall
        onClick={updateUserDocumentsAgreement.bind(null, { updateUser, user, updateTransientProps })}>
        {I18n.t('profile:documentsTab.consent')}
      </Button.PrimarySmall>
    </Modal.Actions>
  </Modal>
);

DocumentsLegalRightsModal.propTypes = {
  legal_rights_modal: PropTypes.bool,
  updateTransientProps: PropTypes.func,
  updateUser: PropTypes.func,
  user: PropTypes.object
};

export default DocumentsLegalRightsModal;
