import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'framework7-react';

import { I18n } from 'Locales';
import { TextField } from 'Components';
import { ColumnView } from 'Containers';
import {
  handleOnChangeWithValidations,
  mockFunction,
  onFieldChange,
  phoneNumberSanitization,
  validations
} from 'Helpers';

const SendWelcomeMessage = ({
  transient = {},
  updateTransientProps = mockFunction,
  updateTransientPropWithValidations = mockFunction
}) => {
  const {
    custom_footer = '',
    email = '',
    email_error_message = '',
    email_has_error = false,
    full_name = '',
    phone_number = '',
    phone_number_error_message = '',
    phone_number_has_error = false,
    type = ''
  } = transient;

  const isEmailType = type === 'email';
  const labelKey = isEmailType ? 'admin:onboardingProfile.email' : 'admin:onboardingProfile.phoneNumber';
  const sendingChannel = isEmailType ? I18n.t('admin:sendingChannel.email') : I18n.t('admin:sendingChannel.sms');

  return (
    <>
      <ColumnView>
        <List noHairlines className="w-full pt-4">
          <TextField
            label={I18n.t(labelKey)}
            onChange={handleOnChangeWithValidations.bind(
              null,
              updateTransientPropWithValidations,
              type,
              isEmailType
                ? [validations.isRequired, validations.emailValidation]
                : [validations.isRequired, validations.phoneNumber]
            )}
            onInput={!isEmailType && phoneNumberSanitization}
            errorMessageForce={isEmailType ? email_has_error : phone_number_has_error}
            errorMessage={isEmailType ? email_error_message : phone_number_error_message}
            type={isEmailType ? 'text' : 'number'}
            value={isEmailType ? email : phone_number}
          />
        </List>

        <div className="text-container pb-16">
          <List noHairlines className="w-full">
            <TextField
              label={I18n.t('admin:welcomeEmailModal.customMessage')}
              onTextEditorChange={onFieldChange.bind(null, updateTransientProps, 'custom_footer')}
              outline={true}
              type="texteditor"
              value={custom_footer}
              textEditorParams={{
                buttons: []
              }}
            />
          </List>
        </div>
      </ColumnView>

      <div className="footer-container">
        <div className="text-sm pr-4">
          {I18n.t('admin:welcomeEmailModal.sendWelcomeMessage', { type: sendingChannel, selectedRows: 1 })}
        </div>
        <div className="text-sm font-bold mr-4">{full_name}</div>
      </div>
    </>
  );
};

SendWelcomeMessage.propTypes = {
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func
};

export default SendWelcomeMessage;
