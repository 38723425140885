import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { isSmallDevice, mockFunction } from 'Helpers';
import { QuickFilters, SimpleTable } from 'Components';
import { FILTERS, FONT_SIZE, PAGE_NAMES, TEXT_ALIGN } from 'Constants';

const columns = [
  {
    name: I18n.t('admin:dailyFiguresTableHeaders.signDate'),
    field: 'sign_date',
    itemSize: FONT_SIZE.SMALL,
    headerAlign: TEXT_ALIGN.START
  },
  {
    name: I18n.t('admin:dailyFiguresTableHeaders.client'),
    field: 'client_name',
    itemSize: FONT_SIZE.SMALL,
    headerAlign: TEXT_ALIGN.START
  },
  {
    name: I18n.t('admin:dailyFiguresTableHeaders.teamNumber'),
    field: 'mc_pin',
    itemSize: FONT_SIZE.SMALL,
    itemAlign: TEXT_ALIGN.CENTER,
    headerAlign: TEXT_ALIGN.START
  },
  {
    name: I18n.t('admin:dailyFiguresTableHeaders.sales'),
    field: 'sales',
    itemSize: FONT_SIZE.SMALL,
    itemAlign: TEXT_ALIGN.CENTER
  },
  {
    name: I18n.t('admin:dailyFiguresTableHeaders.baitf'),
    field: 'ba_in_field',
    itemSize: FONT_SIZE.SMALL,
    itemAlign: TEXT_ALIGN.CENTER
  },
  {
    name: I18n.t('admin:dailyFiguresTableHeaders.scoring'),
    field: 'scoring',
    itemSize: FONT_SIZE.SMALL,
    itemAlign: TEXT_ALIGN.CENTER
  },
  {
    name: I18n.t('admin:dailyFiguresTableHeaders.ownerSales'),
    field: 'owner_sales',
    itemSize: FONT_SIZE.SMALL,
    itemAlign: TEXT_ALIGN.CENTER
  },
  {
    name: I18n.t('admin:dailyFiguresTableHeaders.weekEnds'),
    field: 'weekends',
    itemSize: FONT_SIZE.SMALL,
    headerAlign: TEXT_ALIGN.START
  }
];

const Charity = ({
  adminFilters = {},
  dailyFigures = {},
  getAdminDailyFigures = mockFunction,
  transient = {},
  updateTransientProps = mockFunction,
  updateUserProps = mockFunction,
  userRole = ''
}) => {
  const { admin = [] } = dailyFigures;
  const { getAdminDailyFiguresLoading } = transient;

  return (
    <>
      <QuickFilters
        filtersType={FILTERS.DAILY_FIGURES}
        fetchTableItems={getAdminDailyFigures}
        quickFilters={adminFilters}
        role={userRole}
        transient={transient}
        updateTransientProps={updateTransientProps}
        updateUserProps={updateUserProps}
        view={PAGE_NAMES.ADMIN_VIEW}
      />

      <SimpleTable
        columns={columns}
        rows={admin}
        maxRowWidth={isSmallDevice ? 'w-120' : 'w-auto'}
        processing={getAdminDailyFiguresLoading}
      />
    </>
  );
};

Charity.propTypes = {
  adminFilters: PropTypes.object,
  dailyFigures: PropTypes.object,
  getAdminDailyFigures: PropTypes.func,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateUserProps: PropTypes.func,
  userRole: PropTypes.string
};

export default Charity;
