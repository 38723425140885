import React from 'react';

const Edit = ({ color = '#FFFFFF', ...props }) => (
  <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="edit-02">
      <path
        id="Icon"
        // eslint-disable-next-line
        d="M15 8.33334L11.6667 5M2.08331 17.9167L4.90362 17.6033C5.24819 17.565 5.42048 17.5459 5.58152 17.4937C5.72439 17.4475 5.86035 17.3821 5.98572 17.2995C6.12702 17.2063 6.2496 17.0837 6.49475 16.8386L17.5 5.83334C18.4205 4.91286 18.4205 3.42048 17.5 2.5C16.5795 1.57953 15.0871 1.57953 14.1667 2.5L3.16142 13.5052C2.91627 13.7504 2.79369 13.873 2.70051 14.0143C2.61784 14.1396 2.55249 14.2756 2.50624 14.4185C2.45411 14.5795 2.43496 14.7518 2.39668 15.0964L2.08331 17.9167Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Edit;
