import { connect } from 'react-redux';

import { Creators as userActions } from 'Reducers/user';
import { Creators as GrowlActions } from 'Reducers/growl';
import { Creators as transientActions } from 'Reducers/transient';
import { Creators as dailyFiguresActions } from 'Reducers/adminDailyFigures';

import BAsView from './view';

export default connect(
  state => ({
    ...state.adminOnboarding,
    adminDailyFigures: state.adminDailyFigures,
    adminFilters: state.user.adminFilters,
    dailyFigures: state.adminDailyFigures.dailyFigures,
    application: state.application,
    transient: state.transient,
    userRole: state.user.role_name
  }),
  {
    getAdminDailyFigures: dailyFiguresActions.getAdminDailyFigures,
    manualCheckIn: dailyFiguresActions.manualCheckIn,
    manualCheckInCommercial: dailyFiguresActions.manualCheckInCommercial,
    resetTransient: transientActions.reset,
    updateDailyFiguresProps: dailyFiguresActions.updateProps,
    updateGrowlProps: GrowlActions.updateProps,
    updateSales: dailyFiguresActions.updateSales,
    updateTransientProps: transientActions.updateProps,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations,
    updateUserProps: userActions.updateProps
  }
)(BAsView);
