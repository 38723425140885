import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import { call, delay, put, select } from 'redux-saga/effects';

import { Api } from 'Core';
import Config from 'Config';
import { I18n } from 'Locales';
import { User } from 'Repositories';
import * as Selectors from 'Selectors';
import { ServiceWorker } from 'Offline';
import { defaultRoutes } from 'Core/routes';
import { Types as StateTypes } from 'ReduxStore/rootReducer';
import { Types as CheckInTypes } from 'Reducers/checkIn';
import { NavigationService, PwaService } from 'Services';
import { ADMIN_ROLES, APP_MODES, PAGE_NAMES } from 'Constants';
import { Types as AdminOnboardingTypes } from 'Reducers/adminOnboarding';
import { Types as FlowTypes, flows as flowsSelector } from 'Reducers/flow';
import { Types as GrowlTypes, growl as growlSelector } from 'Reducers/growl';
import { donorAddresses as donorAddressesSelector } from 'Reducers/postCodes';
import { Types as CampaignTypes, campaigns as campaignsSelector } from 'Reducers/campaign';
import { transient as transientSelector, Types as TransientTypes } from 'Reducers/transient';
import { Types as ResultTypes, offlineResults as offlineResultsSelector } from 'Reducers/result';
import { adminMode, checkSearchParams, clearStorage, defaultMcPin, isUpdateRequired } from 'Helpers';
import { Types as UserTypes, donorFlowId as donorFlowIdSelector, user as userSelector } from 'Reducers/user';
import {
  Types as ApplicationTypes,
  client as clientSelector,
  isOffline as isOfflineSelector,
  locale as localeSelector,
  mode as modeSelector,
  offlineSince as offlineSinceSelector,
  orID as orIDSelector,
  session as sessionSelect,
  token as tokenSelect
} from 'Reducers/application';

import { onOnline } from './onOnline';
import { onOffline } from './onOffline';
import { navigateTo as navigateToExport } from './navigateTo';
import { onServiceWorkerMessage } from './onServiceWorkerMessage';
import { orientationChangeDetected, onOrientationChange as onOrientationChangeExported } from './onOrientationChange';

// EXPORTED
export const apiError = function* ({ error }) {
  switch (error?.response?.status) {
    case 401:
    case 403:
      yield put({ type: ApplicationTypes.SESSION_EXPIRED_LOGOUT });
      break;

    case '0':
    case 'TIMEOUT':
      yield put({
        type: GrowlTypes.ALERT,
        title: I18n.t('growl:error.backend.timeout.title'),
        body: I18n.t('growl:error.backend.timeout.body'),
        kind: 'error'
      });
      break;
  }
};

export const backendLoginError = function* () {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      error: true,
      processing: false,
      password: ''
    }
  });

  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.login.title'),
    body: I18n.t('growl:error.login.body'),
    kind: 'error'
  });

  yield delay(1000);

  yield put({
    type: TransientTypes.UPDATE_PROP,
    key: 'error',
    value: false
  });
};

export const backendLoginSuccessful = function* ({ payload: user }) {
  const growl = yield select(growlSelector);
  const campaigns = yield select(campaignsSelector);
  const offlineResults = yield select(offlineResultsSelector);

  if (growl?.visible) {
    yield put({
      type: GrowlTypes.UPDATE_PROP,
      key: 'visible',
      value: false
    });
  }

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      processing: false
    }
  });

  yield put({
    type: ApplicationTypes.UPDATE_PROPS,
    props: {
      locale: user.locale,
      session: true,
      token: AES.encrypt(user.auth_token, Config.PASSPHRASE).toString()
    }
  });

  yield put({
    type: UserTypes.SET_USER,
    payload: user
  });

  yield put({
    type: ApplicationTypes.SAVE_USER_PUBLIC_INFO,
    email: user.email,
    avatar: user.avatar_url
  });

  if (isUpdateRequired(user.agent_app_version, Config.APP_VERSION)) {
    yield put({
      type: UserTypes.UPDATE_PROP,
      key: 'version_update_required',
      value: true
    });

    NavigationService.navigate({
      name: 'VersionUpdate'
    });
  } else {
    yield put({
      type: CheckInTypes.GET
    });

    yield put({
      type: UserTypes.GET_ONBOARDING_PROFILES,
      id: user.id
    });

    yield put({ type: ResultTypes.GET });

    yield put({ type: UserTypes.GET_COUNTRIES });

    if (offlineResults?.length > 0) {
      onOnline();
    }

    if (adminMode(user)) {
      yield put({
        type: ApplicationTypes.UPDATE_PROP,
        key: 'mode',
        value: APP_MODES.ADMIN
      });
    }

    if (user.needs_password_change) {
      NavigationService.navigate({
        name: PAGE_NAMES.PASSWORD_UPDATE
      });
    } else {
      NavigationService.navigate({
        name: defaultRoutes.session.name
      });
    }

    if (user.role_name === ADMIN_ROLES.MC_ADMIN || user.role_name === ADMIN_ROLES.OWNER) {
      const pin = defaultMcPin(user.mc_pins, user.mc_ids, user.managed_mc_id);

      yield put({
        type: AdminOnboardingTypes.GET_MC_PIN,
        data: pin
      });

      yield put({
        type: AdminOnboardingTypes.UPDATE_PROPS,
        props: {
          selectedMcPin: pin
        }
      });
    }
  }
  yield put({
    type: CampaignTypes.SET_SELECTED_CAMPAIGN_FILTER_ID,
    userId: user?.id,
    payload: campaigns[user?.id]?.selectedCampaign
  });
};

export const brokenOrUnauthorizedAccess = function () {
  NavigationService.navigate({
    name: 'BrokenOrUnauthorized'
  });
};

export const checkDonorLoginCompleted = function* () {
  const flows = yield select(flowsSelector);
  const donorAddresses = yield select(donorAddressesSelector);
  const donorFlowId = yield select(donorFlowIdSelector);
  const flow = flows[donorFlowId];

  if (isUpdateRequired(flow.agent_app_version, Config.APP_VERSION)) {
    NavigationService.navigate({
      name: 'VersionUpdate'
    });
  } else if (flows && flow && (donorAddresses?.length > 0 || ['DE', 'IE', 'UK'].includes(flow.country))) {
    yield put({ type: ApplicationTypes.DONOR_LOGIN_COMPLETED, flowId: donorFlowId });
  }
};

export const checkOnline = function* () {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ApplicationTypes.USER_IS_ONLINE }
    },
    promise: User.getExecutionToken()
  });
};

export const clearSession = function* ({ isDonor }) {
  const user = yield select(userSelector);
  Api.auth_token();

  //save lastKnownUserId and lastKnownUserEmail to be able to sumbit offline sales if token expires
  yield put({
    type: ApplicationTypes.SAVE_USER_PUBLIC_INFO,
    id: user.id,
    email: user.email
  });

  if (!isDonor) {
    clearStorage();
  }

  yield put({ type: StateTypes.RESET });

  if (!isDonor) {
    // we need to add delay in order to make sure clearStorage is finished before we reload
    yield delay(100);
    window.location.reload();
  }
};

export const closeSplashScreen = function* () {
  yield delay(300);
  yield put({
    type: ApplicationTypes.UPDATE_PROP,
    key: 'initialised',
    value: true
  });
};

export const donorLogin = function* ({ badge_number, orID }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      enableDonorLogin: false,
      processing: true
    }
  });

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ApplicationTypes.DONOR_LOGIN_SUCCESSFUL },
      fail: { type: ApplicationTypes.DONOR_LOGIN_ERROR }
    },
    promise: User.donorLogin(badge_number, orID)
  });
};

export const donorLoginCompleted = function* ({ flowId }) {
  yield put({
    type: ApplicationTypes.UPDATE_PROPS,
    props: {
      session: true
    }
  });
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      processing: false
    }
  });

  NavigationService.navigate({
    name: 'Flow',
    flowId
  });
};

export const donorLoginError = function* ({ error }) {
  const client = yield select(clientSelector);
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      error: true,
      processing: false,
      badge_number: ''
    }
  });

  if (error?.response?.status === 401) {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.donorLoginExpired.title'),
      body: I18n.t('growl:error.donorLoginExpired.body'),
      client,
      kind: 'error'
    });
  } else {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.donorLogin.title'),
      body: I18n.t('growl:error.donorLogin.body'),
      client,
      kind: 'error'
    });
  }

  yield delay(1000);

  yield put({
    type: TransientTypes.UPDATE_PROP,
    key: 'error',
    value: false
  });
};

export const donorLoginSuccessful = function* ({ payload: user }) {
  // set user and proceed to get flow
  yield put({
    type: ApplicationTypes.UPDATE_PROPS,
    props: {
      token: AES.encrypt(user.auth_token, Config.PASSPHRASE).toString()
    }
  });

  yield put({
    type: UserTypes.SET_USER,
    payload: user
  });

  yield put({
    type: FlowTypes.GET_DONOR_FLOW,
    id: user.published_flow_id
  });
};

export const initFrameworkSeven = () => {
  NavigationService.init();
  NavigationService.listenEvent({ view: 'main', eventName: 'routeChanged' });
  if ('serviceWorker' in navigator) {
    ServiceWorker.listen(onServiceWorkerMessage);
  }
  window.addEventListener('offline', onOffline);
  window.addEventListener('online', onOnline);
  window.addEventListener('orientationchange', orientationChangeDetected);
  orientationChangeDetected();
  PwaService.init();
};

export const login = function* ({ email, password }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      enableAgentLogin: false,
      processing: true
    }
  });

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ApplicationTypes.BACKEND_LOGIN_SUCCESSFUL },
      fail: { type: ApplicationTypes.BACKEND_LOGIN_ERROR }
    },
    promise: User.login(email, password)
  });
};

export const logout = function* () {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ApplicationTypes.CLEAR_SESSION },
      fail: { type: ApplicationTypes.CLEAR_SESSION }
    },
    promise: Api.delete('https://staging.databyte.live/v2/sessions/')
  });
};

export const navigateTo = navigateToExport;

export const onOrientationChange = onOrientationChangeExported;

export const postConfirmationToken = function* ({ confirmation_token }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ApplicationTypes.POST_CONFIRMATION_TOKEN_SUCCESS },
      fail: { type: ApplicationTypes.POST_CONFIRMATION_TOKEN_FAIL }
    },
    promise: User.postConfirmationToken({ confirmation_token })
  });
};

export const postConfirmationTokenSuccess = function* ({ payload }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      email: payload.email,
      status: payload.status
    }
  });
  if (payload.status === 'activated') {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.accountActivated.title'),
      body: I18n.t('growl:error.accountActivated.body'),
      kind: 'error'
    });
    yield delay(1000);
    window.location.replace('/');
  }
};

export const postConfirmationTokenFail = function* () {
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.activationToken.title'),
    body: I18n.t('growl:error.activationToken.body'),
    kind: 'error'
  });
};

export const activateAccount = function* ({ session }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      processing: true
    }
  });

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ApplicationTypes.ACTIVATE_ACCOUNT_SUCCESS },
      fail: { type: ApplicationTypes.ACTIVATE_ACCOUNT_FAIL }
    },
    promise: User.activateAccount(session)
  });
};

export const activateAccountFail = function* () {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      processing: false
    }
  });

  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.activationAcount.title'),
    body: I18n.t('growl:error.activationAcount.body'),
    kind: 'error'
  });
};

export const activateAccountSuccess = function* ({ payload }) {
  const locale = yield select(localeSelector);
  const transient = yield select(transientSelector);

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      processing: false,
      email: payload.email,
      password: payload.password
    }
  });

  yield put({
    type: ApplicationTypes.UPDATE_PROPS,
    props: {
      session: true,
      token: AES.encrypt(payload.auth_token, Config.PASSPHRASE).toString()
    }
  });

  yield put({
    type: UserTypes.SET_USER,
    payload
  });

  yield put({
    type: UserTypes.UPDATE_API_MODEL,
    props: {
      data: {
        attributes: {
          locale: locale
        }
      }
    }
  });

  yield put({
    type: ApplicationTypes.SAVE_USER_PUBLIC_INFO,
    email: payload.email
  });

  yield put({
    type: UserTypes.UPDATE_PROP,
    key: 'tc_accepted',
    value: transient.tc_accepted
  });

  NavigationService.navigate({
    name: 'Carousel'
  });
};

export const persisted = function* () {
  const currentLocalTime = Date.now();
  const donorFlowId = yield select(donorFlowIdSelector);
  const isOffline = yield select(isOfflineSelector);
  const mode = yield select(modeSelector);
  const offlineSince = yield select(offlineSinceSelector);
  let session = yield select(sessionSelect);
  const currentORID = yield select(orIDSelector);
  let token = yield select(tokenSelect);
  const user = yield select(userSelector);

  const { ba, client, confirmation_token, country, orID, primaryLogo, resetPasswordToken } = checkSearchParams(
    window.location.search
  );
  let { isDonor } = checkSearchParams(window.location.search);

  if (window.location.origin.includes('donor')) {
    isDonor = true;
  }

  if (country) {
    yield put({
      type: ApplicationTypes.UPDATE_PROPS,
      props: {
        country: country.toLowerCase()
      }
    });
  }

  if (isOffline && offlineSince && Math.abs(currentLocalTime - offlineSince) > Config.LOGOUT_THRESHOLD) {
    yield put({ type: ApplicationTypes.SECURITY_LOGOUT });
  } else {
    if (confirmation_token) {
      NavigationService.navigate({
        action: yield put({
          type: ApplicationTypes.CLOSE_SPLASH_SCREEN
        }),
        name: 'SignUp',
        confirmation_token
      });
      yield put({ type: ApplicationTypes.POST_CONFIRMATION_TOKEN, confirmation_token });
    } else {
      if (resetPasswordToken) {
        NavigationService.navigate({
          action: yield put({
            type: ApplicationTypes.CLOSE_SPLASH_SCREEN
          }),
          name: 'ResetPassword',
          resetPasswordToken
        });
      } else {
        if (isDonor && !(orID && client && ba)) {
          yield put({
            type: ApplicationTypes.BROKEN_OR_UNAUTHORIZED_ACCESS,
            action: yield put({
              type: ApplicationTypes.CLOSE_SPLASH_SCREEN
            })
          });
        } else {
          if (isDonor && currentORID !== orID) {
            yield call(clearSession, { isDonor });
            session = null;
            token = null;
          }

          let targetPage;

          if (user.version_update_required) {
            targetPage = PAGE_NAMES.VERSION_UPDATE;
          } else if (user.needs_password_change) {
            targetPage = PAGE_NAMES.PASSWORD_UPDATE;
          } else if (session) {
            targetPage = mode === APP_MODES.DONOR ? defaultRoutes.donorSession.name : defaultRoutes.session.name;
          } else {
            targetPage = defaultRoutes.noSession.name;
          }

          NavigationService.navigate({
            action: yield put({
              type: ApplicationTypes.CLOSE_SPLASH_SCREEN
            }),
            name: targetPage,
            ba,
            client,
            flowId: donorFlowId,
            isDonor,
            orID,
            primaryLogo
          });

          if (!user.tc_accepted) {
            yield put({
              type: TransientTypes.UPDATE_PROP,
              key: 'tc_accepted',
              value: user.tc_accepted
            });
          }
        }
        if (session && token) {
          Api.auth_token(AES.decrypt(token, Config.PASSPHRASE).toString(Utf8));
        }
      }
    }
  }
};

export const securityLogout = function* () {
  yield put({
    type: ApplicationTypes.CLOSE_SPLASH_SCREEN
  });

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ApplicationTypes.CLEAR_SESSION },
      fail: { type: ApplicationTypes.CLEAR_SESSION }
    },
    promise: Api.delete('https://staging.databyte.live/v2/sessions/')
  });
};

export const sessionExpiredLogout = function* () {
  yield delay(parseInt(Config.GROWL_AUTOHIDE) / 10); // required otherwise F7 router will not navigate
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.backend.session.title'),
    body: I18n.t('growl:error.backend.session.body'),
    kind: 'error'
  });
  yield put({
    type: ApplicationTypes.CLOSE_SPLASH_SCREEN
  });
  yield put({
    type: ApplicationTypes.CLEAR_SESSION
  });
};

export const tryExecutionToken = function* (offlineFlow, userId) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.SUBMIT_OFFLINE_FLOW, offlineFlow, userId }
    },
    promise: User.getExecutionToken()
  });
};

export const userIsOnline = function* ({ displayGrowl = false }) {
  const offlineResults = yield select(offlineResultsSelector);
  const selectedCampaign = yield select(Selectors.selectedOfflineCampaign);

  yield put({
    type: ApplicationTypes.UPDATE_PROPS,
    props: {
      isOffline: false,
      offlineSince: null
    }
  });

  yield put({
    type: UserTypes.GET_ATTACHMENTS
  });

  yield put({
    type: UserTypes.UPDATE_API_MODEL,
    props: { data: { relationships: { campaign: { data: { type: 'campaign', id: selectedCampaign.id } } } } },
    displayGrowl
  });

  if (offlineResults?.length > 0) {
    yield put({ type: ResultTypes.GET, callback: 'checkOfflineFlows' });
  }
};
