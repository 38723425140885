import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Exit } from 'Icons';
import { ColumnView, RowView } from 'Containers';

import { ModalContext } from '../Context';

const Title = ({ children = undefined, closeButton = false }) => {
  const { onClose, closeByBackdropClick } = useContext(ModalContext);

  return (
    <ColumnView>
      {closeButton && !closeByBackdropClick && (
        <RowView justifyContent="flex-end" onClick={onClose.bind(null, false)}>
          <Exit />
        </RowView>
      )}

      <ColumnView gap={16} paddingBottom={children ? 16 : 0}>
        {children}
      </ColumnView>
    </ColumnView>
  );
};

Title.propTypes = {
  children: PropTypes.any,
  closeButton: PropTypes.bool
};

export default Title;
