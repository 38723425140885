import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { Page as F7Page, Link, Toolbar as ToolbarF7 } from 'framework7-react';

import { I18n } from 'Locales';
import { F7, NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { StateActive, StateDisabled } from 'Icons';
import { AppHeader, Banner, Button } from 'Components';
import { ALERT_TYPES, APP_MODES, PAGE_NAMES } from 'Constants';

import './style.scss';
import { Page, Summary } from './subviews';

const availablePagesFilter = (availablePages, page) => availablePages.includes(page.id);

const handleExit = ({ donorMode, logout, pageName, updateFlowProps }) => {
  const title = I18n.t('flow:confirmExitFlow');
  let onClick = () => {
    NavigationService.navigate({ name: pageName });
    updateFlowProps({ currentPageIndex: 0, validatedPages: [] });
  };

  if (donorMode) onClick = logout;

  F7.dialog
    .create({
      title,
      buttons: [{ text: I18n.t('general:cancel') }, { text: I18n.t('general:ok'), onClick }]
    })
    .open();
};

const Flow = props => {
  const {
    availablePages,
    currentCheckInAdress,
    currentFlowBadgeNumber,
    currentFlowData,
    currentPage,
    currentPageIndex,
    flow_signature,
    flow,
    flowCountry,
    getUKAddress,
    initSubmitFlow,
    invalidateComponent,
    isOffline,
    logout,
    mode,
    name,
    nextFlowPage,
    pageIsInvalidOnNext,
    pages,
    processing,
    referenced_products,
    selectedCampaignPublishedFlowId,
    signature,
    transient,
    updateAgreement,
    updateCurrentFlowData,
    updateFlowProps,
    updateSignature,
    updateTransientProps,
    updateTransientPropWithValidations,
    user,
    userAvatar,
    userFullName,
    validateComponent,
    validatedPages,
    validateOnlineBankAccount,
    validBadgeNumber
  } = props;

  const donorMode = mode === APP_MODES.DONOR;
  const agentMode = mode === APP_MODES.AGENT;
  const forceSendToDonor = flow?.flows[selectedCampaignPublishedFlowId]?.force_send_to_donor;
  const allFlowIsValid = JSON.stringify(availablePages.sort()) === JSON.stringify(validatedPages.sort());
  const [currentSection, setCurrentSection] = useState('');
  const sectionRefs = useRef(new Map());
  const prevDisplayablePagesRef = useRef([]);

  useEffect(() => {
    updateFlowProps({ currentPageIndex: 0, validatedPages: [] });
  }, []);

  const createObserver = () => {
    // Adjust the threshold based on screen height to be more responsive with different screen sizes
    const threshold = window.innerHeight < 800 ? 0.3 : 0.6;
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // update the current section state when a new section becomes visible
            if (entry.intersectionRatio >= 0.1) {
              setCurrentSection(entry.target.getAttribute('data-section-id'));
            }
          }
        });
      },
      { threshold: threshold }
    );

    sectionRefs.current.forEach(ref => {
      if (ref && ref instanceof Element) {
        observer.observe(ref);
      }
    });

    return () => {
      sectionRefs.current.forEach(ref => {
        if (ref && ref instanceof Element) {
          observer.unobserve(ref);
        }
      });
    };
  };

  useEffect(() => {
    createObserver();
  }, [currentPageIndex]);

  useEffect(() => {
    const observerCleanup = createObserver();
    return () => {
      observerCleanup();
    };
  }, [validatedPages]);

  const renderBanner = ({ agentMode = false, forceSendToDonor = false, isOffline = false, user = {} }) => {
    if (!forceSendToDonor && !isOffline) return;

    let bannerTitle = '';
    let content = '';

    if (forceSendToDonor && isOffline && agentMode) {
      bannerTitle = I18n.t('general:forceSendToDonorBannerInFlow:title');
      content = I18n.t('general:forceSendToDonorBannerInFlow:description');
    }
    if (user.test_mode) {
      bannerTitle = I18n.t('settings:testMode');
      content = I18n.t('settings:testModeContent');
    }
    if (!bannerTitle || !content) return;

    return <Banner type={ALERT_TYPES.INFO} title={bannerTitle} content={content} />;
  };

  const navigateToTop = () => {
    updateFlowProps({ currentPage: pages[0], currentPageIndex: 0, pageIsInvalidOnNext: false });
    document.querySelector('.page-current > .page-content').scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentSection(pages[0].id);
  };

  const navigateToPage = direction => {
    if (!allFlowIsValid) {
      updateFlowProps({ currentPageIndex: validatedPages?.length });
    } else {
      updateFlowProps({ currentPageIndex: pages?.length });
    }

    if (direction === 'next') {
      nextFlowPage();
    } else if (direction === 'top') {
      navigateToTop();
    }
  };

  const renderFlowNavButtons = () => {
    const totalPages = pages.length + 1;
    const isLastPage = currentPageIndex === totalPages;

    return (
      <RowView gap={24} paddingHorizontal={20} paddingTop={20} paddingBottom={16}>
        <Button.Outline disabled={processing} width={'100%'} onClick={navigateToTop.bind(null)}>
          {I18n.t('general:back')}
        </Button.Outline>
        {!isLastPage && (
          <Button.Primary width={'100%'} onClick={navigateToPage.bind(null, 'next')}>
            {I18n.t('general:next')}
          </Button.Primary>
        )}
      </RowView>
    );
  };

  const findFirstNonValidatedPage = () => {
    const sortedAvailablePages = pages.filter(page => availablePages.includes(page.id));
    return sortedAvailablePages.find(page => !validatedPages.includes(page.id));
  };

  // display validated pages up to and including the first non-validated page
  const displayablePages = () => {
    const displayPages = [];
    const sortedAvailablePages = pages.filter(page => availablePages.includes(page.id));
    const firstNonValidatedPage = findFirstNonValidatedPage();

    for (const page of sortedAvailablePages) {
      displayPages.push(page);

      if (page.id === firstNonValidatedPage?.id) {
        break;
      }
    }

    return displayPages;
  };

  // to not invalidate the page on next if the page is not displayed
  useEffect(() => {
    const newDisplayablePages = displayablePages();
    const prevDisplayablePages = prevDisplayablePagesRef.current;

    const hasChanged =
      newDisplayablePages.length !== prevDisplayablePages.length ||
      newDisplayablePages.some((page, index) => page.id !== prevDisplayablePages[index]?.id);

    if (hasChanged) {
      updateFlowProps({ pageIsInvalidOnNext: false });
      prevDisplayablePagesRef.current = newDisplayablePages;
    }
  }, [validatedPages, availablePages, pages]);

  return (
    <F7Page id={'flow'}>
      <AppHeader
        user
        goBack
        title={name}
        hideAvatar={donorMode}
        handleClick={handleExit.bind(null, { donorMode, logout, pageName: PAGE_NAMES.CAMPAIGNS, updateFlowProps })}
        onProfileRedirect={handleExit.bind(null, { donorMode, logout, pageName: PAGE_NAMES.PROFILE, updateFlowProps })}
        banner={renderBanner({ agentMode, forceSendToDonor, isOffline, user })}>
        <ToolbarF7 className="navbar-style" position={'top'} tabbar scrollable noShadow noHairLine>
          {pages.filter(availablePagesFilter.bind(null, availablePages)).map(page => (
            <div key={page.id} width={'auto'} className={'tab-style'}>
              <ColumnView>
                <img src={validatedPages.includes(page.id) ? StateActive : StateDisabled} alt="Status Icon" />
                <Link
                  tabLink={currentPage?.id}
                  tabLinkActive={currentSection === page.id && pages.length + 1 !== currentPageIndex}>
                  <span
                    className={`tab-label ${
                      page?.id === currentSection && pages.length + 1 !== currentPageIndex && 'active'
                    }`}>
                    {page.name}
                  </span>
                </Link>
              </ColumnView>
            </div>
          ))}
          <div key="summary" width={'auto'} className={'tab-style'}>
            <ColumnView>
              <img src={signature?.agreement ? StateActive : StateDisabled} alt="Summary Icon" />
              <Link tabLink="summary" tabLinkActive={pages.length + 1 === currentPageIndex}>
                <span className={`tab-label ${pages.length + 1 === currentPageIndex && 'active'}`}>
                  {I18n.t('flow:pages.summary')}
                </span>
              </Link>
            </ColumnView>
          </div>
        </ToolbarF7>
      </AppHeader>
      {name && (
        <div className="flow-pages">
          {pages.length + 1 !== currentPageIndex &&
            displayablePages().map(page => (
              <div
                className={`scroll-${page.id} ${currentSection === page.id ? 'current-section' : ''}`}
                key={page.id}
                ref={el => sectionRefs.current.set(page.id, el)}
                data-section-id={page.id}>
                <div className="page-to-scroll">
                  <Page
                    key={page.id}
                    {...page}
                    currentCheckInAdress={currentCheckInAdress}
                    currentFlowData={currentFlowData}
                    currentPageIndex={currentPageIndex}
                    flowCountry={flowCountry}
                    getUKAddress={getUKAddress}
                    invalidateComponent={invalidateComponent}
                    isOffline={isOffline}
                    mode={mode}
                    pageIsInvalidOnNext={pageIsInvalidOnNext}
                    referenced_products={referenced_products}
                    transient={transient}
                    updateCurrentFlowData={updateCurrentFlowData}
                    updateTransientProps={updateTransientProps}
                    userFullName={userFullName}
                    validateComponent={validateComponent}
                    validateOnlineBankAccount={validateOnlineBankAccount}
                  />
                </div>
              </div>
            ))}
          {pages.length + 1 === currentPageIndex && (
            <Summary
              availablePages={availablePages}
              currentFlowBadgeNumber={currentFlowBadgeNumber}
              currentFlowData={currentFlowData}
              currentPageIndex={currentPageIndex}
              flow_signature={flow_signature}
              flow={flow}
              initSubmitFlow={initSubmitFlow}
              isOffline={isOffline}
              mode={mode}
              pages={pages}
              processing={processing}
              referenced_products={referenced_products}
              selectedCampaignPublishedFlowId={selectedCampaignPublishedFlowId}
              signatureValues={signature}
              transient={transient}
              updateAgreement={updateAgreement}
              updateSignature={updateSignature}
              updateTransientProps={updateTransientProps}
              updateTransientPropWithValidations={updateTransientPropWithValidations}
              user={user}
              userAvatar={userAvatar}
              userFullName={userFullName}
              validBadgeNumber={validBadgeNumber}
            />
          )}
        </div>
      )}
      {renderFlowNavButtons()}
    </F7Page>
  );
};

Flow.propTypes = {
  availablePages: PropTypes.array,
  components: PropTypes.array,
  currentCheckInAdress: PropTypes.array,
  currentFlowBadgeNumber: PropTypes.string,
  currentFlowData: PropTypes.object,
  currentPage: PropTypes.object,
  currentPageIndex: PropTypes.number,
  flow_signature: PropTypes.object,
  flow: PropTypes.object,
  flowCountry: PropTypes.string,
  getUKAddress: PropTypes.func,
  initSubmitFlow: PropTypes.func,
  invalidateComponent: PropTypes.func,
  isOffline: PropTypes.bool,
  logout: PropTypes.func,
  mode: PropTypes.string,
  name: PropTypes.string,
  nextFlowPage: PropTypes.func,
  pageIsInvalidOnNext: PropTypes.bool,
  pages: PropTypes.array,
  processing: PropTypes.bool,
  referenced_products: PropTypes.array,
  selectedCampaignPublishedFlowId: PropTypes.string,
  signature: PropTypes.object,
  transient: PropTypes.object,
  updateAgreement: PropTypes.func,
  updateCurrentFlowData: PropTypes.func,
  updateFlowProps: PropTypes.func,
  updateSignature: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  user: PropTypes.object,
  userAvatar: PropTypes.string,
  userFullName: PropTypes.string,
  validateComponent: PropTypes.func,
  validatedPages: PropTypes.array,
  validateOnlineBankAccount: PropTypes.func,
  validBadgeNumber: PropTypes.any
};

export default Flow;
