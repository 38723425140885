import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import React, { useEffect, useState } from 'react';

import { mockFunction, stopPropagation } from 'Helpers';

import './style.scss';
import { Actions, Content, ModalContext, Title } from './subviews';

const Modal = ({
  children = undefined,
  closeByBackdropClick = true,
  id = null,
  onClose = mockFunction,
  open = false,
  position = '',
  size = 'large'
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (open) {
      setIsVisible(true);
      setTimeout(() => setIsAnimating(true), 10); // Delay to ensure the class is applied after render
    } else {
      setIsAnimating(false);
      const timer = setTimeout(() => setIsVisible(false), 500);
      return () => clearTimeout(timer);
    }
  }, [open]);

  if (!isVisible) {
    return null;
  }

  return createPortal(
    <div id="custom-modal">
      <div
        className={`modal-container ${isAnimating ? 'open' : ''}`}
        onClick={closeByBackdropClick ? onClose : mockFunction}>
        <div className={`modal-content ${size} ${position} ${isAnimating ? 'open' : ''}`} onClick={stopPropagation}>
          <div id={id} className="content-wrapper">
            <ModalContext.Provider value={{ onClose, closeByBackdropClick }}>{children}</ModalContext.Provider>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  closeByBackdropClick: PropTypes.bool,
  id: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  position: PropTypes.string,
  size: PropTypes.string
};

export default Object.assign(Modal, { Actions, Content, Title });
