import { createActions } from 'reduxsauce';
import { persistCombineReducers } from 'redux-persist';

import { appInitialState } from 'Reducers/application';
import {
  adminDailyFigures,
  adminOnboarding,
  application,
  campaign,
  checkIn,
  flow,
  growl,
  postCodes,
  result,
  transient,
  user
} from 'Reducers';

import { storeConfig } from './config';

export const { Types } = createActions(
  {
    reset: null
  },
  { prefix: 'state/' }
);

const appReducers = persistCombineReducers(storeConfig, {
  adminDailyFigures: adminDailyFigures,
  adminOnboarding: adminOnboarding,
  application: application,
  campaign: campaign,
  checkIn: checkIn,
  flow: flow,
  growl: growl,
  postCodes: postCodes,
  result: result,
  transient: transient,
  user: user
});

const rootReducer = (state, action) => {
  if (action.type === 'state/RESET') {
    state = {
      ...state,
      adminDailyFigures: undefined,
      adminOnboarding: undefined,
      application: {
        ...state.application,
        ...appInitialState
      },
      flow: undefined,
      postCodes: undefined,
      // keep the result state just if offline sales exists
      result: state?.result[state.user.id]?.offline?.length ? { ...state.result } : undefined,
      transient: undefined,
      user: undefined
    };
  }
  return appReducers(state, action);
};

export default rootReducer;
