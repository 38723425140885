import { createActions, createReducer } from 'reduxsauce';

import { ONBOARDING_PROFILE_TABS } from 'Constants';

import { updateProp, updateProps } from '../shared';

export const { Types, Creators } = createActions(
  {
    alterBadges: ['payload'],
    alterBulkActions: ['payload'],
    checkOnboardingProfileVetting: ['id'],
    checkOnboardingProfileVettingSuccess: ['payload'],
    checkSendWelcomeEmail: ['data'],
    checkVetting: ['data'],
    checkVettingSuccess: ['payload'],
    confirmOnboardingProfileVetting: ['id'],
    confirmOnboardingProfileVettingSuccess: ['payload'],
    createUser: ['data'],
    createUserFail: ['error'],
    createUserSuccess: ['payload'],
    declineOnboardingProfile: ['id'],
    declineOnboardingProfileSuccess: ['payload'],
    declineProfiles: ['data'],
    declineProfilesSuccess: ['payload'],
    deleteUserAttachment: ['data'],
    deleteUserAttachmentSuccess: null,
    extendOnboardingProfileVetting: ['id'],
    extendOnboardingProfileVettingSuccess: ['payload'],
    extendVetting: ['data'],
    extendVettingSuccess: ['payload'],
    getAdminOnboardingProfiles: ['data'],
    getAdminOnboardingProfilesFail: ['error'],
    getAdminOnboardingProfilesSuccess: ['payload', 'meta'],
    getCampaignsByClient: ['id'],
    getCampaignsByClientSuccess: ['payload'],
    getClients: null,
    getClientsSuccess: ['payload'],
    getCoach: ['data'],
    getCoachSuccess: ['payload'],
    getMarketingCompanies: null,
    getMarketingCompaniesSuccess: ['payload'],
    getMcPin: ['data'],
    getMcPinSuccess: ['data'],
    getOnboardingBlocks: ['id'],
    getOnboardingBlocksSuccess: ['payload'],
    getRecruiter: ['data'],
    getRecruiterSuccess: ['payload'],
    getReOnboardingCampaigns: ['data'],
    getReOnboardingCampaignsSuccess: ['payload'],
    getSigners: ['mcId'],
    getSignersSuccess: ['payload'],
    getUserOnboardingProfile: ['id', 'callback'],
    getUserOnboardingProfileSuccess: ['payload', 'callback'],
    reactivateOnboardingProfile: ['id'],
    reactivateOnboardingProfileSuccess: ['payload'],
    reactivateProfiles: ['data'],
    reactivateProfilesSuccess: ['payload'],
    rejectOnboardingProfileVetting: ['id'],
    rejectOnboardingProfileVettingSuccess: ['payload'],
    rejectVetting: ['data'],
    rejectVettingSuccess: ['payload'],
    releaseBlock: ['data', 'callback'],
    releaseBlockSuccess: ['payload'],
    releaseBlockFail: ['error'],
    removeAttachment: ['id'],
    removeAttachmentSuccess: null,
    reOnboard: ['data'],
    reOnboardProfile: ['data'],
    reOnboardProfileSuccess: ['payload'],
    reOnboardSuccess: ['payload'],
    reset: null,
    resetOnboardingProfilePages: null,
    selectedProfilesForReOnboard: ['data'],
    sendWelcomeEmail: ['data', 'callback'],
    sendWelcomeEmailFail: ['error'],
    sendWelcomeEmailSuccess: ['rows'],
    sendWelcomeSms: ['data', 'callback'],
    sendWelcomeSmsSuccess: null,
    updateOnboardingProfileDocuments: ['data', 'id'],
    updateOnboardingProfileDocumentsSuccess: ['payload'],
    updateOnboardingUserProfile: ['props', 'id', 'fieldName'],
    updateOnboardingUserProfileFail: ['error'],
    updateOnboardingUserProfileSuccess: ['payload'],
    updateOnboardingUserSection: ['data', 'id', 'callback'],
    updateOnboardingUserSectionError: ['error'],
    updateBadgeNumber: ['data'],
    updateBadgeNumberSuccess: ['payload'],
    updateBadgeNumberError: ['error'],
    updateOnboardingUserSectionSuccess: ['payload'],
    updateProp: ['key', 'value'],
    updateProps: ['props'],
    updateUserDocument: ['data'],
    updateUserDocumentSuccess: ['payload'],
    vetProfile: ['id'],
    vetProfileSuccess: ['payload']
  },
  { prefix: 'adminOnboarding/' }
);

const initialState = {
  selectedMcPin: '',
  currentPage: ONBOARDING_PROFILE_TABS.DETAILS,
  onboardingProfilePages: [
    ONBOARDING_PROFILE_TABS.DETAILS,
    ONBOARDING_PROFILE_TABS.BASIC_PROFILE,
    ONBOARDING_PROFILE_TABS.ACTIVITY
  ]
};

const alterBulkActions = (state, { payload }) => {
  const updatedOnboardings = state.onboardings.payload?.map(existingUser => {
    const updatedUser = payload?.find(newUser => newUser?.id === existingUser?.id);
    return { ...existingUser, ...updatedUser };
  });

  payload?.forEach(newUser => {
    if (!state.onboardings.payload?.some(existingUser => existingUser?.id === newUser?.id)) {
      updatedOnboardings.unshift(newUser);
    }
  });

  return {
    ...state,
    onboardings: {
      ...state.onboardings,
      payload: updatedOnboardings
    }
  };
};

export const alterBadges = (state, { payload }) => {
  const { userOnboardingProfile } = state;
  const badgeIndex = userOnboardingProfile.badges.findIndex(badge => badge.id === payload.id);
  const keysToUpdate = ['number', 'activation_date', 'status'];
  const sanitizedPayload = payload.badges_audit_trails.map(auditTrail => {
    const auditTrailWithoutBadges = { ...auditTrail };
    delete auditTrailWithoutBadges.badge;

    const filteredKeysToUpdate = {};
    keysToUpdate.forEach(key => {
      if (key in auditTrailWithoutBadges) {
        filteredKeysToUpdate[key] = auditTrailWithoutBadges[key];
      }
    });

    return filteredKeysToUpdate;
  });

  if (badgeIndex !== -1) {
    const updatedBadge = {
      ...userOnboardingProfile.badges[badgeIndex],
      ...keysToUpdate.reduce((acc, key) => {
        if (key in payload) acc[key] = payload[key];
        return acc;
      }, {}),
      badges_audit_trails: sanitizedPayload
    };

    const updatedBadges = [
      ...userOnboardingProfile.badges.slice(0, badgeIndex),
      updatedBadge,
      ...userOnboardingProfile.badges.slice(badgeIndex + 1)
    ];

    return {
      ...state,
      userOnboardingProfile: {
        ...userOnboardingProfile,
        badges: updatedBadges
      }
    };
  }

  return state;
};

export const reset = () => initialState;
export const adminOnboarding = state => state.adminOnboarding;
export const blocks = state => state.adminOnboarding.blocks;
export const currentTabs = state => state.adminOnboarding.onboardingProfilePages;
export const onboardingUserProfile = state => state.adminOnboarding.userOnboardingProfile;
export const resetOnboardingProfilePages = state => ({
  ...state,
  onboardingProfilePages: initialState.onboardingProfilePages
});

export default createReducer(initialState, {
  [Types.ALTER_BADGES]: alterBadges,
  [Types.ALTER_BULK_ACTIONS]: alterBulkActions,
  [Types.RESET]: reset,
  [Types.UPDATE_PROP]: updateProp,
  [Types.UPDATE_PROPS]: updateProps,
  [Types.RESET_ONBOARDING_PROFILE_PAGES]: resetOnboardingProfilePages
});
