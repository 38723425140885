import PropTypes from 'prop-types';
import { Page } from 'framework7-react';
import React, { useEffect, useState } from 'react';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { ArrowUp } from 'Icons';
import { mockFunction } from 'Helpers';
import { AppHeader } from 'Components';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { ADMIN_ROLES, PAGE_NAMES } from 'Constants';

import './style.scss';
import SwitchMcPinModal from './SwitchMcPinModal';

const ToggleRowView = ({ isOpen = false, onClick = mockFunction, title = '' }) => (
  <>
    <RowView onClick={onClick}>
      <RowView>
        <div className="section-title pl-20">{title}</div>
      </RowView>
      <ArrowUp className={isOpen ? 'arrow-down' : 'arrow-up'} />
    </RowView>
    <RowView backgroundColor={colors.primary} height={1} marginTop={5} marginBottom={10} />
  </>
);

ToggleRowView.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string
};

const views = [
  {
    title: I18n.t('admin:onboarding'),
    subviews: [{ subviewTitle: I18n.t('admin:dashboard'), subviewPath: PAGE_NAMES.DASHBOARD }]
  },

  {
    title: I18n.t('admin:report'),
    subviews: [{ subviewTitle: I18n.t('admin:dailyFigures'), subviewPath: PAGE_NAMES.DAILY_FIGURES }]
  }
];

const renderSubView = ({ subviewPath = '', subviewTitle = '' }, subviewIndex) => (
  <React.Fragment key={subviewIndex}>
    <RowView
      marginBottom={4}
      onClick={subviewPath?.length ? NavigationService.navigate.bind(null, { name: subviewPath }) : mockFunction}>
      <div className="section-subtitle">{subviewTitle}</div>
    </RowView>
  </React.Fragment>
);

const renderView = ({ setShowSubview = mockFunction, showSubview = '' }, { subviews = [], title = '' }, index) => (
  <React.Fragment key={index}>
    <ToggleRowView
      onClick={setShowSubview.bind(null, showSubview === title ? '' : title)}
      isOpen={showSubview === title}
      title={title}
    />

    {showSubview === title && subviews?.map(renderSubView)}
  </React.Fragment>
);

const Admin = ({
  adminOnboarding = {},
  getMcPin = mockFunction,
  transient = {},
  updateAdminProps = mockFunction,
  updateTransientProps = mockFunction,
  user = {}
}) => {
  const [showSubview, setShowSubview] = useState('');

  useEffect(() => {
    if (adminOnboarding?.selectedMcPin !== '') {
      updateTransientProps({ selectedMcPin: adminOnboarding.selectedMcPin });
    }
  }, []);

  const { mcPinsModal = false } = transient;
  const isNotAdmin = user?.role_name !== ADMIN_ROLES.ADMIN;

  return (
    <Page id={'admin'}>
      <AppHeader
        hideAvatar
        showMcPins={isNotAdmin}
        onClickMcPin={user?.mc_pins?.length > 1 ? updateTransientProps.bind(null, { mcPinsModal: true }) : mockFunction}
        title={I18n.t('general:admin')}
      />
      <ColumnView minHeight={'calc(100% - 74px)'} justifyContent="space-between" paddingHorizontal={20}>
        <div className="w-full mt-32">{views?.map(renderView.bind(null, { setShowSubview, showSubview }))}</div>
      </ColumnView>

      <SwitchMcPinModal
        admin={adminOnboarding}
        getMcPin={getMcPin}
        mcPinsModal={mcPinsModal}
        transient={transient}
        updateAdminProps={updateAdminProps}
        updateTransientProps={updateTransientProps}
        user={user}
      />
    </Page>
  );
};

Admin.propTypes = {
  adminOnboarding: PropTypes.object,
  getMcPin: PropTypes.func,
  transient: PropTypes.object,
  updateAdminProps: PropTypes.func,
  updateTransientProps: PropTypes.func,
  user: PropTypes.object
};

export default Admin;
