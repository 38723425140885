import moment from 'moment';

import { Api } from 'Core';
import Config from 'Config';
import { DATE_FORMAT, TABLE_CONFIG } from 'Constants';

const getAdminDailyFigures = (data = {}) => {
  const {
    campaign_id = '',
    dailyFiguresDate_start_date = '',
    dailyFiguresDate_end_date = '',
    mc_pins = '',
    page = TABLE_CONFIG.PAGE,
    per_page = TABLE_CONFIG.ROWS,
    sortColumn = 'sales',
    sortDirection = 'desc',
    client_id = '',
    filterText = '',
    daily_figure_type = data.dailyFigureType,
    filterNumber = '',
    by_view = data.dailyFigureView
  } = data;

  const yesterday = moment().subtract(1, 'days').format(DATE_FORMAT.UI);
  const start_date = dailyFiguresDate_start_date
    ? moment(dailyFiguresDate_start_date).format(DATE_FORMAT.UI)
    : yesterday;
  const end_date = dailyFiguresDate_end_date ? moment(dailyFiguresDate_end_date).format(DATE_FORMAT.UI) : yesterday;

  return Api.get(
    `${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/daily_figures/summary?` +
      `by_view=${by_view}&` +
      `page=${page}&` +
      `per_page=${per_page}&` +
      `campaign_id=${campaign_id}&` +
      `start_date=${start_date}&` +
      `end_date=${end_date}&` +
      `mc_pins=${mc_pins}&` +
      `sortColumn=${sortColumn}&` +
      `sortDirection=${sortDirection}&` +
      `client_id=${client_id}&` +
      `filterText=${filterText}&` +
      `daily_figure_type=${daily_figure_type}&` +
      `filterNumber=${filterNumber}`
  );
};

const manualCheckIn = data => Api.post(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/check_ins/bulk_update`, data);

const manualCheckInCommercial = data =>
  Api.post(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/daily_figures/bulk_update`, data);

const updateSales = data =>
  Api.post(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/daily_figures/update_sale`, data);

const getCampaignsByClientType = type =>
  Api.get(
    `${Config.API_SERVER()}${
      Config.API_V3_PATH_PREFIX
    }/campaigns/light_index?sortColumn=name&sortDirection=asc&by_client_type=${type}`
  );

const getClientsByClientType = type =>
  Api.get(
    `${Config.API_SERVER()}${
      Config.API_V3_PATH_PREFIX
    }/clients/light_index?sortColumn=name&sortDirection=asc&by_client_type=${type}`
  );

export default {
  getAdminDailyFigures,
  getCampaignsByClientType,
  getClientsByClientType,
  manualCheckIn,
  manualCheckInCommercial,
  updateSales
};
