import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { RowView } from 'Containers';
import { mockFunction } from 'Helpers';
import { MODAL_SIZES } from 'Constants';
import { Button, Modal } from 'Components';

import './style.scss';
import { ConfirmVetting, Reonboard, SendWelcomeMessage } from './subviews';

const handleSendWelcomeMessage = ({
  custom_footer = '',
  email = '',
  getUserOnboardingProfile = mockFunction,
  phone_number = '',
  profileId = '',
  sendWelcomeEmail = mockFunction,
  sendWelcomeSms = mockFunction,
  type = '',
  updateTransientProps = mockFunction
}) => {
  updateTransientProps({
    custom_footer: '',
    showSendWelcomeMessageModal: false
  });

  if (type === 'email') {
    sendWelcomeEmail({ custom_footer, email, profileId }, getUserOnboardingProfile.bind(null, profileId));
  } else {
    const decodedMessage = new DOMParser().parseFromString(custom_footer, 'text/html').body.textContent;
    sendWelcomeSms(
      { custom_footer: decodedMessage, phone_number, profileId },
      getUserOnboardingProfile.bind(null, profileId)
    );
  }
};

const SimpleModal = ({
  confirmOnboardingProfileVetting = mockFunction,
  getReOnboardingCampaigns = mockFunction,
  getUserOnboardingProfile = mockFunction,
  reOnboardProfile = mockFunction,
  sendWelcomeEmail = mockFunction,
  sendWelcomeSms = mockFunction,
  transient = {},
  updateTransientProps = mockFunction,
  updateTransientPropWithValidations = mockFunction
}) => {
  const {
    custom_footer = '',
    email = '',
    email_has_error = false,
    phone_number = '',
    phone_number_has_error = false,
    reOnboardLoading = false,
    reuse_existing_badge_number = true,
    selectedCampaigns = [],
    selected_client = '',
    selected_signer = '',
    showConfirmVettingModal = false,
    showProfileReonboardModal = false,
    showSendWelcomeMessageModal = false,
    type = '',
    userOnboardingProfile = {}
  } = transient;
  const profileId = userOnboardingProfile.id;
  let modalTitle = '';
  const isEmailType = type === 'email';
  const titleKey = isEmailType ? 'admin:actions.sendWelcomeEmail' : 'admin:actions.sendWelcomeSms';

  if (showSendWelcomeMessageModal) modalTitle = I18n.t(titleKey);
  if (showProfileReonboardModal) modalTitle = I18n.t('admin:reOnboardModal.reOnboardBA');
  if (showConfirmVettingModal) modalTitle = I18n.t('admin:actions.confirmVetting');

  return (
    <Modal
      id={'modal'}
      open={showSendWelcomeMessageModal || showProfileReonboardModal || showConfirmVettingModal}
      size={showConfirmVettingModal ? MODAL_SIZES.SMALL : MODAL_SIZES.MEDIUM}
      onClose={updateTransientProps.bind(null, {
        custom_footer: '',
        selectedCampaigns: [],
        reOnboardLoading: false,
        selected_client: '',
        selected_signer: '',
        email_has_error: transient.email_has_error,
        phone_number_has_error: transient.phone_number_has_error,
        showSendWelcomeMessageModal: false
      })}>
      <Modal.Title>
        <RowView>
          <div className="title-md font-bold">{modalTitle}</div>
        </RowView>
      </Modal.Title>

      <Modal.Content>
        {showSendWelcomeMessageModal && (
          <SendWelcomeMessage
            transient={transient}
            updateTransientProps={updateTransientProps}
            updateTransientPropWithValidations={updateTransientPropWithValidations}
          />
        )}

        {showProfileReonboardModal && (
          <Reonboard
            transient={transient}
            updateTransientProps={updateTransientProps}
            getReOnboardingCampaigns={getReOnboardingCampaigns}
          />
        )}

        {showConfirmVettingModal && <ConfirmVetting transient={transient} />}
      </Modal.Content>

      <Modal.Actions>
        {(showSendWelcomeMessageModal || showConfirmVettingModal || showProfileReonboardModal) && (
          <Button.OutlineSmall
            onClick={updateTransientProps.bind(null, {
              email_has_error: transient.email_has_error,
              phone_number_has_error: transient.phone_number_has_error,
              showConfirmVettingModal: false,
              selectedCampaigns: [],
              reOnboardLoading: false,
              selected_client: '',
              selected_signer: '',
              showProfileReonboardModal: false,
              showSendWelcomeMessageModal: false
            })}>
            {I18n.t('general:cancel')}
          </Button.OutlineSmall>
        )}

        <Button.PrimarySmall
          onClick={
            showSendWelcomeMessageModal
              ? handleSendWelcomeMessage.bind(null, {
                  custom_footer,
                  email,
                  getUserOnboardingProfile,
                  phone_number,
                  profileId,
                  sendWelcomeEmail,
                  sendWelcomeSms,
                  type,
                  updateTransientProps
                })
              : showProfileReonboardModal
              ? reOnboardProfile.bind(null, {
                  reuse_existing_badge_number,
                  selectedCampaigns,
                  selected_client,
                  selected_signer,
                  selectedRowsIds: profileId
                })
              : confirmOnboardingProfileVetting.bind(null, profileId)
          }
          processing={reOnboardLoading}
          disabled={
            showProfileReonboardModal
              ? !selectedCampaigns?.length || reOnboardLoading
              : showSendWelcomeMessageModal
              ? email_has_error || phone_number_has_error
              : false
          }>
          {showSendWelcomeMessageModal ? I18n.t('general:send') : I18n.t('general:ok')}
        </Button.PrimarySmall>
      </Modal.Actions>
    </Modal>
  );
};

SimpleModal.propTypes = {
  confirmOnboardingProfileVetting: PropTypes.func,
  getReOnboardingCampaigns: PropTypes.func,
  getUserOnboardingProfile: PropTypes.func,
  reOnboardProfile: PropTypes.func,
  sendWelcomeEmail: PropTypes.func,
  sendWelcomeSms: PropTypes.func,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func
};

export default SimpleModal;
