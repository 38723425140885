import moment from 'moment';
import PropTypes from 'prop-types';
import frLocale from 'moment/locale/fr';
import nlLocale from 'moment/locale/nl';
import { BlockTitle } from 'framework7-react';
import React, { useEffect, useRef, useState } from 'react';

import {
  AlertCircle,
  DocumentIncomplete,
  PDFCompleted,
  PDFIncomplete,
  PDFPending,
  PendingEye,
  Reupload,
  StateActive,
  TrashCan
} from 'Icons';
import { F7 } from 'Services';
import { I18n } from 'Locales';
import { ColumnView, RowView } from 'Containers';
import { Bubble, Button, Card } from 'Components';
import { isExtraSmallDevice, mockFunction } from 'Helpers';
import { DOCUMENT_TYPES, ICON_COLOR, NEEDS_LEURKAART, SIGNING_TYPES, TABS } from 'Constants';

import './style.scss';
import { DocumentsLegalRightsModal } from '../Modals';

const handleFileUpload = (uploadAttachments, id, e) => {
  const { files } = e.target;
  if (!files) return;
  uploadAttachments({ file: files[0], id });
};

const onButtonClick = ref => ref && ref?.click();

const handleRef = (files, id, el) => (files.current[id] = el);

const viewDocument = url => {
  window.open(url);
};

const readDocument = (readAttachment, attachment) => {
  window.open(attachment.url);
  readAttachment(attachment);
};

const deleteFile = (deleteAttachment, attachment) => {
  const title = I18n.t('profile:documentsTab.deleteAlert');

  F7.dialog
    .create({
      title,
      buttons: [
        { text: I18n.t('general:cancel') },
        { text: I18n.t('general:ok'), onClick: deleteAttachment.bind(null, attachment) }
      ]
    })
    .open();
};

const getAttachmentInfo = attachment => {
  const isDocumentTypeImage = attachment.file_content_type?.includes('image');
  const incompleteDocumentType = attachment.attachable.signing_type === SIGNING_TYPES.UPLOAD;
  const versionNumber = attachment.attachable?.version_number ? ` - v${attachment.attachable?.version_number}` : '';

  return { incompleteDocumentType, isDocumentTypeImage, versionNumber };
};

const handleAction = ({ mainAction, setButtonActive }) => {
  mainAction();
  setButtonActive(null);
};

const handleIncompleteClick = ({ documentActionType, updateTransientProps, user }) => {
  if (user.document_confirmation) {
    documentActionType();
  } else {
    updateTransientProps({ legal_rights_modal: true });
  }
};

const handlePendingClick = ({ user, updateTransientProps, attachment, buttonActive, setButtonActive }) => {
  if (user.document_confirmation) {
    if (buttonActive === attachment.id) {
      setButtonActive(null);
    } else {
      setButtonActive(attachment.id);
    }
  } else {
    updateTransientProps({ legal_rights_modal: true });
  }
};

const renderIncompleteDocument = (
  {
    files = {},
    isOffline = false,
    loadingUploadAttachment = '',
    readAttachment = mockFunction,
    updateTransientProps = mockFunction,
    uploadAttachments = mockFunction,
    user = {}
  },
  attachment
) => {
  moment.locale(user?.locale, [frLocale, nlLocale]);

  const isTimeLeashed = attachment.attachable?.document_type === DOCUMENT_TYPES.TIME_LEASHED;
  const mandatoryHeader =
    attachment.attachable?.document_type === DOCUMENT_TYPES.MANDATORY ||
    attachment.attachable?.document_type === DOCUMENT_TYPES.MC_ADMIN_TIME_LEASHED
      ? I18n.t('profile:documentsTab.mandatory')
      : '';
  const documentTypeMessage =
    attachment.document?.signing_type === SIGNING_TYPES.UPLOAD
      ? I18n.t('profile:documentsTab.upload')
      : attachment.document?.signing_type === SIGNING_TYPES.SIGN
      ? I18n.t('profile:documentsTab.sign')
      : I18n.t('profile:documentsTab.read');
  const documentActionType =
    attachment.document?.signing_type === SIGNING_TYPES.UPLOAD
      ? () => onButtonClick(files.current[attachment.id])
      : attachment.document?.signing_type === SIGNING_TYPES.SIGN
      ? viewDocument.bind(null, attachment.agent_sign_url)
      : readDocument.bind(null, readAttachment, attachment);
  const documentButtonType =
    attachment.document?.signing_type === SIGNING_TYPES.UPLOAD
      ? I18n.t('profile:documentsTab.documentsButtons:uploadButton')
      : attachment.document?.signing_type === SIGNING_TYPES.SIGN
      ? I18n.t('profile:documentsTab.documentsButtons:signButton')
      : I18n.t('profile:documentsTab.documentsButtons:viewButton');
  const expirationDate =
    attachment.attachable?.expiration_date &&
    isTimeLeashed &&
    I18n.t('profile:documentsTab.expirationDate', {
      date: moment(Date.parse(attachment.attachable?.expiration_date)).fromNow()
    });
  const isMcAdminTimeLeashed = attachment.attachable?.document_type === DOCUMENT_TYPES.MC_ADMIN_TIME_LEASHED;
  const { incompleteDocumentType, versionNumber } = getAttachmentInfo(attachment);
  const isHeaderPresent =
    attachment.attachable?.expiration_date ||
    attachment.attachable?.document_type === DOCUMENT_TYPES.MANDATORY ||
    attachment.attachable?.document_type === DOCUMENT_TYPES.MC_ADMIN_TIME_LEASHED;
  const documentHaveDescription = attachment.document?.description;

  return (
    <RowView key={attachment.id} paddingHorizontal={20} paddingVertical={12}>
      <RowView height={80} width={80}>
        <img slot="media" className="document-img" src={incompleteDocumentType ? DocumentIncomplete : PDFIncomplete} />
      </RowView>
      <ColumnView paddingLeft={20} paddingRight={4} alignItems={'flex-start'} height={80}>
        {isHeaderPresent && (
          <div className="text-xxs text-fail pb-4">
            {attachment.attachable?.expiration_date && !isMcAdminTimeLeashed && isTimeLeashed
              ? expirationDate
              : mandatoryHeader}
          </div>
        )}
        <div className={`text-sm font-medium line-height-normal pb-4 ${isExtraSmallDevice ? 'truncate-text' : ''}`}>
          {`${attachment.document?.title}${versionNumber}`}
        </div>
        {documentHaveDescription && (
          <div className={`text-xxs ${isExtraSmallDevice ? 'truncate-text' : ''} pb-4`}>
            {attachment.document?.description}
          </div>
        )}
        <div className="flex">
          <div className="flex align-center justify-center">
            <AlertCircle fillColor={attachment.attachable?.rejection_reason ? ICON_COLOR.RED : ICON_COLOR.BLUE} />
          </div>
          <div className="text-xxs text-primary flex align-center pl-4 pt-2">
            {attachment.attachable?.rejection_reason
              ? I18n.t(`profile:documentsTab.rejectionReason.${attachment.attachable?.rejection_reason}`)
              : documentTypeMessage}
          </div>
        </div>
      </ColumnView>
      <ColumnView width={'auto'}>
        <Button.OutlineSmall
          processing={loadingUploadAttachment === attachment.id}
          width={'85px'}
          disabled={isOffline}
          className="documents-button line-height-normal h-36"
          onClick={handleIncompleteClick.bind(null, {
            documentActionType,
            updateTransientProps,
            user
          })}>
          {documentButtonType}
        </Button.OutlineSmall>
      </ColumnView>
      <input
        type="file"
        ref={handleRef.bind(null, files, attachment.id)}
        className="display-none"
        onChange={handleFileUpload.bind(null, uploadAttachments, attachment.id)}
        slot={'fixed'}
        //eslint-disable-next-line
        accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
    </RowView>
  );
};

const renderPendingDocument = (
  {
    buttonActive,
    deleteAttachment = mockFunction,
    files = {},
    isOffline = false,
    loadingUploadAttachment = '',
    setButtonActive,
    updateTransientProps = mockFunction,
    uploadAttachments = mockFunction,
    user = {}
  },
  attachment = {}
) => {
  const { isDocumentTypeImage, versionNumber } = getAttachmentInfo(attachment);
  const documentHaveDescription = attachment.document?.description;

  const items = [
    {
      onClick: handleAction.bind(null, {
        mainAction: onButtonClick.bind(null, files.current[attachment.id]),
        setButtonActive
      }),
      label: I18n.t('profile:documentsTab.documentsButtons:reuploadButton'),
      icon: Reupload
    },
    {
      onClick: handleAction.bind(null, {
        mainAction: deleteFile.bind(null, deleteAttachment, attachment),
        setButtonActive
      }),
      label: I18n.t('profile:documentsTab.documentsButtons:deleteButton'),
      icon: TrashCan
    },
    {
      onClick: handleAction.bind(null, {
        mainAction: viewDocument.bind(null, attachment?.url),
        setButtonActive
      }),
      label: I18n.t('profile:documentsTab.documentsButtons:viewButton'),
      icon: PendingEye
    }
  ];

  return (
    <RowView key={attachment.id} paddingHorizontal={20} paddingVertical={12}>
      <RowView height={80} width={80}>
        <img slot="media" className="document-img" src={isDocumentTypeImage ? attachment.url : PDFPending} />
      </RowView>
      <ColumnView paddingLeft={20} paddingRight={4} alignItems={'flex-start'} height={80}>
        <div className={`text-sm line-height-normal font-medium pb-4 ${isExtraSmallDevice ? 'truncate-text' : ''}`}>
          {`${attachment.document.title}${versionNumber}`}
        </div>
        {documentHaveDescription && (
          <div className={`text-xxs ${isExtraSmallDevice ? 'truncate-text' : ''} pb-4`}>
            {attachment.document?.description}
          </div>
        )}
        <div className="flex">
          <PendingEye fillColor={ICON_COLOR.BLUE} />
          <div className="text-xxs text-primary flex align-center pl-4 pt-2">
            {I18n.t('profile:documentsTab.underReview')}
          </div>
        </div>
      </ColumnView>

      <ColumnView
        width={'auto'}
        onClick={handlePendingClick.bind(null, {
          user,
          updateTransientProps,
          attachment,
          buttonActive,
          setButtonActive
        })}>
        {!attachment.agent_sign_url && (
          <Bubble
            loading={loadingUploadAttachment === attachment.id}
            position={'top'}
            width={'85px'}
            disabled={isOffline}
            items={items}
            label={I18n.t('profile:documentsTab.documentsButtons:actionsButton')}
          />
        )}
      </ColumnView>
      <input
        type="file"
        ref={handleRef.bind(null, files, attachment.id)}
        className="display-none"
        onChange={handleFileUpload.bind(null, uploadAttachments, attachment.id)}
        slot={'fixed'}
        //eslint-disable-next-line
        accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
    </RowView>
  );
};

const renderCompletedDocument = (attachment = {}) => {
  const documentState =
    attachment.attachable.signing_type === SIGNING_TYPES.SIGN
      ? `${I18n.t('profile:documentsTab.signedOn')} ${attachment.attachable?.applicant_signed_on}` +
        ' | ' +
        `${attachment.attachable?.admin_signed_on} (${I18n.t('profile:documentsTab.admin')})`
      : attachment.attachable?.applicant_read_on
      ? I18n.t('profile:documentsTab.viewedAt', {
          updated_at: moment(attachment.attachable?.applicant_read_on).format('MMM Do YYYY')
        })
      : I18n.t('profile:documentsTab.acceptedOn', {
          accepted_on: moment(attachment.attachable?.accepted_on).format('MMM Do YYYY')
        });

  const { isDocumentTypeImage, versionNumber } = getAttachmentInfo(attachment);
  const documentHaveDescription = attachment.document?.description;

  return (
    <RowView key={attachment.id} paddingHorizontal={20} paddingVertical={12}>
      <RowView height={80} width={80}>
        <img slot="media" className="document-img" src={isDocumentTypeImage ? attachment.url : PDFCompleted} />
      </RowView>
      <ColumnView paddingLeft={20} paddingRight={4} alignItems={'flex-start'} height={80}>
        <div className={`text-sm line-height-normal font-medium pb-4 ${isExtraSmallDevice ? 'truncate-text' : ''}`}>
          {`${attachment.document.title}${versionNumber}`}
        </div>
        {documentHaveDescription && (
          <div className={`text-xxs ${isExtraSmallDevice ? 'truncate-text' : ''} pb-4`}>
            {attachment.document?.description}
          </div>
        )}
        <div className="flex">
          <img className="h-20 w-20" src={StateActive} />
          <div className="text-xxs text-primary flex align-center pl-4 pt-2">{documentState}</div>
        </div>
      </ColumnView>
      <ColumnView width={'auto'}>
        <Button.OutlineSmall
          width={'85px'}
          className="documents-button line-height-normal h-36"
          onClick={viewDocument.bind(null, attachment?.url)}>
          {I18n.t('profile:documentsTab.documentsButtons:viewButton')}
        </Button.OutlineSmall>
      </ColumnView>
    </RowView>
  );
};

const reshapeCampaign = ({ getAttachments, setCampaignFilter }, campaign) => ({
  ...campaign,
  label: campaign.name,
  onClick: () => {
    setCampaignFilter(campaign.name);
    getAttachments(campaign.id);
  }
});

const DocumentsTab = ({
  campaigns = [],
  currentPage = '',
  deleteAttachment = mockFunction,
  filteredCampaignName = '',
  getAttachments = mockFunction,
  isOffline = false,
  legal_rights_modal = false,
  loadingUploadAttachment = '',
  readAttachment = mockFunction,
  selectedCampaign = {},
  selectedCampaignFilterId = '',
  updateTransientProps = mockFunction,
  updateUser = mockFunction,
  uploadAttachments = mockFunction,
  user = {}
}) => {
  const files = useRef([]);
  const uniqueCampaigns = campaigns?.filter(
    (campaign, index, self) => self?.findIndex(c => c?.id === campaign?.id) === index
  );
  const campaignName = filteredCampaignName
    ? filteredCampaignName
    : selectedCampaign?.name
    ? selectedCampaign?.name
    : uniqueCampaigns[0]?.name;
  const [buttonActive, setButtonActive] = useState(false);
  const [campaignFilter, setCampaignFilter] = useState(campaignName);
  const { complete_documents, incomplete_documents, pending_documents } = user;
  const isLeurkaartNeeded = incomplete_documents?.filter(doc =>
    doc?.document?.regions?.some(region => NEEDS_LEURKAART?.includes(region))
  );
  const hasOnlyOptionalDocuments = incomplete_documents?.every(
    doc => doc?.document?.document_type === DOCUMENT_TYPES.OPTIONAL
  );
  const leurkaartRequired = isLeurkaartNeeded?.length > 0;
  const hasMandatoryDocuments = !hasOnlyOptionalDocuments;

  const campaignsOptions = [...uniqueCampaigns?.map(reshapeCampaign.bind(null, { getAttachments, setCampaignFilter }))];
  const campaignId = selectedCampaignFilterId
    ? selectedCampaignFilterId
    : selectedCampaign?.id
    ? selectedCampaign?.id
    : uniqueCampaigns[0]?.id;

  useEffect(() => {
    if (currentPage === TABS.DOCUMENTS) {
      getAttachments(campaignId);
    }
  }, [currentPage]);

  return (
    <ColumnView id={'documents'}>
      {!!uniqueCampaigns?.length && (
        <ColumnView paddingHorizontal={20} gap={20} marginTop={20}>
          <RowView justifyContent={'space-between'} height={'36px'}>
            <div className="title-md">{I18n.t('reports:campaignFilter')}</div>
            <Bubble items={campaignsOptions} label={campaignFilter} />
          </RowView>
        </ColumnView>
      )}

      {leurkaartRequired && hasMandatoryDocuments && (
        <ColumnView paddingHorizontal={20} gap={20} marginTop={20}>
          <Card description={I18n.t('profile:documentsTab.needsLeurkaart')} />
        </ColumnView>
      )}

      {incomplete_documents?.length ? (
        <>
          <RowView justifyContent={'flex-start'}>
            <BlockTitle className="mb-0 ml-20 mt-16 text-md text-dark-gray">
              {I18n.t('profile:documentsTab.incomplete')}
            </BlockTitle>
          </RowView>
          {incomplete_documents.map(
            renderIncompleteDocument.bind(null, {
              files,
              isOffline,
              loadingUploadAttachment,
              readAttachment,
              updateTransientProps,
              uploadAttachments,
              user
            })
          )}
        </>
      ) : (
        <>
          <RowView justifyContent={'flex-start'}>
            <BlockTitle className="mb-0 ml-20 mt-16 text-md text-dark-gray">
              {I18n.t('profile:documentsTab.incomplete')}
            </BlockTitle>
          </RowView>
          <RowView marginTop={24} marginBottom={8}>
            {I18n.t('profile:documentsTab.emptyIncompleteDocuments')}
          </RowView>
        </>
      )}
      {pending_documents?.length ? (
        <>
          <RowView justifyContent={'flex-start'}>
            <BlockTitle className="mb-0 ml-20 mt-16 text-md text-dark-gray">
              {I18n.t('profile:documentsTab.pending')}
            </BlockTitle>
          </RowView>
          {pending_documents.map(
            renderPendingDocument.bind(null, {
              buttonActive,
              deleteAttachment,
              files,
              isOffline,
              loadingUploadAttachment,
              setButtonActive,
              updateTransientProps,
              uploadAttachments,
              user
            })
          )}
        </>
      ) : (
        <>
          <RowView justifyContent={'flex-start'}>
            <BlockTitle className="mb-0 ml-20 mt-16 text-md text-dark-gray">
              {I18n.t('profile:documentsTab.pending')}
            </BlockTitle>
          </RowView>
          <RowView marginTop={24} marginBottom={8}>
            {I18n.t('profile:documentsTab.emptyPendingDocuments')}
          </RowView>
        </>
      )}
      {complete_documents?.length ? (
        <>
          <RowView justifyContent={'flex-start'}>
            <BlockTitle className="mb-0 ml-20 mt-16 text-md text-dark-gray">
              {I18n.t('profile:documentsTab.completed')}
            </BlockTitle>
          </RowView>
          {complete_documents.map(renderCompletedDocument)}
        </>
      ) : (
        <>
          <RowView justifyContent={'flex-start'}>
            <BlockTitle className="mb-0 ml-20 mt-16 text-md text-dark-gray">
              {I18n.t('profile:documentsTab.completed')}
            </BlockTitle>
          </RowView>
          <RowView marginTop={24} marginBottom={8}>
            {I18n.t('profile:documentsTab.emptyCompleteDocuments')}
          </RowView>
        </>
      )}

      <DocumentsLegalRightsModal
        legal_rights_modal={legal_rights_modal}
        updateTransientProps={updateTransientProps}
        updateUser={updateUser}
        user={user}
      />
    </ColumnView>
  );
};

DocumentsTab.propTypes = {
  campaigns: PropTypes.array,
  currentPage: PropTypes.string,
  deleteAttachment: PropTypes.func,
  filteredCampaignName: PropTypes.string,
  getAttachments: PropTypes.func,
  isOffline: PropTypes.bool,
  legal_rights_modal: PropTypes.bool,
  loadingUploadAttachment: PropTypes.string,
  onClick: PropTypes.func,
  readAttachment: PropTypes.func,
  selectedCampaign: PropTypes.object,
  selectedCampaignFilterId: PropTypes.string,
  updateTransientProps: PropTypes.func,
  updateUser: PropTypes.func,
  uploadAttachments: PropTypes.func,
  user: PropTypes.object
};

export default DocumentsTab;
