import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Checkbox } from 'framework7-react';

import { colors } from 'Theme';
import { I18n } from 'Locales';
import { ArrowUp } from 'Icons';
import { ColumnView, RowView } from 'Containers';
import { mockFunction, stopPropagation } from 'Helpers';
import { ALIGNMENT, FONT_SIZE, TEXT_ALIGN } from 'Constants';

import './style.scss';
import Drawer from './Drawer';
import { transformData } from './utils/helpers';

const handleSorting = ({ setSortConfig, sortConfig, filters, column, updateTransientProps }) => {
  const newSortDirection =
    sortConfig?.sortColumn === column.field && sortConfig?.sortDirection === 'asc' ? 'desc' : 'asc';

  setSortConfig({
    sortColumn: column.field,
    sortDirection: newSortDirection
  });

  updateTransientProps({ sort: { sortColumn: column.field, sortDirection: newSortDirection } });
  column.sortingAction({ sortColumn: column.field, sortDirection: newSortDirection, ...filters });
};

const renderHeader = (
  { filters = {}, setSortConfig, sortConfig, updateTransientProps = mockFunction },
  column,
  index
) => {
  const width = column?.width || 'auto';
  const headerSize = column?.headerSize || FONT_SIZE.SMALL;
  const justifyContent = column?.headerAlign || ALIGNMENT.START;

  return (
    <RowView
      key={index}
      justifyContent={justifyContent}
      width={width}
      {...(column?.sorting && {
        onClick: handleSorting.bind(null, { setSortConfig, sortConfig, filters, column, updateTransientProps })
      })}>
      <div className={`table-header ${headerSize}`}>{column?.name}</div>

      {column?.sorting && (
        <div className="mt-4">
          <ArrowUp
            fillColor={colors.blue}
            className={
              sortConfig?.sortColumn === column.field && sortConfig?.sortDirection === 'asc' ? 'rotate0' : 'rotate180'
            }
          />
        </div>
      )}
    </RowView>
  );
};

const renderItem = ({ row = {}, columns = {}, moreInfo = false }, key = '', index) => {
  // TODO improve renderItem style condition (remove moreInfo and use the same styling for all tables)
  const headerAlign = columns[index]?.headerAlign || ALIGNMENT.CENTER;
  const itemAlign = columns[index]?.itemAlign || TEXT_ALIGN.START;
  const itemSize = columns[index]?.itemSize || FONT_SIZE.MEDIUM;
  const itemAction = columns[index]?.action;
  const itemType = columns[index]?.itemType || 'text';
  const checked = row[key] === true;
  const justifyContent = headerAlign || ALIGNMENT.START;
  const Icon = columns[index]?.icon;
  const item = Array.isArray(row[key]) ? row[key].join(', ') : row[key];

  return (
    <RowView
      key={key}
      justifyContent={justifyContent}
      alignItems={'flex-start'}
      width={columns[index]?.width || 'auto'}
      // TODO apply the same styling for all items
      onClick={itemType === 'edit' ? itemAction.bind(null, row) : mockFunction}>
      <div className={`${moreInfo ? 'item' : 'row-item'} ${itemAlign} ${itemSize}`}>
        {itemType === 'checkbox' ? <Checkbox checked={checked} onChange={itemAction.bind(null, row)} /> : item}
      </div>
      {Icon && (
        <div className="icon-wrapper">
          <Icon className="icon-position h-12 w-12" />
        </div>
      )}
    </RowView>
  );
};

const renderRows = (
  {
    columns = [],
    drawerButtonAction = mockFunction,
    fieldsKeys = [],
    handleRowCheckboxChange = mockFunction,
    handleRowClick = mockFunction,
    moreInfo = true,
    multipleSelection = true,
    selectedRows = [],
    setShowDrawer = mockFunction,
    showDrawer = {}
  },
  row = {},
  index
) => {
  const selectedRow = row.id === showDrawer;
  const isRowSelected = selectedRows?.some(selectedRow => selectedRow.id === row.id);

  return (
    <ColumnView key={index} width={'100%'}>
      <RowView
        paddingVertical={5}
        {...(moreInfo && {
          onClick: e => {
            handleRowClick(row.id, setShowDrawer, showDrawer);
            e.stopPropagation();
          }
        })}>
        <RowView justifyContent={'space-between'} gap={moreInfo ? 16 : 0} paddingHorizontal={8}>
          {!!multipleSelection && (
            <RowView width={'auto'} justifyContent={'flex-start'} onClick={stopPropagation}>
              <Checkbox checked={isRowSelected} onChange={handleRowCheckboxChange.bind(null, row)} />
            </RowView>
          )}
          {fieldsKeys?.map(renderItem.bind(null, { row, columns, moreInfo }))}
        </RowView>

        {moreInfo && (
          <RowView justifyContent={'flex-end'} width={'auto'}>
            <ArrowUp fillColor={colors.blue} className={selectedRow ? 'rotate180' : 'rotate0'} />
          </RowView>
        )}
      </RowView>

      {selectedRow && <Drawer items={row?.more_details} id={row.id} onClick={drawerButtonAction.bind(null, row)} />}

      <RowView backgroundColor={colors.darkGrey} height={1} />
    </ColumnView>
  );
};

const Table = ({
  columns = [],
  drawerButtonAction = mockFunction,
  filters = {},
  moreInfo = false,
  multipleSelection = true,
  processing = false,
  requiresDataTransformation = true,
  rows = [],
  selectedRows = [],
  headerHeight = '40',
  updateTransientProps = mockFunction,
  userRole = ''
}) => {
  const { payload } = rows;
  const data = requiresDataTransformation ? transformData(payload, userRole) : rows;
  const fieldsKeys = (columns || [])?.map(column => column?.field);
  const [showDrawer, setShowDrawer] = useState(null);
  const [sortConfig, setSortConfig] = useState({ sortColumn: '', sortDirection: 'asc' });
  const isAllSelected = data?.length > 0 && selectedRows?.length === data.length;

  const handleRowClick = (rowId, setShowDrawer, showDrawer) => {
    if (moreInfo && requiresDataTransformation) {
      setShowDrawer(rowId === showDrawer ? null : rowId);
    }
  };

  const handleHeaderCheckboxChange = () => {
    if (isAllSelected) {
      updateTransientProps({ selectedRows: [] });
    } else {
      updateTransientProps({ selectedRows: data });
    }
  };

  const handleRowCheckboxChange = row => {
    const newSelectedRows = selectedRows?.some(selectedRow => selectedRow.id === row.id)
      ? selectedRows.filter(selectedRow => selectedRow.id !== row.id)
      : [...(selectedRows || []), row];

    updateTransientProps({ selectedRows: newSelectedRows });
  };

  return (
    <ColumnView id={'table'} width={'100%'} paddingHorizontal={16} paddingTop={16}>
      <RowView
        gap={moreInfo ? 16 : 0}
        justifyContent={'space-between'}
        paddingHorizontal={8}
        // TODO change this logic remove headerHeight
        height={headerHeight !== '40' ? 'auto' : 40}>
        {!!multipleSelection && (
          <RowView width={'auto'} justifyContent={'flex-start'}>
            <Checkbox checked={isAllSelected} onChange={handleHeaderCheckboxChange} />
          </RowView>
        )}

        {columns?.map(renderHeader.bind(null, { filters, setSortConfig, sortConfig, updateTransientProps }))}

        {moreInfo && <RowView width={'auto'} />}
      </RowView>

      <RowView
        backgroundColor={colors.darkGrey}
        height={2}
        //TODO change this logic
        marginTop={headerHeight !== '40' && 4}
        marginBottom={headerHeight !== '40' && 8}
      />

      {processing ? (
        <div className="empty-table">
          <div className="loader" />
        </div>
      ) : (
        <ColumnView>
          {data?.length ? (
            data?.map(
              renderRows.bind(null, {
                columns,
                drawerButtonAction,
                fieldsKeys,
                handleRowCheckboxChange,
                handleRowClick,
                moreInfo,
                multipleSelection,
                selectedRows,
                setShowDrawer,
                showDrawer
              })
            )
          ) : (
            <div className="empty-table">{I18n.t('general:noResultsFound')}</div>
          )}
        </ColumnView>
      )}
    </ColumnView>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  drawerButtonAction: PropTypes.func,
  filters: PropTypes.object,
  headerHeight: PropTypes.string,
  moreInfo: PropTypes.bool,
  multipleSelection: PropTypes.bool,
  processing: PropTypes.bool,
  requiresDataTransformation: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedRows: PropTypes.array,
  updateTransientProps: PropTypes.func,
  userRole: PropTypes.string
};

export default Table;
