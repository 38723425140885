import { connect } from 'react-redux';

import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';
import { Creators as adminActions } from 'Reducers/adminOnboarding';
import { Creators as dailyFiguresAction } from 'Reducers/adminDailyFigures';

import DailyFigures from './view';

export default connect(
  state => ({
    transient: state.transient,
    user: state.user
  }),
  {
    getAdminDailyFigures: dailyFiguresAction.getAdminDailyFigures,
    getCampaignsByClientType: dailyFiguresAction.getCampaignsByClientType,
    getClientsByClientType: dailyFiguresAction.getClientsByClientType,
    getMarketingCompanies: adminActions.getMarketingCompanies,
    updateDailyFiguresProps: dailyFiguresAction.updateProps,
    updateTransientProps: transientActions.updateProps,
    updateUserProps: userActions.updateProps
  }
)(DailyFigures);
