import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { isSmallDevice, mockFunction } from 'Helpers';
import { QuickFilters, SimpleTable } from 'Components';
import { FILTERS, FONT_SIZE, PAGE_NAMES, TEXT_ALIGN } from 'Constants';

const Commercial = ({
  adminDailyFigures = {},
  adminFilters = {},
  getAdminDailyFigures = mockFunction,
  transient = {},
  updateTransientProps = mockFunction,
  updateUserProps = mockFunction,
  userRole = ''
}) => {
  const { dailyFigures = {} } = adminDailyFigures;
  const { admin = [] } = dailyFigures;

  const { getAdminDailyFiguresLoading = false } = transient;

  const columns = [
    {
      name: I18n.t('admin:signDate'),
      field: 'sign_date',
      headerAlign: TEXT_ALIGN.START,
      itemSize: FONT_SIZE.SMALL
    },
    {
      name: I18n.t('admin:client'),
      field: 'client_name',
      itemSize: FONT_SIZE.SMALL,
      headerAlign: TEXT_ALIGN.START
    },
    {
      name: I18n.t('admin:teamNumber'),
      field: 'mc_pin',
      itemSize: FONT_SIZE.SMALL,
      headerAlign: TEXT_ALIGN.CENTER,
      itemAlign: TEXT_ALIGN.CENTER
    },
    {
      name: I18n.t('admin:sales'),
      field: 'sales',
      itemSize: FONT_SIZE.SMALL,
      headerAlign: TEXT_ALIGN.CENTER,
      itemAlign: TEXT_ALIGN.CENTER
    },
    {
      name: I18n.t('admin:baInTheField'),
      field: 'ba_in_field',
      itemSize: FONT_SIZE.SMALL,
      itemAlign: TEXT_ALIGN.CENTER
    },
    {
      name: I18n.t('admin:scoring'),
      field: 'scoring',
      itemSize: FONT_SIZE.SMALL,
      itemAlign: TEXT_ALIGN.CENTER
    },
    {
      name: I18n.t('admin:ownerSales'),
      field: 'owner_sales',
      itemSize: FONT_SIZE.SMALL,
      itemAlign: TEXT_ALIGN.CENTER
    },
    {
      name: I18n.t('admin:weekEnds'),
      field: 'weekends',
      itemSize: FONT_SIZE.SMALL,
      headerAlign: TEXT_ALIGN.START
    }
  ];

  return (
    <>
      <QuickFilters
        filtersType={FILTERS.DAILY_FIGURES}
        fetchTableItems={getAdminDailyFigures}
        quickFilters={adminFilters}
        transient={transient}
        role={userRole}
        updateTransientProps={updateTransientProps}
        updateUserProps={updateUserProps}
        view={PAGE_NAMES.ADMIN_VIEW}
      />

      <SimpleTable
        columns={columns}
        rows={admin}
        maxRowWidth={isSmallDevice ? 'w-120' : 'w-auto'}
        processing={getAdminDailyFiguresLoading}
      />
    </>
  );
};

Commercial.propTypes = {
  adminDailyFigures: PropTypes.object,
  adminFilters: PropTypes.object,
  getAdminDailyFigures: PropTypes.func,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateUserProps: PropTypes.func,
  userRole: PropTypes.string
};

export default Commercial;
