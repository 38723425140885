import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'framework7-react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { ColumnView } from 'Containers';
import { NavigationService } from 'Services';
import { Accordion, AppHeader } from 'Components';
import {
  ADMIN_ROLES,
  CAMPAIGNS_TYPE,
  DAILY_FIGURES_TYPE,
  DAILY_FIGURES_CAMPAIGNS,
  DAILY_FIGURES_TAB_VIEWS,
  PAGE_NAMES,
  DAILY_FIGURES_VIEW
} from 'Constants';

const handleNavigation = ({
  campaignsName = '',
  campaignType = '',
  dailyFigureView = '',
  dailyFigureType = '',
  sortColumn = '',
  getCampaignsByClientType = mockFunction,
  getClientsByClientType = mockFunction,
  getMarketingCompanies = mockFunction,
  pageName,
  updateTransientProps = mockFunction,
  updateDailyFiguresProps = mockFunction,
  view
}) => {
  updateDailyFiguresProps({
    campaignsName,
    view,
    currentTableView: DAILY_FIGURES_TAB_VIEWS.STATUS,
    dailyFigureType,
    dailyFigureView
  });
  updateTransientProps({ sort: { sortColumn } });
  NavigationService.navigate({ name: pageName });
  getCampaignsByClientType(campaignType);
  getClientsByClientType(campaignType);
  getMarketingCompanies();
};

const DailyFigures = ({
  getCampaignsByClientType = mockFunction,
  getClientsByClientType = mockFunction,
  updateTransientProps = mockFunction,
  getMarketingCompanies = mockFunction,
  updateDailyFiguresProps = mockFunction,
  user = {}
}) => {
  const isAdmin = user?.role_name === ADMIN_ROLES.ADMIN;
  const campaigns = [
    {
      name: I18n.t('admin:charityCampaigns'),
      views: [
        {
          name: I18n.t('admin:campaignsViews.basView'),
          onClick: handleNavigation.bind(null, {
            campaignsName: DAILY_FIGURES_CAMPAIGNS.CHARITY_CAMPAIGNS,
            campaignType: CAMPAIGNS_TYPE.FUNDRAISING,
            dailyFigureType: DAILY_FIGURES_TYPE.CHARITY,
            dailyFigureView: DAILY_FIGURES_VIEW.BAS_VIEW,
            sortColumn: 'sales',
            getCampaignsByClientType,
            getMarketingCompanies,
            pageName: PAGE_NAMES.BAS_VIEW,
            updateDailyFiguresProps,
            updateTransientProps,
            view: PAGE_NAMES.BAS_VIEW
          })
        },
        {
          name: I18n.t('admin:campaignsViews.campaignsView'),
          onClick: handleNavigation.bind(null, {
            campaignsName: DAILY_FIGURES_CAMPAIGNS.CHARITY_CAMPAIGNS,
            campaignType: CAMPAIGNS_TYPE.FUNDRAISING,
            dailyFigureType: DAILY_FIGURES_TYPE.CHARITY,
            dailyFigureView: DAILY_FIGURES_VIEW.CAMPAIGNS_VIEW,
            sortColumn: 'name',
            getCampaignsByClientType,
            getMarketingCompanies,
            pageName: PAGE_NAMES.CAMPAIGNS_VIEW,
            updateDailyFiguresProps,
            updateTransientProps,
            view: PAGE_NAMES.CAMPAIGNS_VIEW
          })
        },
        {
          name: I18n.t('admin:campaignsViews.adminView'),
          hide: !isAdmin,
          onClick: handleNavigation.bind(null, {
            campaignsName: DAILY_FIGURES_CAMPAIGNS.CHARITY_CAMPAIGNS,
            campaignType: CAMPAIGNS_TYPE.FUNDRAISING,
            dailyFigureType: DAILY_FIGURES_TYPE.CHARITY,
            dailyFigureView: DAILY_FIGURES_VIEW.ADMIN_VIEW,
            sortColumn: 'name',
            getCampaignsByClientType,
            getClientsByClientType,
            getMarketingCompanies,
            pageName: PAGE_NAMES.ADMIN_VIEW,
            updateDailyFiguresProps,
            updateTransientProps,
            view: PAGE_NAMES.ADMIN_VIEW
          })
        }
      ]
    },
    {
      name: I18n.t('admin:commercialCampaigns'),
      views: [
        {
          name: I18n.t('admin:campaignsViews.basView'),
          onClick: handleNavigation.bind(null, {
            campaignsName: DAILY_FIGURES_CAMPAIGNS.COMMERCIAL_CAMPAIGNS,
            campaignType: CAMPAIGNS_TYPE.COMMERCIAL,
            dailyFigureType: DAILY_FIGURES_TYPE.COMMERCIAL,
            dailyFigureView: DAILY_FIGURES_VIEW.BAS_VIEW,
            sortColumn: 'sales',
            getCampaignsByClientType,
            getMarketingCompanies,
            pageName: PAGE_NAMES.BAS_VIEW,
            updateDailyFiguresProps,
            updateTransientProps,
            view: PAGE_NAMES.BAS_VIEW
          })
        },
        {
          name: I18n.t('admin:campaignsViews.campaignsView'),
          onClick: handleNavigation.bind(null, {
            campaignsName: DAILY_FIGURES_CAMPAIGNS.COMMERCIAL_CAMPAIGNS,
            campaignType: CAMPAIGNS_TYPE.COMMERCIAL,
            dailyFigureType: DAILY_FIGURES_TYPE.COMMERCIAL,
            dailyFigureView: DAILY_FIGURES_VIEW.CAMPAIGNS_VIEW,
            sortColumn: 'name',
            getCampaignsByClientType,
            getMarketingCompanies,
            pageName: PAGE_NAMES.CAMPAIGNS_VIEW,
            updateDailyFiguresProps,
            updateTransientProps,
            view: PAGE_NAMES.CAMPAIGNS_VIEW
          })
        },
        {
          name: I18n.t('admin:campaignsViews.adminView'),
          onClick: handleNavigation.bind(null, {
            campaignsName: DAILY_FIGURES_CAMPAIGNS.COMMERCIAL_CAMPAIGNS,
            campaignType: CAMPAIGNS_TYPE.COMMERCIAL,
            dailyFigureType: DAILY_FIGURES_TYPE.COMMERCIAL,
            dailyFigureView: DAILY_FIGURES_VIEW.ADMIN_VIEW,
            sortColumn: 'name',
            getCampaignsByClientType,
            getClientsByClientType,
            getMarketingCompanies,
            pageName: PAGE_NAMES.ADMIN_VIEW,
            updateDailyFiguresProps,
            updateTransientProps,
            view: PAGE_NAMES.ADMIN_VIEW
          }),
          hide: !isAdmin
        }
      ]
    }
  ];

  return (
    <Page>
      <AppHeader
        goBack
        hideAvatar
        showMcPins={false}
        title={I18n.t('admin:dailyFigures')}
        handleClick={NavigationService.goBack.bind(null, { name: PAGE_NAMES.ADMIN })}
      />

      <ColumnView paddingVertical={30} paddingHorizontal={20} gap={24} width={'100%'}>
        {campaigns?.map((campaign, index) => (
          <Accordion key={index} title={campaign.name} items={campaign.views} />
        ))}
      </ColumnView>
    </Page>
  );
};

DailyFigures.propTypes = {
  getCampaignsByClientType: PropTypes.func,
  getClientsByClientType: PropTypes.func,
  getMarketingCompanies: PropTypes.func,
  updateDailyFiguresProps: PropTypes.func,
  updateTransientProps: PropTypes.func,
  user: PropTypes.object
};

export default DailyFigures;
