import moment from 'moment';
import PropTypes from 'prop-types';
import { List } from 'framework7-react';
import React, { useEffect, useRef, useState } from 'react';

import Config from 'Config';
import { I18n } from 'Locales';
import { ColumnView, RowView } from 'Containers';
import { DATE_FORMAT, MODAL_SIZES } from 'Constants';
import { Button, Modal, SearchAndSelect, TextField } from 'Components';
import { handleOnChangeWithValidations, mockFunction, phoneNumberSanitization, validations } from 'Helpers';

import './style.scss';

const UpdateSales = ({
  campaigns = [],
  transient = {},
  updateGrowlProps = mockFunction,
  updateSales = mockFunction,
  updateTransientProps = mockFunction,
  updateTransientPropWithValidations = mockFunction
}) => {
  const isInitialMount = useRef(true);
  const [selectedName, setSelectedName] = useState('');
  const {
    campaignId = '',
    date = '',
    filters = {},
    sales = '',
    selectedRow = {},
    showUpdateSalesModal = false
  } = transient;

  const { campaign_id = '', dailyFiguresDate_start_date = '' } = filters;

  useEffect(() => {
    // ensure that the useEffect hook does not run when component mounts
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    const yesterday = moment().subtract(1, 'days').format(DATE_FORMAT.INTERNAL);

    if (dailyFiguresDate_start_date !== '')
      updateTransientProps({
        date: dailyFiguresDate_start_date
      });
    else {
      updateTransientProps({
        date: yesterday
      });
    }

    if (campaign_id !== '') {
      updateTransientProps({ campaignId: campaign_id });
    }

    if (campaigns?.length === 1 && showUpdateSalesModal) {
      updateTransientProps({
        campaignId: campaigns[0]?.id
      });
      setSelectedName(campaigns[0]?.name);
    }
  }, [showUpdateSalesModal]);

  const handleDateChange = key => e => {
    const value = e.target.value;

    updateTransientProps({ [key]: value });
  };

  const handleClick = () => {
    if (moment(transient?.date).isSame(moment(), 'day')) {
      updateGrowlProps({
        visible: true,
        body: I18n.t('growl:error.todayUpdateSalesError.body'),
        kind: 'error'
      });

      setTimeout(() => {
        updateGrowlProps({
          visible: false
        });
      }, Config.GROWL_AUTOHIDE);
    } else {
      updateSales({
        campaign_id: campaignId,
        sales: transient.sales,
        date: moment(transient.date).format(DATE_FORMAT.UI),
        user_id: selectedRow.user_id
      });
    }
  };

  useEffect(() => {
    const sales = campaigns?.find(campaign => campaign?.id === campaignId)?.sales;
    updateTransientProps({ sales });
  }, [campaignId]);

  const isDisabled = campaignId === '' || sales === '' || date === '';
  const campaignName = campaigns?.find(campaign => JSON.stringify(campaign?.id) === campaign_id)?.name || '';

  const handleClose = ({ updateTransientProps, setSelectedName }) => {
    updateTransientProps({ campaignId: '', date: '', sales: '', selectedRow: {}, showUpdateSalesModal: false });
    setSelectedName('');
  };

  return (
    <Modal
      size={MODAL_SIZES.MEDIUM}
      id={'update-sales-modal'}
      open={showUpdateSalesModal}
      onClose={handleClose.bind(null, { updateTransientProps, setSelectedName })}>
      <Modal.Title>
        <RowView>
          <div className="text-md font-bold">{I18n.t('admin:updateSalesNumber')}</div>
        </RowView>
        <RowView>
          <span>{I18n.t('admin:updateBaSales')}</span>
        </RowView>
      </Modal.Title>

      <Modal.Content>
        <ColumnView id={'update-sales-modal'} height={'100%'} justifyContent={'space-between'}>
          <List className="w-full">
            <div className="pt-4 pb-4">
              {campaign_id ? (
                <RowView justifyContent={'center'} paddingTop={4} paddingBottom={8} gap={4} flexWrap={'wrap'}>
                  <div className="tag">{campaignName}</div>
                </RowView>
              ) : (
                <SearchAndSelect
                  displayProperty="name"
                  fieldName="campaignId"
                  label={I18n.t('admin:reOnboardModal.campaign')}
                  multiple={false}
                  options={campaigns}
                  value={selectedName}
                  selectedProperty="id"
                  updateTransientProps={updateTransientProps}
                />
              )}
            </div>

            <TextField
              label={I18n.t('admin:date')}
              value={transient?.date}
              max={moment().format(DATE_FORMAT.INTERNAL) || transient?.endDate}
              onChange={handleDateChange('date')}
              type="date"
            />

            <ColumnView gap={4} width={'100%'}>
              <TextField
                className="w-full"
                onInput={phoneNumberSanitization}
                label={I18n.t('admin:salesNumber')}
                value={transient?.sales}
                onChange={handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'sales', [
                  validations.isRequired,
                  validations.numbersOnly
                ])}
                type="text"
              />

              {!campaign_id && campaigns?.length > 1 && (
                <ColumnView gap={4} width={'100%'}>
                  {campaigns?.map((campaign, index) => (
                    <RowView key={index} gap={4} justifyContent={'start'}>
                      <span className="text-sm font-bold">{campaign?.name}: </span>
                      <span className="text-sm">{I18n.t('admin:salesCounter', { sales: campaign?.sales })}</span>
                    </RowView>
                  ))}
                </ColumnView>
              )}
            </ColumnView>
          </List>
        </ColumnView>
      </Modal.Content>

      <Modal.Actions>
        <Button.OutlineSmall
          className="line-height-normal"
          onClick={handleClose.bind(null, {
            updateTransientProps,
            setSelectedName
          })}>
          {I18n.t('general:cancel')}
        </Button.OutlineSmall>
        <Button.PrimarySmall disabled={isDisabled} className="line-height-normal" onClick={handleClick}>
          {I18n.t('general:ok')}
        </Button.PrimarySmall>
      </Modal.Actions>
    </Modal>
  );
};

UpdateSales.propTypes = {
  campaigns: PropTypes.array,
  transient: PropTypes.object,
  updateGrowlProps: PropTypes.func,
  updateSales: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func
};

export default UpdateSales;
