import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Block, BlockTitle, Icon, List, ListItem } from 'framework7-react';

import { I18n } from 'Locales';
import { APP_MODES } from 'Constants';
import { ColumnView, RowView } from 'Containers';
import { AgentInfo, SummarySignature } from 'FlowComponentTypes';
import { Button, Card, PassFlowModal, TextField } from 'Components';
import { handleOnChangeWithValidations, isSmallDevice, phoneNumberSanitization, validations } from 'Helpers';

import './style.scss';
import { getFieldProp, signatureStyle, getIconColor } from '../Component/shared';

const filterChoiceTitle = (choice, rf) => choice == rf.id;

const renderAddress = (id, name, value, isValid) => {
  const { AddressLine1, AddressLine2, AddressLine3, City, PostCode } = value;

  return (
    <div key={id + 'address_summary'}>
      <RowView justifyContent={'flex-start'} paddingLeft={16}>
        <div className="item-title item-label pb-8">{name}</div>
      </RowView>

      <ColumnView>
        <div className="w-full">
          <div className={`${isValid ? '' : 'list-input-style'}`}>
            {!isValid && <Icon md={'material:warning'} className="warning" />}
            <TextField
              label={I18n.t('flow:components.address.line1')}
              placeholder={I18n.t('flow:components.address.line1')}
              value={AddressLine1}
              disabled
              icon="material:check"
              iconColor={getIconColor(false, AddressLine1)}
            />
          </div>

          <TextField
            label={I18n.t('flow:components.address.line2')}
            placeholder={I18n.t('flow:components.address.line2')}
            value={AddressLine2}
            disabled
            icon="material:check"
            iconColor={getIconColor(false, AddressLine2)}
          />

          <TextField
            label={I18n.t('flow:components.address.line3')}
            placeholder={I18n.t('flow:components.address.line3')}
            value={AddressLine3}
            disabled
            icon="material:check"
            iconColor={getIconColor(false, AddressLine3)}
          />

          <div className={`${isValid ? '' : 'list-input-style'}`}>
            {!isValid && <Icon md={'material:warning'} className="warning" />}
            <TextField
              label={I18n.t('flow:components.address.postalCodeLabel')}
              placeholder={I18n.t('flow:components.address.postalCodePlaceholder')}
              value={PostCode}
              disabled
              icon="material:check"
              iconColor={getIconColor(false, PostCode)}
            />
          </div>

          <div className={`${isValid ? '' : 'list-input-style'}`}>
            {!isValid && <Icon md={'material:warning'} className="warning" />}
            <TextField
              label={I18n.t('flow:components.address.cityLabel')}
              placeholder={I18n.t('flow:components.address.cityPlaceholder')}
              value={City}
              disabled
              icon="material:check"
              iconColor={getIconColor(false, City)}
            />
          </div>
        </div>
      </ColumnView>
    </div>
  );
};

const renderComponent = (currentFlowData, referenced_products, component) =>
  currentFlowData[component.component_key]?.showInSummaryPage
    ? renderShowComponent(
        component,
        currentFlowData[component.component_key].value,
        referenced_products,
        currentFlowData[component.component_key].isValid
      )
    : null;

const renderPageBlocks = (showTitle, currentFlowData, referenced_products, page) =>
  showTitle[page.id] && (
    <Fragment key={`${page.id}summary`}>
      <BlockTitle small className="text-dark-gray">
        {page.name}
      </BlockTitle>
      <List noHairlines>
        <ul>{page.components.map(renderComponent.bind(this, currentFlowData, referenced_products))}</ul>
      </List>
    </Fragment>
  );

const renderShowComponent = (component, value, referenced_products, isValid) => {
  const { component_type, fields, id, name, type } = component;
  const isProduct = component_type === 'product';

  if (type === 'confirmed_email') {
    const { email, emailConfirmation } = value;
    return (
      <>
        {isValid ? (
          <>
            <TextField
              label={I18n.t('flow:components.confirmEmail.email')}
              value={email}
              disabled
              icon="material:check"
              iconColor={getIconColor(false, email)}
            />
            <TextField
              label={I18n.t('flow:components.confirmEmail.confirmEmail')}
              value={emailConfirmation}
              disabled
              icon="material:check"
              iconColor={getIconColor(false, emailConfirmation)}
            />
          </>
        ) : (
          <>
            <div className="list-input-style">
              <Icon md={'material:warning'} className="warning" />
              <TextField
                label={I18n.t('flow:components.confirmEmail.email')}
                value={email}
                disabled
                icon="material:check"
                iconColor={getIconColor(false, email)}
              />
            </div>

            <div className="list-input-style">
              <Icon md={'material:warning'} className="warning" />
              <TextField
                label={I18n.t('flow:components.confirmEmail.confirmEmail')}
                value={emailConfirmation}
                disabled
                icon="material:check"
                iconColor={getIconColor(false, emailConfirmation)}
              />
            </div>
          </>
        )}
      </>
    );
  }

  if (type === 'text' || type === 'select') {
    return (
      <div key={`${id}summary`}>
        {isValid ? (
          <TextField
            label={name}
            type="text"
            value={!isProduct ? value : referenced_products.filter(filterChoiceTitle.bind(this, value))[0].name}
            disabled
          />
        ) : (
          <div className="list-input-style">
            <Icon md={'material:warning'} className="warning" />
            <TextField
              label={name}
              type="text"
              value={!isProduct ? value : referenced_products.filter(filterChoiceTitle.bind(this, value))[0].name}
              disabled
            />
          </div>
        )}
      </div>
    );
  }

  if (type === 'bank_account') {
    return renderBankAccount(id, component, value, name, isValid);
  }

  if (type === 'bank_registration_lookup') {
    return (
      <div key={`${id}summary`}>
        {isValid ? (
          <TextField label={name} type="text" value={value.registrationNumber} disabled />
        ) : (
          <div className="list-input-style">
            <Icon md={'material:warning'} className="warning" />
            <TextField label={name} type="text" value={value.registrationNumber} disabled />
          </div>
        )}
      </div>
    );
  }

  if (type === 'checkbox' || type === 'radio') {
    const isRadio = type === 'radio';
    const isCheckBox = type === 'checkbox';

    let choices = fields[0].choices;

    if (component_type === 'product') {
      const mapId = rf => rf.id;
      choices = referenced_products.map(mapId.bind(this));
    }

    const checkValue = (value, type, choice) => {
      if (type === 'radio') {
        return choice == value;
      }

      return value && value.indexOf(choice) >= 0;
    };

    const renderListItem = (isProduct, referenced_products, filterChoiceTitle, choice) => (
      <ListItem
        className="disabled"
        radio={isRadio}
        checkbox={isCheckBox}
        key={choice + 'summary'}
        checked={true}
        value={choice}
        title={!isProduct ? choice : referenced_products.find(filterChoiceTitle.bind(this, choice)).name}
      />
    );

    return (
      <Fragment key={`${id}summary`}>
        <RowView justifyContent={'flex-start'} paddingLeft={16}>
          <div className="item-title item-label text-color-gray">{name}</div>
        </RowView>
        {choices
          .filter(checkValue.bind(this, value, type))
          .map(renderListItem.bind(this, isProduct, referenced_products, filterChoiceTitle))}
      </Fragment>
    );
  }

  if (type === 'address') {
    return renderAddress(id, name, value, isValid);
  }

  if (type === 'signature') {
    return (
      <Fragment key={`${id}summary`}>
        <RowView justifyContent={'flex-start'} paddingLeft={16}>
          <div className="item-title item-label text-color-gray">{name}</div>
        </RowView>

        <img
          slot="media"
          src={
            value ||
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAQAAABeK7' +
              'cBAAAADUlEQVR42mP8X8/AAAAFgwGANbMDjQAAAABJRU5ErkJggg=='
          }
          width={isSmallDevice ? window.innerWidth - 32 : '160'}
          style={{ ...signatureStyle.blankImage, ...signatureStyle.centerImage }}
        />
      </Fragment>
    );
  }

  if (type === 'area') {
    return isValid ? (
      <TextField disabled key={`${id}summary`} label={name} type="textarea" value={value} />
    ) : (
      <div className="list-input-style">
        <Icon md={'material:warning'} className="warning" />
        <TextField disabled key={`${id}summary`} label={name} type="textarea" value={value} />
      </div>
    );
  }

  return <p key={Math.random() * 100 + 'comp_not_summary'}>Component with type: {type} cannot be rendered</p>;
};

const renderBankAccount = (id, component, value, name, isValid) => {
  const { country } = component;
  if (['FR', 'BE', 'DE', 'IE'].includes(country)) {
    return (
      <React.Fragment key={`${id}summary`}>
        <div className={!isValid ? 'list-input-style' : ''}>
          {!isValid && <Icon md={'material:warning'} className="warning" />}
          <TextField
            label={name}
            type="text"
            value={value.registrationNumber}
            disabled
            icon="material:check"
            iconColor={getIconColor(false, value.registrationNumber)}
          />
        </div>

        {value.bankName && (
          <RowView>
            <TextField
              label={I18n.t('flow:components.bankAccount.bankName')}
              type={'text'}
              className={'flex1'}
              value={value.bankName}
              disabled
              icon="material:check"
              iconColor={getIconColor(false, value.bankName)}
            />
          </RowView>
        )}
        {value.bic && (
          <RowView>
            <TextField
              label={I18n.t('flow:components.bankAccount.bic')}
              type={'text'}
              className={'flex1'}
              value={value.bic}
              disabled
              icon="material:check"
              iconColor={getIconColor(false, value.bic)}
            />
          </RowView>
        )}
      </React.Fragment>
    );
  }

  if (country === 'UK') {
    const accountNumberProp = getFieldProp(component.fields, 'accountNumber');
    const bankNameProp = getFieldProp(component.fields, 'bankName');
    const holderNameProp = getFieldProp(component.fields, 'holderName');
    const sortCodeProp = getFieldProp(component.fields, 'sortCode');

    const { bankName, holderName, accountNumber, sortCode } = value;

    return (
      <ColumnView>
        <RowView>
          <TextField
            label={I18n.t('flow:components.bankAccount.bankName')}
            type={'text'}
            placeholder={bankNameProp?.placeholder}
            className={'input-style'}
            value={bankName}
            disabled
            icon="material:check"
            iconColor={getIconColor(false, bankName)}
          />
        </RowView>
        <RowView>
          <TextField
            label={I18n.t('flow:components.bankAccount.holderName')}
            type={'text'}
            placeholder={holderNameProp?.placeholder}
            className={'input-style'}
            value={holderName}
            disabled
            icon="material:check"
            iconColor={getIconColor(false, holderName)}
          />
        </RowView>

        <RowView flexWrap={isSmallDevice ? 'wrap' : 'nowrap'}>
          <TextField
            label={I18n.t('flow:components.bankAccount.accountNumber')}
            type={'text'}
            placeholder={accountNumberProp?.placeholder}
            className={'input-style'}
            value={accountNumber}
            disabled
            icon="material:check"
            iconColor={getIconColor(false, accountNumber)}
          />

          <TextField
            label={I18n.t('flow:components.bankAccount.sortCode')}
            type={'text'}
            placeholder={sortCodeProp?.placeholder}
            className={'input-style'}
            value={sortCode}
            disabled
            icon="material:check"
            iconColor={getIconColor(false, sortCode)}
          />
        </RowView>
      </ColumnView>
    );
  }
};

const showTitleFilter = (currentFlowData, component) => currentFlowData[component.component_key] !== undefined;

const handleChangeValue = (updateTransientProps, key, value) => {
  updateTransientProps({ [key]: value });
};

const options = ['SMS', 'Email'];

const Summary = props => {
  const [isComplete, setIsComplete] = useState(false);

  const {
    currentFlowBadgeNumber,
    currentFlowData,
    currentPageIndex,
    flow_signature,
    flow,
    initSubmitFlow,
    isOffline,
    mode,
    pages,
    availablePages,
    processing,
    referenced_products,
    selectedCampaignPublishedFlowId,
    signatureValues,
    transient,
    updateAgreement,
    updateSignature,
    updateTransientProps,
    updateTransientPropWithValidations,
    user,
    userAvatar,
    userFullName,
    validBadgeNumber
  } = props;

  const agentMode = mode === APP_MODES.AGENT;
  const donorMode = mode === APP_MODES.DONOR;
  const { showSignature } = flow_signature;

  const {
    donor_email_address = '',
    donor_email_address_has_error,
    donor_phone_number = '',
    donor_phone_number_has_error,
    donorModal,
    sending_option
  } = transient;

  const disableButton =
    processing ||
    !sending_option ||
    (sending_option === 'SMS' && (donor_phone_number_has_error || !donor_phone_number)) ||
    (sending_option === 'Email' && (donor_email_address_has_error || !donor_email_address));

  const forceSendToDonor = flow?.flows[selectedCampaignPublishedFlowId]?.force_send_to_donor;

  useEffect(() => {
    let isAllValid = false;
    let isSigned = false;
    for (const key in currentFlowData) {
      const { isValid = true, component, value } = currentFlowData[key];
      handleChangeValue(updateTransientProps, key, isValid);
      if (component === 'email') {
        updateTransientPropWithValidations('donor_email_address', value, [
          validations.isRequired,
          validations.emailValidation
        ]);
      } else if (component === 'phone') {
        updateTransientPropWithValidations('donor_phone_number', value, [
          validations.isRequired,
          validations.phoneNumber
        ]);
      } else if (value?.email) {
        updateTransientPropWithValidations('donor_email_address', value.email, [
          validations.isRequired,
          validations.emailValidation
        ]);
      }
      if (!isValid) {
        isAllValid = true;
      }
    }

    if (signatureValues?.base64?.length > 0 || !showSignature) {
      isSigned = true;
    }

    setIsComplete(!isAllValid);
    updateTransientProps({ isComplete: !isAllValid, signed: isSigned });
  }, [currentFlowData, isComplete, signatureValues, updateTransientProps]);

  useEffect(() => {
    updateTransientPropWithValidations('donor_email_address', donor_email_address, [
      validations.isRequired,
      validations.emailValidation
    ]);
    updateTransientPropWithValidations('donor_phone_number', donor_phone_number, [
      validations.isRequired,
      validations.phoneNumber
    ]);
  }, [sending_option]);

  const showTitle = {};

  const sendingOptionsConfig = {
    Email: [
      {
        label: I18n.t('flow:email'),
        type: 'text',
        placeholder: I18n.t('flow:emailAddress'),
        value: donor_email_address || '',
        onChange: handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'donor_email_address', [
          validations.isRequired,
          validations.emailValidation
        ])
      }
    ],
    SMS: [
      {
        label: I18n.t('flow:sms'),
        type: 'text',
        placeholder: I18n.t('flow:phoneNumber'),
        value: donor_phone_number || '',
        onInput: phoneNumberSanitization,
        onChange: handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'donor_phone_number', [
          validations.isRequired,
          validations.phoneNumber
        ])
      }
    ]
  };

  const handleClick = () => {
    if (!agentMode) {
      initSubmitFlow();
    } else if (user?.mandatory_location) {
      updateTransientProps({ loadingLocation: true });
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          updateTransientProps({ latitude, longitude, loadingLocation: false });

          initSubmitFlow(true);
        },
        () => {
          updateTransientProps({ showDeniedLocationModal: true, loadingLocation: false });
        }
      );
    } else {
      initSubmitFlow(true);
    }
  };

  const handleSendLinkToDonor = () => {
    if (user?.mandatory_location) {
      updateTransientProps({ loadingLocation: true });
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          updateTransientProps({ latitude, longitude, loadingLocation: false, donorModal: true, sendToDonor: true });
        },
        () => {
          updateTransientProps({
            showDeniedLocationModal: true,
            loadingLocation: false,
            donorModal: false,
            sendToDonor: false
          });
        }
      );
    } else {
      updateTransientProps({ donorModal: true, sendToDonor: true });
    }
  };

  const displayablePages = pages.filter(page => availablePages?.includes(page?.id));
  for (const page of displayablePages) {
    showTitle[page.id] = page.components.filter(showTitleFilter.bind(this, currentFlowData)).length > 0;
  }

  return (
    <ColumnView justifyContent={'flex-start'} alignItems={'stretch'}>
      {pages.map(renderPageBlocks.bind(this, showTitle, currentFlowData, referenced_products))}

      <BlockTitle small className="text-dark-gray">
        {I18n.t('flow:summary.agreement')}
      </BlockTitle>

      <Block>
        <Card description={I18n.t('flow:summary.agreementWarning')} />
      </Block>

      <AgentInfo
        currentFlowBadgeNumber={currentFlowBadgeNumber}
        flow_signature={flow_signature}
        mode={mode}
        user={user}
        userAvatar={userAvatar}
        userFullName={userFullName}
        validBadgeNumber={validBadgeNumber}
      />

      {showSignature && isComplete && !forceSendToDonor && (
        <SummarySignature
          currentPageIndex={currentPageIndex}
          mode={mode}
          updateSignature={updateSignature}
          updateAgreement={updateAgreement}
          userFullName={userFullName}
          value={signatureValues}
        />
      )}

      <ColumnView>
        <RowView paddingBottom={10} paddingHorizontal={20}>
          <Button.Primary
            width={'100%'}
            onClick={handleClick}
            disabled={
              forceSendToDonor ||
              (showSignature &&
                ((agentMode && !signatureValues?.agreement) || (donorMode && signatureValues?.base64.length === 0))) ||
              !transient.isComplete ||
              processing ||
              transient?.loadingLocation
            }
            processing={processing || transient?.loadingLocation}>
            {I18n.t('flow:submit')}
          </Button.Primary>
        </RowView>
        {!donorMode && !isOffline && (
          <RowView paddingBottom={10} paddingHorizontal={20}>
            <Button.Outline
              width={'100%'}
              onClick={handleSendLinkToDonor}
              processing={(processing && !signatureValues.base64.length === 0) || transient?.loadingLocation}
              disabled={!!signatureValues.base64 || transient?.loadingLocation}>
              {I18n.t('flow:submitAndSendtoDonnor')}
            </Button.Outline>
          </RowView>
        )}
      </ColumnView>

      <PassFlowModal
        title={I18n.t('flow:passFlowToDonor')}
        opened={donorModal && !isOffline}
        subtitle={I18n.t('flow:sendLinkToDonorSubtitle')}
        isOpen={donorModal && !isOffline}
        onClose={updateTransientProps.bind(null, { donorModal: false, sendToDonor: false })}
        sendingOption={sending_option}
        options={options}
        sendingOptionsConfig={sendingOptionsConfig}
        handleChange={(field, value) => updateTransientProps({ [field]: value })}
        handleSubmit={() => initSubmitFlow()}
        disableButton={disableButton}
      />
    </ColumnView>
  );
};

Summary.propTypes = {
  availablePages: PropTypes.array,
  currentFlowBadgeNumber: PropTypes.string,
  currentFlowData: PropTypes.object,
  currentPageIndex: PropTypes.number,
  flow_signature: PropTypes.object,
  flow: PropTypes.object,
  initSubmitFlow: PropTypes.func,
  isOffline: PropTypes.bool,
  mode: PropTypes.string,
  pages: PropTypes.array,
  processing: PropTypes.bool,
  referenced_products: PropTypes.array,
  selectedCampaignPublishedFlowId: PropTypes.string,
  signatureValues: PropTypes.object,
  transient: PropTypes.object,
  updateAgreement: PropTypes.func,
  updateSignature: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  user: PropTypes.object,
  userAvatar: PropTypes.string,
  userFullName: PropTypes.string,
  validBadgeNumber: PropTypes.any
};

export default Summary;
