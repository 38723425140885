import flow from './flow';
import growl from './growl';
import admin from './admin';
import inputs from './inputs';
import checkIn from './checkIn';
import profile from './profile';
import filters from './filters';
import reports from './reports';
import general from './general';
import campaign from './campaign';
import settings from './settings';
import validations from './validations';

export default {
  admin,
  campaign,
  checkIn,
  filters,
  flow,
  general,
  growl,
  inputs,
  profile,
  reports,
  settings,
  validations
};
