import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'framework7-react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { ColumnView, RowView } from 'Containers';
import { Button, Modal, TextField } from 'Components';

import './style.scss';

const PassFlowModal = ({
  disableButton = false,
  handleChange = mockFunction,
  donorName = '',
  opened = false,
  handleSubmit = mockFunction,
  onClose = mockFunction,
  options = [],
  sendingOption = '',
  sendingOptionsConfig = {},
  subtitle = '',
  title = ''
}) => (
  <Modal id={'pass-flow-modal'} open={opened} size={'medium'} onClose={onClose}>
    <Modal.Title>
      <div className="title-md">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </Modal.Title>

    <Modal.Content>
      <ColumnView>
        <List className="w-full">
          <TextField
            label={I18n.t('flow:sendingOption')}
            type="select"
            value={sendingOption}
            onChange={e => handleChange('sending_option', e.target.value)}>
            <option value="" disabled>
              {I18n.t('flow:sendingOption')}
            </option>
            {options?.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </TextField>
        </List>
        {sendingOption && (
          <List className="w-full">
            {sendingOptionsConfig[sendingOption].map(({ label, type, placeholder, value, onChange }) => (
              <TextField
                label={label}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                key={label}
              />
            ))}
          </List>
        )}
      </ColumnView>

      {!!donorName && donorName !== '' && (
        <RowView flexWrap={'wrap'} justifyContent={'flex-start'} paddingHorizontal={20}>
          <span className="text-sm pr-4">{I18n.t('reports:passFlowTo')}</span>
          <div className="font-semibold pr-4">{donorName}</div>
          <span>?</span>
        </RowView>
      )}
    </Modal.Content>

    <Modal.Actions>
      <Button.OutlineSmall onClick={onClose}>{I18n.t('general:cancel')}</Button.OutlineSmall>
      <Button.PrimarySmall onClick={handleSubmit} disabled={disableButton}>
        {I18n.t('flow:send')}
      </Button.PrimarySmall>
    </Modal.Actions>
  </Modal>
);

PassFlowModal.propTypes = {
  disableButton: PropTypes.bool,
  donorName: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  opened: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  sendingOption: PropTypes.string,
  sendingOptionsConfig: PropTypes.object,
  subtitle: PropTypes.string,
  title: PropTypes.string
};

export default PassFlowModal;
