import { connect } from 'react-redux';

import { Creators as userActions } from 'Reducers/user';
import { Creators as GrowlActions } from 'Reducers/growl';
import { Creators as transientActions } from 'Reducers/transient';
import { Creators as adminActions } from 'Reducers/adminOnboarding';

import OnboardingProfile from './view';

export default connect(
  state => ({
    ...state.adminOnboarding,
    isOffline: state.application.isOffline,
    transient: state.transient,
    user_role: state.user.role_name,
    user: state.user
  }),
  {
    bankAccountCheckFR: userActions.bankAccountCheckFR,
    bankAccountCheckUK: userActions.bankAccountCheckUK,
    checkOnboardingProfileVetting: adminActions.checkOnboardingProfileVetting,
    cleanUpTransientProps: transientActions.cleanUpTransientProps,
    confirmOnboardingProfileVetting: adminActions.confirmOnboardingProfileVetting,
    declineOnboardingProfile: adminActions.declineOnboardingProfile,
    deleteUserAttachment: adminActions.deleteUserAttachment,
    extendOnboardingProfileVetting: adminActions.extendOnboardingProfileVetting,
    getAdminOnboardingProfiles: adminActions.getAdminOnboardingProfiles,
    getCampaignsByClient: adminActions.getCampaignsByClient,
    getClients: adminActions.getClients,
    getReOnboardingCampaigns: adminActions.getReOnboardingCampaigns,
    getSigners: adminActions.getSigners,
    getUserOnboardingProfile: adminActions.getUserOnboardingProfile,
    reactivateOnboardingProfile: adminActions.reactivateOnboardingProfile,
    readAttachment: userActions.readAttachment,
    rejectOnboardingProfileVetting: adminActions.rejectOnboardingProfileVetting,
    releaseBlock: adminActions.releaseBlock,
    removeAttachment: adminActions.removeAttachment,
    reOnboardProfile: adminActions.reOnboardProfile,
    resetOnboardingProfilePages: adminActions.resetOnboardingProfilePages,
    sendWelcomeEmail: adminActions.sendWelcomeEmail,
    sendWelcomeSms: adminActions.sendWelcomeSms,
    updateAdminProps: adminActions.updateProps,
    updateBadgeNumber: adminActions.updateBadgeNumber,
    updateGrowlProps: GrowlActions.updateProps,
    updateOnboardingProfileDocuments: adminActions.updateOnboardingProfileDocuments,
    updateOnboardingUserProfile: adminActions.updateOnboardingUserProfile,
    updateOnboardingUserSection: adminActions.updateOnboardingUserSection,
    updateTransientProps: transientActions.updateProps,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations,
    updateUserAvatar: userActions.updateUserAvatar,
    updateUserDocument: adminActions.updateUserDocument,
    vetProfile: adminActions.vetProfile
  }
)(OnboardingProfile);
