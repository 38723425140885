import moment from 'moment';
import PropTypes from 'prop-types';
import { List } from 'framework7-react';
import React, { useEffect, useRef } from 'react';

import Config from 'Config';
import { I18n } from 'Locales';
import { RowView } from 'Containers';
import { mockFunction } from 'Helpers';
import { DATE_FORMAT, MODAL_SIZES } from 'Constants';
import { Button, Modal, SearchAndSelect, TextField } from 'Components';

const ManualCheckIn = ({
  campaigns = [],
  manualCheckIn = mockFunction,
  transient = {},
  updateGrowlProps = mockFunction,
  updateTransientProps = mockFunction
}) => {
  const isInitialMount = useRef(true);
  const {
    campaignId = '',
    endDate = '',
    selectedRow = {},
    showManualCheckInModal = false,
    startDate = '',
    filters = {}
  } = transient;

  const { campaign_id = '', dailyFiguresDate_end_date = '', dailyFiguresDate_start_date = '' } = filters;

  useEffect(() => {
    // ensure that the useEffect hook does not run when component mounts
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    const yesterday = moment().subtract(1, 'days').format(DATE_FORMAT.INTERNAL);

    if (dailyFiguresDate_start_date !== '' && dailyFiguresDate_end_date !== '')
      updateTransientProps({
        startDate: dailyFiguresDate_start_date,
        endDate: dailyFiguresDate_end_date
      });
    else {
      updateTransientProps({
        startDate: yesterday,
        endDate: yesterday
      });
    }

    if (campaign_id !== '') {
      updateTransientProps({ campaignId: campaign_id });
    }
  }, [showManualCheckInModal]);

  const handleDateChange = key => e => {
    const value = e.target.value;

    updateTransientProps({ [key]: value });
  };

  const handleClick = () => {
    if (moment(transient?.endDate).isSame(moment(), 'day')) {
      updateGrowlProps({
        visible: true,
        body: I18n.t('growl:error.todayCheckInError.body'),
        kind: 'error'
      });

      setTimeout(() => {
        updateGrowlProps({
          visible: false
        });
      }, Config.GROWL_AUTOHIDE);
    } else {
      manualCheckIn({
        campaign_id: campaignId,
        checked: !selectedRow.ba_in_field,
        start_date: moment(startDate).format(DATE_FORMAT.UI),
        end_date: moment(endDate).format(DATE_FORMAT.UI),
        user_id: selectedRow.user_id,
        sales: selectedRow.sales
      });
    }
  };

  return (
    <Modal
      size={MODAL_SIZES.MEDIUM}
      open={showManualCheckInModal}
      onClose={updateTransientProps.bind(null, {
        campaignId: '',
        endDate: '',
        selectedRow: {},
        showManualCheckInModal: false,
        startDate: ''
      })}>
      <Modal.Title>
        <RowView>
          <div className="text-md font-bold">{I18n.t('admin:manualCheckIn')}</div>
        </RowView>
        <RowView>
          <span>{I18n.t('admin:markBaInTheField')}</span>
        </RowView>
      </Modal.Title>

      <Modal.Content>
        <List className="w-full">
          <div className="pt-4 pb-4">
            <SearchAndSelect
              displayProperty="name"
              fieldName="campaignId"
              label={I18n.t('admin:reOnboardModal.campaign')}
              multiple={false}
              options={campaigns}
              selectedProperty="id"
              transient={transient}
              updateTransientProps={updateTransientProps}
              value={campaignId}
            />
          </div>

          <TextField
            label={I18n.t('filters:general.start_date')}
            value={transient?.startDate}
            max={moment().format(DATE_FORMAT.INTERNAL) || transient?.endDate}
            onChange={handleDateChange('startDate')}
            type="date"
          />
          <TextField
            label={I18n.t('filters:general.end_date')}
            value={transient?.endDate}
            min={transient?.startDate}
            max={moment().format(DATE_FORMAT.INTERNAL)}
            onChange={handleDateChange('endDate')}
            type="date"
          />
        </List>
      </Modal.Content>

      <Modal.Actions>
        <Button.OutlineSmall
          className="line-height-normal"
          onClick={updateTransientProps.bind(null, {
            campaignId: '',
            endDate: '',
            selectedRow: {},
            showManualCheckInModal: false,
            startDate: ''
          })}>
          {I18n.t('general:cancel')}
        </Button.OutlineSmall>
        <Button.PrimarySmall disabled={campaignId === ''} className="line-height-normal" onClick={handleClick}>
          {I18n.t('general:ok')}
        </Button.PrimarySmall>
      </Modal.Actions>
    </Modal>
  );
};

ManualCheckIn.propTypes = {
  campaigns: PropTypes.array,
  manualCheckIn: PropTypes.func,
  transient: PropTypes.object,
  updateGrowlProps: PropTypes.func,
  updateTransientProps: PropTypes.func
};

export default ManualCheckIn;
