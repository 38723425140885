import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { Button, Modal } from 'Components';
import { ColumnView, RowView } from 'Containers';

const MandatoryLocationModal = ({
  device = '',
  showDeniedLocationModal = false,
  updateTransientProps = mockFunction
}) => (
  <Modal open={showDeniedLocationModal}>
    <ColumnView id={'location-modal'} justifyContent={'space-between'} height={'100%'} gap={24}>
      <ColumnView>
        <RowView>
          <div className="text-md font-bold">{I18n.t('general:enableLocation.locationDisabledTitle')}</div>
        </RowView>
        <ColumnView gap={4} paddingTop={16}>
          <div className="text-sm pb-8">{I18n.t('general:enableLocation.enableLocationSubtitle')}</div>
          {I18n.t(device === 'android' ? 'general:enableLocation.androidSteps' : 'general:enableLocation.iosSteps', {
            returnObjects: true
          }).map((step, index) => (
            <RowView justifyContent={'flex-start'} key={index}>
              <div className="text-sm">{step}</div>
            </RowView>
          ))}
        </ColumnView>
      </ColumnView>
      <RowView>
        <Button.Primary
          className="line-height-normal"
          width={'100%'}
          onClick={updateTransientProps.bind(null, { showDeniedLocationModal: false })}>
          {I18n.t('general:close')}
        </Button.Primary>
      </RowView>
    </ColumnView>
  </Modal>
);

MandatoryLocationModal.propTypes = {
  device: PropTypes.string,
  showDeniedLocationModal: PropTypes.bool,
  updateTransientProps: PropTypes.func
};

export default MandatoryLocationModal;
