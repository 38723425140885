import { connect } from 'react-redux';

import { Creators as transientActions } from 'Reducers/transient';
import { Creators as adminActions } from 'Reducers/adminOnboarding';

import Admin from './view';

export default connect(
  state => ({
    adminOnboarding: state.adminOnboarding,
    transient: state.transient,
    user: state.user
  }),
  {
    getMcPin: adminActions.getMcPin,
    updateAdminProps: adminActions.updateProps,
    updateTransientProps: transientActions.updateProps
  }
)(Admin);
