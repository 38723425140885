import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { MODAL_SIZES } from 'Constants';
import { ColumnView, RowView } from 'Containers';
import { Avatar, BadgeIcon, Modal } from 'Components';
import { BadgeIconPlaceholder, PlaceholderCampaign } from 'Icons';

const renderBadgeModalInfo = (item, index) => (
  <RowView key={index} justifyContent="flex-start" width={'100%'}>
    <div className="text-xs text-gray line-height-normal">{`${item.label}:`}</div>
    <div className="text-sm line-height-normal pl-4">{item.value}</div>
  </RowView>
);

const renderBadges = (badges, selectedBadgeId, updateTransientProps, transient) =>
  badges.map(badge => (
    <ColumnView
      marginBottom="auto"
      key={badge.id}
      alignSelf="start"
      onClick={() =>
        badge.id !== selectedBadgeId &&
        updateTransientProps({
          activeUserBadges: {
            ...transient.activeUserBadges,
            selectedBadge: badge
          }
        })
      }>
      <BadgeIcon badgeColor={badge.id == selectedBadgeId ? 'blue' : 'gray'}>
        <img
          src={badge?.campaign?.icon_logo_url ?? BadgeIconPlaceholder}
          className={badge?.campaign?.icon_logo_url && 'badge-icon-size'}
        />
      </BadgeIcon>
      <RowView marginBottom={20}>
        <div className="text-center text-xxs">{badge?.campaign?.name}</div>
      </RowView>
    </ColumnView>
  ));

const ShowBadgeModal = ({
  transient = {},
  updateTransientProps = mockFunction,
  updateUserAvatar = mockFunction,
  user = {}
}) => {
  const transformedBadges = transient?.activeUserBadges?.badges?.reduce((acc, badge) => {
    const campaignId = badge?.campaign?.id;
    if (!acc[campaignId]) {
      acc[campaignId] = [];
    }
    acc[campaignId].push(badge?.number);
    return acc;
  }, {});
  const { showBadgeModal = false } = transient;
  const uniqueBadges = transient?.activeUserBadges?.badges?.filter(
    (badge, index, self) => self.findIndex(b => b.campaign.id === badge.campaign.id) === index
  );
  const userSelectedBadgeId = transient?.activeUserBadges?.selectedBadge?.id;
  const userSelectedBadgeCampaignId = transient?.activeUserBadges?.selectedBadge?.campaign?.id;
  const userSelectedBadgeNumber = transformedBadges?.[userSelectedBadgeCampaignId]?.join(', ');
  const userSelectedCampaignName = transient?.activeUserBadges?.selectedBadge?.campaign?.name;

  const badgeModalInfo = [
    {
      label: I18n.t('profile:profileTab.badgeNumber'),
      value: userSelectedBadgeNumber
    },
    {
      label: I18n.t('profile:profileTab.campaign'),
      value: userSelectedCampaignName
    },
    { label: I18n.t('profile:profileTab.marketingCompany'), value: user.mc_names }
  ];

  return (
    <Modal
      id={'profile-tab'}
      size={MODAL_SIZES.LARGE}
      open={showBadgeModal}
      closeByBackdropClick={false}
      onClose={updateTransientProps.bind(null, { showBadgeModal: false })}>
      <Modal.Title closeButton></Modal.Title>

      <Modal.Content>
        <ColumnView>
          <Avatar
            avatarInitials
            size={90}
            source={user?.avatar_url}
            transient={transient}
            updateTransientProps={updateTransientProps}
            updateUserAvatar={updateUserAvatar}
            user={user}
            userAvatar
          />
          <div className="text-md font-medium line-height-24 pt-8">{`${user.first_name} ${user.last_name}`}</div>
          <div className="text-xs text-gray line-height-24">{I18n.t('profile:profileTab.brandAmbassador')}</div>
        </ColumnView>

        <ColumnView gap={16}>{badgeModalInfo.map(renderBadgeModalInfo)}</ColumnView>
        <div
          className={`badge-scroll no-scrollbars pt-8 ${
            uniqueBadges?.length > 2 ? 'w-full justify-start' : 'w-auto justify-center'
          }`}>
          {uniqueBadges?.length && renderBadges(uniqueBadges, userSelectedBadgeId, updateTransientProps, transient)}
          <img src={PlaceholderCampaign} />
        </div>
      </Modal.Content>
    </Modal>
  );
};

ShowBadgeModal.propTypes = {
  selectedBadgeNumber: PropTypes.string,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateUserAvatar: PropTypes.func,
  user: PropTypes.object
};

export default ShowBadgeModal;
