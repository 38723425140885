import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { ColumnView, RowView } from 'Containers';

const ErrorsModal = ({ profilesWithErrors = [], profilesWithoutErrors = '' }) => (
  <>
    <ColumnView>
      <div className="text-sm font-bold">
        {I18n.t('admin:bulkErrors.numberOfRecords', {
          successfully: profilesWithoutErrors,
          failed: profilesWithErrors?.length
        })}
      </div>
      <div>{I18n.t('admin:bulkErrors.seeBelow')}</div>
    </ColumnView>

    <div className="errors-container">
      {profilesWithErrors.map(profile => (
        <RowView alignItems={'flex-start'} key={profile.user_name} height={60} gap={10}>
          <div className="text-sm font-bold width-pct-30">{profile.user_name}:</div>
          <div className="text-sm width-pct-70">
            {profile.resource_errors.state ? profile.resource_errors.state[0] : profile.resource_errors.base[0]}
          </div>
        </RowView>
      ))}
    </div>
  </>
);

ErrorsModal.propTypes = {
  profilesWithErrors: PropTypes.array,
  profilesWithoutErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default ErrorsModal;
