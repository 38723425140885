import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { List, ListItem, Searchbar } from 'framework7-react';

import { I18n } from 'Locales';
import { ColumnView, RowView } from 'Containers';
import { displayedValue, mockFunction, preventDefault, useOutsideClick } from 'Helpers';

import './style.scss';

const SearchAndSelect = ({
  clearKey = null,
  displayProperty = '',
  fieldName = '',
  label = '',
  multiple = false,
  onChange = null,
  onClear = mockFunction,
  onSearch = null,
  options = [],
  otherOptions = [],
  resetKey = null,
  selectedProperty = '',
  selectedValues = [],
  selectProperty = '',
  updateTransientProps = mockFunction,
  value = ''
}) => {
  const dropdownRef = useRef(null);
  const isInitialMount = useRef(true);
  const [showList, setShowList] = useState(false);
  const [prevSearchValue, setPrevSearchValue] = useState('');
  const [searchValue, setSearchValue] = useState(value || '');
  const [selectedValue, setSelectedValue] = useState(value || '');
  const [isEditing, setIsEditing] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(selectedValues || []);
  const normalizedOtherOptions = Array.isArray(otherOptions) ? otherOptions : [otherOptions];

  const filteredOptions = (options || []).filter(option => {
    const val = displayedValue(option, displayProperty)?.toLowerCase();
    const searchVal = typeof searchValue === 'string' ? searchValue.toLowerCase() : '';
    return val ? val.includes(searchVal) : false;
  });

  const extendedOptions = filteredOptions?.length
    ? [...filteredOptions, ...normalizedOtherOptions]
    : normalizedOtherOptions;

  const clearSearch = () => {
    onClear();
    setSearchValue('');
    setSelectedValue('');
    setShowList(false);
    setSelectedOptions([]);
  };

  useEffect(() => {
    if (clearKey !== null) {
      clearSearch();
    }
  }, [clearKey]);

  useEffect(() => {
    if (selectedValues?.length) {
      setSelectedOptions(selectedValues);
    }
  }, []);

  const handleSearchbarClick = e => {
    if (!isEditing) setIsEditing(true);
    setSearchValue(e.target.value);
    setShowList(true);
  };

  const handleSelectedValue = option => {
    const displayValue = displayedValue(option, displayProperty);
    const transientValue = selectedProperty ? option[selectedProperty] : displayValue;

    setSelectedValue(displayValue);
    setSearchValue(displayValue);
    setShowList(false);
    setIsEditing(false);
    updateTransientProps({ [fieldName]: transientValue });
    if (onChange) {
      onChange(option);
    }
  };

  const handleCheckedValue = option => {
    const value = displayedValue(option, selectProperty || displayProperty);

    setSelectedOptions(prevSelectedOptions => {
      const newDisplayOptions = prevSelectedOptions.includes(value)
        ? prevSelectedOptions.filter(opt => opt !== value)
        : [...prevSelectedOptions, value];

      const newTransientOptions = newDisplayOptions
        .map(displayOption => {
          const matchingOption = options.find(opt => displayedValue(opt, displayProperty) === displayOption);
          return matchingOption ? (selectedProperty ? matchingOption[selectedProperty] : displayOption) : null;
        })
        .filter(Boolean);

      updateTransientProps({ [fieldName]: newTransientOptions });
      if (onChange) {
        onChange(newTransientOptions);
      }
      return newDisplayOptions;
    });
  };

  const handleClick = option => {
    if (multiple) {
      handleCheckedValue(option);
    } else {
      handleSelectedValue(option);
    }
  };

  useEffect(() => {
    if (onSearch && searchValue.length > 0 && searchValue !== prevSearchValue) {
      setPrevSearchValue(searchValue);
      const handler = setTimeout(() => {
        onSearch(searchValue);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [fieldName, onSearch, searchValue, selectedValue, updateTransientProps, isEditing, value]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (resetKey !== null) {
      setShowList(false);
      setSearchValue('');
      setSelectedValue('');
      setIsEditing(false);
      setSelectedOptions([]);
      updateTransientProps({ [fieldName]: [] });
    }
  }, [resetKey]);

  useOutsideClick(dropdownRef, () => {
    setShowList(false);
  });

  return (
    <div id="search-and-select" ref={dropdownRef}>
      <ColumnView>
        {multiple && (
          <RowView
            justifyContent={'flex-start'}
            {...(selectedOptions?.length && { paddingTop: 4, paddingBottom: 8 })}
            gap={4}
            flexWrap={'wrap'}>
            {selectedOptions?.map((option, index) => (
              <div key={index} className="tag">
                {option}
              </div>
            ))}
          </RowView>
        )}
        <RowView onClick={() => setShowList(!showList)}>
          <Searchbar
            init={false}
            onChange={e => setSearchValue(e.target.value)}
            onClick={handleSearchbarClick}
            onClickClear={clearSearch}
            placeholder={label}
            value={searchValue}
            onSubmit={preventDefault}
          />
        </RowView>

        <ColumnView paddingVertical={4}>
          {showList &&
            (searchValue === '' && extendedOptions.length === 0 ? (
              <div className="empty-list">{I18n.t('general:typeToSearch')}</div>
            ) : extendedOptions.length === 0 ? (
              <div className="empty-list">{I18n.t('general:noResultsFound')}</div>
            ) : (
              <List className="list">
                {extendedOptions.map((option, index) => {
                  const isOtherOption = normalizedOtherOptions.includes(option);
                  const displayValue = isOtherOption ? option : displayedValue(option, displayProperty);
                  const checked = isOtherOption
                    ? selectedOptions?.includes(option)
                    : selectedOptions?.includes(displayValue);
                  return (
                    <ListItem
                      key={index}
                      checkbox={multiple}
                      checked={checked}
                      onClick={handleClick.bind(null, option)}>
                      {displayValue}
                    </ListItem>
                  );
                })}
              </List>
            ))}
        </ColumnView>
      </ColumnView>
    </div>
  );
};

SearchAndSelect.propTypes = {
  clearKey: PropTypes.any,
  displayProperty: PropTypes.string,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onSearch: PropTypes.func,
  options: PropTypes.array,
  otherOptions: PropTypes.array,
  resetKey: PropTypes.any,
  selectedProperty: PropTypes.string,
  selectedValues: PropTypes.array,
  selectProperty: PropTypes.string,
  updateTransientProps: PropTypes.func,
  value: PropTypes.string
};

export default SearchAndSelect;
