import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { Table } from 'Components';
import { mockFunction } from 'Helpers';
import { ALIGNMENT, FONT_SIZE, TEXT_ALIGN } from 'Constants';

const Status = ({
  dailyFigures = {},
  getAdminDailyFigures = mockFunction,
  getAdminDailyFiguresLoading = false,
  transient = {}
}) => {
  const { filters } = transient;
  const { campaigns = [] } = dailyFigures;

  const columns = [
    {
      name: I18n.t('admin:dailyFiguresTableHeaders.campaign'),
      field: 'name',
      width: '30%',
      sorting: true,
      itemAlign: TEXT_ALIGN.START,
      itemSize: FONT_SIZE.SMALL,
      sortingAction: getAdminDailyFigures
    },
    {
      name: I18n.t('admin:dailyFiguresTableHeaders.baitf'),
      field: 'trading',
      width: '17.5%',
      headerAlign: ALIGNMENT.CENTER,
      itemAlign: TEXT_ALIGN.CENTER,
      itemSize: FONT_SIZE.SMALL
    },
    {
      name: I18n.t('admin:dailyFiguresTableHeaders.scoring'),
      field: 'scoring',
      width: '17.5%',
      headerAlign: ALIGNMENT.CENTER,
      itemAlign: TEXT_ALIGN.CENTER,
      itemSize: FONT_SIZE.SMALL
    },
    {
      name: I18n.t('admin:dailyFiguresTableHeaders.pa'),
      field: 'pa',
      width: '17.5%',
      headerAlign: ALIGNMENT.CENTER,
      itemAlign: TEXT_ALIGN.CENTER,
      itemSize: FONT_SIZE.SMALL
    },
    {
      name: I18n.t('admin:dailyFiguresTableHeaders.sales'),
      field: 'sales',
      width: '17.5%',
      headerAlign: ALIGNMENT.CENTER,
      itemAlign: TEXT_ALIGN.CENTER,
      itemSize: FONT_SIZE.SMALL
    }
  ];

  return (
    <Table
      columns={columns}
      filters={filters}
      multipleSelection={false}
      processing={getAdminDailyFiguresLoading}
      requiresDataTransformation={false}
      rows={campaigns}
    />
  );
};

Status.propTypes = {
  dailyFigures: PropTypes.object,
  getAdminDailyFigures: PropTypes.func,
  getAdminDailyFiguresLoading: PropTypes.bool,
  transient: PropTypes.object
};

export default Status;
