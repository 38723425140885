import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { RowView } from 'Containers';
import { mockFunction } from 'Helpers';
import { MODAL_SIZES } from 'Constants';
import { Button, Modal } from 'Components';

const TermsAndConditionsModal = ({
  tc_modal = false,
  termsAndConditionsMessage = mockFunction,
  updateTransientProps = mockFunction,
  updateUser = mockFunction,
  updateUserTC = mockFunction
}) => (
  <Modal id={'campaigns'} open={tc_modal} size={MODAL_SIZES.SMALL} closeByBackdropClick={false}>
    <Modal.Title>
      <div className="text-md font-bold">{I18n.t('general:agreementNotice')}</div>
    </Modal.Title>

    <Modal.Content contentPosition={'center'}>
      <RowView>{termsAndConditionsMessage(updateTransientProps)}</RowView>
    </Modal.Content>

    <Modal.Actions>
      <Button.Primary
        className="line-height-normal"
        width={'100%'}
        onClick={updateUserTC.bind(null, { updateUser, updateTransientProps })}>
        {I18n.t('general:consent')}
      </Button.Primary>
    </Modal.Actions>
  </Modal>
);

TermsAndConditionsModal.propTypes = {
  tc_modal: PropTypes.bool,
  termsAndConditionsMessage: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateUser: PropTypes.func,
  updateUserTC: PropTypes.func
};

export default TermsAndConditionsModal;
