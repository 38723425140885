import React from 'react';
import PropTypes from 'prop-types';

import { FONT_SIZE, TEXT_ALIGN } from 'Constants';

import './style.scss';

const renderHeader = (column = {}, index) => {
  const headerSize = column?.headerSize || FONT_SIZE.SMALL;
  const headerAlign = column?.headerAlign || TEXT_ALIGN.CENTER;

  return (
    <th key={index} className={`table-header ${headerSize} ${headerAlign}`}>
      {column.name}
    </th>
  );
};

const renderItem = ({ columns = [], maxRowWidth = 'w-auto', row = {} }, column = {}, colIndex) => {
  const itemSize = columns[colIndex]?.itemSize || FONT_SIZE.MEDIUM;
  const itemAlign = columns[colIndex]?.itemAlign || TEXT_ALIGN.START;

  return (
    <td key={colIndex} className={`table-row ${maxRowWidth} ${itemAlign} ${itemSize}`}>
      {row[column.field]}
    </td>
  );
};

const renderRows = ({ columns = [], maxRowWidth = 'w-auto' }, row = {}, rowIndex) => (
  <tr key={rowIndex}>{columns.map(renderItem.bind(null, { columns, maxRowWidth, row }))}</tr>
);

const SimpleTable = ({ columns = [], maxRowWidth = 'w-auto', processing = false, rows = [] }) => (
  <div id={'simple-table'}>
    <div className="data-table data-table-init card">
      {processing ? (
        <div className="empty-table">
          <div className="loader" />
        </div>
      ) : (
        <div className="card-content">
          <table>
            <thead>
              <tr>{columns.map(renderHeader)}</tr>
            </thead>

            <tbody>{rows.map(renderRows.bind(null, { columns, maxRowWidth }))}</tbody>
          </table>
        </div>
      )}
    </div>
  </div>
);

SimpleTable.propTypes = {
  columns: PropTypes.array,
  maxRowWidth: PropTypes.string,
  processing: PropTypes.bool,
  rows: PropTypes.array
};

export default SimpleTable;
