import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';

import { I18n } from 'Locales';
import { BadgeStar } from 'Icons';
import { mockFunction } from 'Helpers';
import { Button, Fab } from 'Components';
import { ColumnView, RowView } from 'Containers';

import './style.scss';

const handleFileUpload = ({ user, updateUserAvatar }, e) => {
  const { files } = e.target;
  if (!files?.length) return;
  updateUserAvatar({ user_id: user.id, file: files[0] });
};

const defaultAvatarStyle = 'bg-primary text-white rounded-full';
const avatarSize = {
  40: `${defaultAvatarStyle} h-40 w-40 text-md`,
  70: `${defaultAvatarStyle} h-70 w-70 font-size-34`,
  90: `${defaultAvatarStyle} h-90 w-90 font-size-34`,
  120: `${defaultAvatarStyle} h-120 w-120 font-size-60`
};

const Avatar = props => {
  const {
    badge,
    editable,
    isOffline,
    onClick,
    size,
    source,
    updateTransientProps,
    updateUserAvatar,
    updateUserAvatarLoading,
    user
  } = props;
  const [hasError, setHasError] = useState(false);
  const inputFile = useRef(null);

  const onButtonClick = () => {
    inputFile.current.click();
  };
  return (
    <ColumnView id="avatar" width={'auto'} onClick={onClick}>
      <div className={`avatar-img ${editable && 'avatar-btn'}`}>
        {hasError || !source ? (
          <RowView>
            <div
              className={`avatar-initials uppercase line-height-normal ${avatarSize[size]} ${
                editable ? 'avatar-img-border' : ''
              }`}>
              {`${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`}
            </div>
          </RowView>
        ) : (
          <RowView>
            <img
              className={`avatar-img avatar-img-border ${editable}`}
              src={source}
              width={size + 'px'}
              height={size + 'px'}
              onError={setHasError.bind(null, true)}
            />
          </RowView>
        )}
        {badge && (
          <Fab disabled={false} onClick={updateTransientProps.bind(null, { showBadgeModal: true })} slot={'fixed'}>
            <img src={BadgeStar} />
          </Fab>
        )}
        {editable && (
          <>
            <input
              className="display-none"
              ref={inputFile}
              onChange={handleFileUpload.bind(null, { user, updateUserAvatar })}
              type="file"
              // we accept pdf as a hack to let user to upload a new photo(not from galery)
              accept="image/*, application/pdf"
            />
            <RowView paddingTop={10}>
              <Button.OutlineSmall
                disabled={isOffline}
                width={127}
                processing={updateUserAvatarLoading}
                onClick={onButtonClick}
                className="line-height-normal h-36">
                <div className="text-blue">
                  {!source ? I18n.t('profile:profileTab.addPhoto') : I18n.t('profile:profileTab.changePhoto')}
                </div>
              </Button.OutlineSmall>
            </RowView>
          </>
        )}
      </div>
    </ColumnView>
  );
};

Avatar.propTypes = {
  badge: PropTypes.bool,
  editable: PropTypes.bool,
  isOffline: PropTypes.bool,
  onClick: PropTypes.func,
  showBadgeModal: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  source: PropTypes.any,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateUserAvatar: PropTypes.func,
  updateUserAvatarLoading: PropTypes.bool,
  user: PropTypes.object
};

Avatar.defaultProps = {
  badge: false,
  editable: false,
  isOffline: false,
  onClick: mockFunction,
  showBadgeModal: false,
  size: '100',
  source: '',
  transient: {},
  updateTransientProps: mockFunction,
  updateUserAvatar: mockFunction,
  updateUserAvatarLoading: false,
  user: {}
};

export default Avatar;
