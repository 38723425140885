import { connect } from 'react-redux';

import * as Selectors from 'Selectors';
import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';

import DocumentsTab from './view';

export default connect(
  state => ({
    ...state.transient,
    campaigns: state.user?.campaigns,
    currentPage: state.application.currentPage,
    filteredCampaignName: Selectors.filteredCampaignName(state),
    isOffline: state.application.isOffline,
    modal: state.transient.modal,
    selectedCampaign: Selectors.selectedCampaign(state),
    selectedCampaignFilterId: Selectors.filteredCampaignId(state),
    user: state.user
  }),
  {
    deleteAttachment: userActions.deleteAttachment,
    getAttachments: userActions.getAttachments,
    readAttachment: userActions.readAttachment,
    updateTransientProps: transientActions.updateProps,
    updateUser: userActions.updateApiModel,
    uploadAttachments: userActions.uploadAttachments
  }
)(DocumentsTab);
