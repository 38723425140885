import { connect } from 'react-redux';

import { Creators as transientActions } from 'Reducers/transient';
import { Creators as adminActions } from 'Reducers/adminOnboarding';

import RejectDocument from './view';

export default connect(
  state => ({
    currentPage: state.adminOnboarding.currentPage,
    transient: state.transient,
    userOnboardingProfile: state.adminOnboarding.userOnboardingProfile
  }),
  {
    updateAdminProp: adminActions.updateProp,
    updateOnboardingProfileDocuments: adminActions.updateOnboardingProfileDocuments,
    updateOnboardingUserProfile: adminActions.updateOnboardingUserProfile,
    updateOnboardingUserSection: adminActions.updateOnboardingUserSection,
    updateTransientProps: transientActions.updateProps,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations
  }
)(RejectDocument);
