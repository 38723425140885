import Config from 'Config';
import { Api } from 'Core';

const get = () => Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/sessions/current?include=recent_results`);

const performTestResults = user =>
  Api.put(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/users/${user.id}/perform_test_results`, {
    data: {
      attributes: {
        test_mode: !user.test_mode
      }
    }
  });

const resendLinkToDonor = data =>
  Api.post(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/results/${data.id}/send_link_to_donor`,
    { data: { attributes: data } },
    false
  );

const submitFlow = (data, token) => {
  const payload = {
    data: {
      attributes: token ? { encoded_data: data, execution_token: token } : data
    }
  };

  return Api.post(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/results`, payload, false);
};

const uploadResultPicture = data =>
  Api.post(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/results/upload`, data, false);

export default { get, performTestResults, resendLinkToDonor, submitFlow, uploadResultPicture };
