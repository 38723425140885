import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Config from 'Config';
import { I18n } from 'Locales';
import { Table } from 'Components';
import { mockFunction } from 'Helpers';
import { ColumnView } from 'Containers';
import { ALIGNMENT, FONT_SIZE, ROLES, TEXT_ALIGN } from 'Constants';

import { ManualCheckIn } from '../../../Modals';

const Status = ({
  dailyFigures = {},
  getAdminDailyFigures = mockFunction,
  manualCheckIn = mockFunction,
  transient = {},
  updateGrowlProps = mockFunction,
  updateTransientProps = mockFunction,
  userRole = ''
}) => {
  const { users = [] } = dailyFigures;
  const {
    getAdminDailyFiguresLoading = false,
    showManualCheckInModal = false,
    filters = {},
    activeCampaigns = []
  } = transient;

  const { campaign_id = '' } = filters;

  const isOwnerOrMcAdmin = ROLES.MC_ADMINISTRATIVE.includes(userRole);
  const dateDifference = moment(filters.dailyFiguresDate_end_date).diff(
    moment(filters.dailyFiguresDate_start_date),
    'days'
  );
  const isStartDateToday =
    filters.dailyFiguresDate_start_date && moment(filters.dailyFiguresDate_start_date).isSame(moment(), 'day');
  const isEndDateToday =
    filters.dailyFiguresDate_end_date && moment(filters.dailyFiguresDate_end_date).isSame(moment(), 'day');
  const today = isStartDateToday || isEndDateToday;

  const showGrowlError = today => {
    const body = today ? I18n.t('growl:error.todayCheckInError.body') : I18n.t('growl:error.manualCheckInError.body');

    updateGrowlProps({
      visible: true,
      title: I18n.t('growl:error.generalError.title'),
      body: body,
      kind: 'error'
    });
    setTimeout(() => updateGrowlProps({ visible: false }), Config.GROWL_AUTOHIDE);
  };

  const dailyManualCheckIn = row => {
    manualCheckIn({
      campaign_id: filters.campaign_id,
      start_date: filters.dailyFiguresDate_start_date,
      end_date: filters.dailyFiguresDate_end_date,
      user_id: row.user_id,
      checked: !row.ba_in_field,
      current_mc_pin: filters?.pin || '',
      sales: row.sales
    });
  };

  const columns = [
    {
      name: I18n.t('admin:baBadge'),
      field: 'badge_number',
      headerAlign: ALIGNMENT.START,
      width: '25%',
      itemAlign: TEXT_ALIGN.START,
      itemSize: FONT_SIZE.SMALL
    },
    {
      name: I18n.t('admin:baName'),
      field: 'name',
      headerAlign: ALIGNMENT.START,
      width: '30%',
      itemAlign: TEXT_ALIGN.START,
      itemSize: FONT_SIZE.SMALL,
      sorting: true,
      sortingAction: getAdminDailyFigures
    },
    {
      name: I18n.t('admin:baInTheField'),
      field: 'ba_in_field',
      headerAlign: ALIGNMENT.CENTER,
      itemAlign: TEXT_ALIGN.CENTER,
      itemSize: FONT_SIZE.SMALL,
      itemType: 'checkbox',
      width: '20%',
      action: row => {
        if (isOwnerOrMcAdmin && dateDifference >= 1) {
          showGrowlError();
        } else if (today) {
          showGrowlError(today);
        } else if (campaign_id !== '') {
          dailyManualCheckIn(row);
        } else {
          updateTransientProps({
            showManualCheckInModal: true,
            selectedRow: row
          });
        }
      }
    },
    {
      name: I18n.t('admin:results'),
      field: 'sales',
      headerAlign: ALIGNMENT.CENTER,
      itemAlign: TEXT_ALIGN.CENTER,
      itemSize: FONT_SIZE.SMALL,
      width: '25%',
      sorting: true,
      sortingAction: getAdminDailyFigures
    }
  ];

  return (
    <ColumnView>
      <Table
        columns={columns}
        filters={filters}
        multipleSelection={false}
        processing={getAdminDailyFiguresLoading}
        requiresDataTransformation={false}
        rows={users}
      />

      <ManualCheckIn
        campaigns={activeCampaigns}
        isOpen={showManualCheckInModal}
        manualCheckIn={manualCheckIn}
        onClose={updateTransientProps.bind(null, { showManualCheckInModal: false })}
        transient={transient}
        updateGrowlProps={updateGrowlProps}
        updateTransientProps={updateTransientProps}
      />
    </ColumnView>
  );
};

Status.propTypes = {
  dailyFigures: PropTypes.object,
  getAdminDailyFigures: PropTypes.func,
  manualCheckIn: PropTypes.func,
  transient: PropTypes.object,
  updateGrowlProps: PropTypes.func,
  updateTransientProps: PropTypes.func,
  userRole: PropTypes.string
};

export default Status;
